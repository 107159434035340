import { Box, Stack } from "@mui/system";
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerTopPic from "../../../images/gh_top_banner_a.png";

import "./Banner.css";
import "./CircleRipple.css";
import "./ClockAnimate.css";
// import "./ClockAnimate.js";

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { PlayCircle, Search } from "@mui/icons-material";
import { bannerApi, keywordApi } from "../../API/Api";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VideoModal from "./VideoModal";

console.log("myapi_" + bannerApi);
const Banner = () => {
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState([]);
  const [keywordData1st, setKeywordData1st] = useState([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");

  const [openVideo, setOpenVideo] = React.useState(false);
  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);

  console.log("keywordData1st:", keywordData1st);
  const focus = useRef(0);

  const handleClickSearch = (value) => {
    focus.current.firstChild.firstChild.value = value;
    console.log("focusdsds:", focus.current.firstChild.firstChild);
    setSearch(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  // useEffect(() => {
  //   fetch(bannerApi)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setBannerData(result);
  //     })
  //     .catch((error) => console.log("bannerData Error => ", error));
  // }, []);

  useEffect(() => {
    fetch(keywordApi)
      .then((res) => res.json())
      .then((result) => {
        setKeywordData1st(result.slice(0, 4));
      });
  }, []);
  console.log("keywordData:", keywordData1st);

  // const { banner_pic, refer, title } = bannerData;
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  function clock() {
    const date = new Date();

    const hours = ((date.getHours() + 11) % 12) + 1;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const hour = hours * 30;
    const minute = minutes * 6;
    const second = seconds * 6;

    const hourHand = document.querySelector(".hour");
    const minuteHand = document.querySelector(".minute");
    const secondHand = document.querySelector(".second");

    hourHand.style.transform = `rotate(${hour}deg)`;
    minuteHand.style.transform = `rotate(${minute}deg)`;
    second == 0
      ? secondHand.classList.add("hide-transition")
      : secondHand.classList.remove("hide-transition");
    secondHand.style.transform = `rotate(${second}deg)`;

    // dynamic shadow
    let hourOffsetSign = hour > 135 && hour < 315 ? "-" : "";
    let minuteOffsetSign = minute > 135 && minute < 315 ? "-" : "";
    let secondOffsetSign = second > 135 && second < 315 ? "-" : "";

    hourHand.style.boxShadow = `${hourOffsetSign}6px ${hourOffsetSign}6px 6px #b8b9be`;
    minuteHand.style.boxShadow = `${minuteOffsetSign}6px ${minuteOffsetSign}6px 6px #b8b9be`;
    secondHand.style.boxShadow = `${secondOffsetSign}6px ${secondOffsetSign}6px 6px #b8b9be`;
  }

  setInterval(clock, 1000);
  return (
    <Stack
      sx={{
        width: { xs: "100%", sm: "100%" },
        position: "relative",
        marginTop: { xs: "80px", md: "20px", lg: 0 },
      }}
    >
      <VideoModal open={openVideo} handleClose={handleCloseVideo} />
      {/* {bannerData.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "88.5vh",
            position: "relative",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            alt="The house from the offer."
            src={bannerTopPic}
          />

          <Box
            sx={{
              position: "absolute",
              top: "45vh",
              right: "17%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
        >
          <Slider {...settings}>
            {bannerData.map((element) => (
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="The house from the offer."
                  src={element?.banner_pic}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      )} */}

      <Box
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "100%", sm: "100%" },
            position: "relative",
            marginTop: { xs: "0px", md: "0px", lg: 0 },
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
          alt="Ghorami Top Banner."
          src={bannerTopPic}
        />

        <Box
          className="clock-container"
          sx={{
            height: "80px",
            width: "80px",
            position: "absolute",
            top: { xs: "15%", md: "20%" },
            right: { xs: "45%", md: "25%" },
          }}
        >
          <Box className="clock">
            <span className="hour"></span>
            <span className="minute"></span>
            <span className="second"></span>
            <span className="axis"></span>
          </Box>
        </Box>
      </Box>
      <Stack
        justifyContent={{ md: "left", lg: "center" }}
        spacing={2}
        sx={{
          // marginBottom: { xs: "40px", sm: 0 },
          marginLeft: { xs: "5%", md: "8%" },
          height: "90vh",
          width: { xs: "90%", md: "90%", lg: "53%" },
          position: { xs: "relative", md: "relative", lg: "absolute" },
          top: -10,
        }}
      >
        <Stack>
          <Typography
            variant="p"
            sx={{
              fontWeight: 700,
              fontSize: "16pt",
              color: "#19B14C",
              paddingBottom: "15px",
            }}
          >
            GET STARTED
          </Typography>
          <Typography variant="p" sx={{ fontWeight: 700, fontSize: "29pt" }}>
            Buy Expert Services for
          </Typography>
          <Typography variant="p" sx={{ fontWeight: 700, fontSize: "29pt" }}>
            Any Job Done
          </Typography>
        </Stack>
        <Stack direction={"column"}>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "12pt", color: "black" }}
          >
            Using Ghorami, You can spread your expertise with no extra effort.
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "14pt", color: "black" }}
          >
            Jumpstart your business with ghorami.
          </Typography>
        </Stack>
        <FormControl
          sx={{
            m: 1,
            width: "100%",
            bgcolor: "#FFFFFF",
            border: "1px solid #9F9FA0",
            borderRadius: "50px",
            display: { xs: "none", sm: "block", md: "block" },
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="left"
            alignItems="center"
            sx={{ width: "100%", padding: ".5%" }}
          >
            <TextField
              ref={focus}
              variant="standard"
              required
              id="phoneNumber"
              name="phoneNumber"
              autoFocus
              onChange={handleChange}
              placeholder="Search..."
              sx={{ width: "40%", marginLeft: "3%", padding: "20px, 0" }}
              InputProps={{
                // startAdornment: <AccountCircle />, // <== adjusted this
                disableUnderline: true, // <== added this
              }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "8%" }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, color: "#9F9FA0" }}
              >
                |
              </Typography>
            </Stack>
            <Select
              variant="standard"
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                disableUnderline: true,
              }}
              sx={{
                width: "29%",
                border: 0,
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:active": {
                  width: "29%",
                  border: 0,
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                },
              }}
              size="large"
              disableUnderline
            >
              {/* <MenuItem value="" sx={{ width: 120 }}> */}
              <OutlinedInput
                placeholder="Please enter text"
                size="small"
                sx={{ width: "100%" }}
              />

              <MenuItem value="job" sx={{ width: "100%" }}>
                <Typography
                  variant="p"
                  // sx={{ fontWeight: 600, color: "#9F9FA0" }}
                  sx={{ width: "100%" }}
                >
                  Job
                </Typography>
              </MenuItem>
              <MenuItem value={"service"} sx={{ width: "100%" }}>
                Service
              </MenuItem>
              <MenuItem value={"freelancer"} sx={{ width: "100%" }}>
                Freelancer
              </MenuItem>
              <MenuItem value={"agency"} sx={{ width: "100%" }}>
                Agency
              </MenuItem>
            </Select>

            <Button
              variant="contained"
              sx={{
                marginLeft: "6px",
                width: "18%",
                borderRadius: "30px",
                padding: "2%",
                textTransform: "capitalize",
                bgcolor: "#009C8B",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              onClick={() => {
                navigate("/search/" + searchType + "/" + search);
              }}
              startIcon={<Search />}
              size="large"
            >
              Search
            </Button>
          </Stack>
        </FormControl>
        <FormControl sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
          <Stack spacing={2}>
            <OutlinedInput
              placeholder="Search..."
              sx={{ width: "100%" }}
              onChange={handleChange}
            />
            <Select
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: "100%" }}
            >
              <OutlinedInput
                placeholder="Please enter text"
                size="small"
                sx={{ width: "100%" }}
              />
              <MenuItem value="job" sx={{ width: "100%" }}>
                <Typography
                  variant="p"
                  // sx={{ fontWeight: 600, color: "#9F9FA0" }}
                  sx={{ width: "100%" }}
                >
                  Job
                </Typography>
              </MenuItem>
              <MenuItem value={"service"} sx={{ width: "100%" }}>
                Service
              </MenuItem>
              <MenuItem value={"freelancer"} sx={{ width: "100%" }}>
                Freelancer
              </MenuItem>
              <MenuItem value={"agency"} sx={{ width: "100%" }}>
                Agency
              </MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                marginLeft: "6px",
                width: "100%",
                borderRadius: "30px",
                padding: "2%",
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate("/search/" + searchType + "/" + search);
              }}
              startIcon={<Search />}
              size="large"
            >
              Search
            </Button>
          </Stack>
        </FormControl>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="left"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="left"
            alignItems="center"
            sx={{ flexWrap: "wrap" }}
          >
            <Typography variant="p">Trending Keywords:</Typography>
            {keywordData1st?.map((element) => (
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "#e7e7e7",
                  color: "black",
                  textTransform: "capitalize",
                  fontSize: "8pt",
                  borderRadius: "20px",
                  margin: { xs: "5px !important", sm: "0px" },
                  "&:hover": {
                    // color: "gray",
                    backgroundColor: "lightblue",
                  },
                }}
                onClick={() => {
                  // navigate("/serviceList/" + element.refer);
                  handleClickSearch(element.title);
                }}
              >
                {element.title}
              </Button>
            ))}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="left"
          alignItems="center"
        >
          <Box
            // className="loadingio-spinner-ripple-wsf5cxo48ch"
            sx={{ border: "1px solid black", borderRadius: "50px" }}
          >
            <IconButton
              className="circle-ripple"
              // className="ldio-japzwhp0h9j"
              size="large"
              onClick={handleOpenVideo}
            >
              <PlayCircle fontSize="inherit" sx={{ color: "#22AB7D" }} />
            </IconButton>
          </Box>
          <Stack>
            <Typography
              variant="p"
              sx={{ fontWeight: 500, fontSize: "13pt", color: "#22AB7D" }}
            >
              Watch Demo
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 500, fontSize: "12pt", color: "black" }}
            >
              Get started in minutes
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="left"
          alignItems="center"
        >
          {/* {clock()} */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Banner;
