import { Apps, FormatListBulleted } from "@mui/icons-material";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { serviceApi } from "../../API/Api";
import ServiceCardItem from "../../Homepage Components/Trending Services/ServiceCardItem";
import ServiceCardItemFlat from "../../Homepage Components/Trending Services/ServiceCardItemFlat";

const Offers = ({ serviceListData }) => {
  const [viewMode, setViewMode] = useState("grid");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{ padding: "10px", marginBottom: "5px" }}
      >
        <Typography variant="p" sx={{ fontSize: "14pt", fontWeight: 700 }}>
          Offer
        </Typography>

        <Stack direction="row" justifyContent={"left"} alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={{ marginRight: "5px" }}
            onClick={() => {
              setViewMode("grid");
            }}
          >
            <Apps />
          </Button>
          <Button
            size="small"
            onClick={() => {
              setViewMode("list");
            }}
          >
            <FormatListBulleted />
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ marginBottom: "20px" }} />

      <Grid container spacing={2}>
        {viewMode === "list" &&
          serviceListData.map((data) => (
            <Grid item xs={12} sm={12} md={12} key={data.pro_pic}>
              <ServiceCardItemFlat data={data} />
            </Grid>
          ))}
        {viewMode === "grid" &&
          serviceListData.map((data) => (
            <Grid item xs={6} sm={6} md={4} key={data.pro_pic}>
              <ServiceCardItem data={data} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Offers;
