import {
  CheckCircleRounded,
  Comment,
  Favorite,
  LocalOffer,
  MenuBook,
  ModeComment,
  Schedule,
  Star,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Valorant from "../../../images/Valorant.jpg";
import "./ServiceCardItem.css";
const ServiceCardItemFlat = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const {
    company,
    post_time,
    poster,
    poster_pic,
    service_category,
    service_duration,
    service_info,
    service_pic,
    service_pic_list,
    service_price,
    service_refer,
    service_review,
    service_currency,
    service_review_quantity,
    service_subcategory,
    service_title,
  } = data;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Card
      sx={{
        width: "100%",
        // height: { xs: "45vh", sm: "50vh", lg: "58vh" },
        height: "auto",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="service"
        sx={{ marginLeft: 1, marginTop: 1 }}
      >
        <Box sx={{ width: "30%", position: "relative" }}>
          <Slider {...settings} sx={{}}>
            <Box sx={{ height: "33vh" }}>
              <CardMedia
                component="img"
                height="100%"
                image={service_pic}
                alt={"Stylin"}
                sx={{ opacity: 1, borderTopLeftRadius: "20px" }}
              />
            </Box>
            {service_pic_list.map((element) => (
              <Box key={element} sx={{ height: "35vh" }}>
                <CardMedia
                  component="img"
                  height="100%"
                  image={element}
                  alt={"Stylin"}
                  sx={{ opacity: 1, borderTopLeftRadius: "20px" }}
                />
              </Box>
            ))}
          </Slider>
          {true && (
            <Box
              sx={{
                position: "absolute",
                top: "4%",
                left: "2%",
                padding: "1% 2%",
                bgcolor: "black",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "white", fontSize: "10pt", fontWeight: 600 }}
              >
                Featured
              </Typography>
            </Box>
          )}
        </Box>

        <Stack sx={{ position: "relative", cursor: "pointer", width: "50%" }}>
          <Stack
            spacing={2}
            sx={{ paddingLeft: "7%", mt: "" }}
            justifyContent={"start"}
          >
            <Stack
              spacing={0}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              //   sx={{ height: "90px" }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
                sx={{ marginTop: "15px" }}
              >
                <LocalOffer
                  sx={{
                    fontSize: { md: "12pt" },
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "11pt" },
                    fontWeight: 600,
                    color: "#22AB7D",
                  }}
                >
                  {service_category}
                </Typography>
              </Stack>
              <Typography
                gutterBottom
                variant="p"
                color="black"
                sx={{ fontSize: { xs: "11pt", md: "13pt" }, fontWeight: 500 }}
                component="div"
              >
                {service_title}
              </Typography>

              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <Schedule
                  sx={{
                    fontSize: { md: "12pt" },
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "10pt" },
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {service_duration}
                </Typography>
              </Stack>

              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
                sx={{ marginTop: "5px" }}
              >
                <MenuBook
                  sx={{
                    fontSize: { md: "12pt" },
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "9pt" },
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {service_info}
                </Typography>
              </Stack>

              <Stack
                spacing={2}
                direction="row"
                justifyContent="left"
                alignItems="center"
                sx={{ marginTop: "10px" }}
              >
                <Stack
                  spacing={0}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                >
                  <Rating
                    name="read-only"
                    value={service_review}
                    readOnly
                    sx={{
                      fontSize: { xs: "7pt", md: "12pt" },
                    }}
                  />

                  <Typography
                    variant="p"
                    color="black"
                    sx={{
                      ml: "2px",
                      fontSize: { xs: "7pt", md: "10pt" },
                      fontWeight: 600,
                    }}
                  >
                    {service_review} ({service_review_quantity} Reviews)
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  marginTop: "15px",
                  borderRadius: "30px",
                  bgcolor: "#22AB7D",
                  fontSize: "10pt",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: "#14506A",
                  },
                }}
                onClick={() => {
                  navigate("/servicedetails/" + service_refer);
                }}
                size="small"
              >
                Details
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "20px", width: "20%", borderLeft: "1px solid gray" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: { xs: "11pt", md: "13pt" },
              fontWeight: 600,
              color: "black",
            }}
          >
            {service_currency} {service_price}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              bgcolor: "#14506A",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#22AB7D",
              },
            }}
            onClick={() => {
              navigate("/booknow/" + service_refer);
            }}
            size="small"
          >
            Book now
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ServiceCardItemFlat;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
