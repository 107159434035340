import { Facebook, Language, LinkedIn, YouTube } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Skills = (props) => {
  const { profileDetails } = props;
  const { skill_que } = profileDetails;
  let skillList;

  if (skill_que) {
    skillList = skill_que.split(",");
    console.log("skillList:", skillList);
  }

  return (
    <Paper
      sx={{ padding: "30px", marginTop: "5px", border: "1px solid #dfdddd" }}
    >
      <Stack spacing={1} justifyContent={"left"} alignItems={"flex-start"}>
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: "600" }}>
          Skills
        </Typography>
        <Stack
          spacing={1}
          direction={"row"}
          justifyContent={"left"}
          alignItems={"center"}
          sx={{ flexWrap: "wrap" }}
        >
          {skillList?.map(
            (element) =>
              element && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: "9pt", margin: "4px" }}
                >
                  {element}
                </Button>
              )
          )}

          {/* <Button size="small" variant="outlined" sx={{ fontSize: "9pt" }}>
            React
          </Button>
          <Button size="small" variant="outlined" sx={{ fontSize: "9pt" }}>
            Node.js
          </Button> */}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Skills;
