import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import { IconButton } from "@mui/material";
import { Download, PictureAsPdf } from "@mui/icons-material";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DescriptionTab = (props) => {
  const { fetchedServiceDetails } = props;
  const { attach_file, details, service_attachment } = fetchedServiceDetails;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Attachment" {...a11yProps(1)} />
          <Tab label="More" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography variant="p">{details}</Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {attach_file?.map((element) => (
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"left"}
            alignItems="center"
          >
            <IconButton
              aria-label="delete"
              size="small"
              href={element.attach_url}
            >
              <Download fontSize="small" />
            </IconButton>
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              {element.attach_filename}
            </Typography>
          </Stack>
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <iframe src={service_attachment} width="100%" height="500px"></iframe>
      </TabPanel>
    </Box>
  );
};

export default DescriptionTab;
