import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BrowseSkillItem = (props) => {
  const { skillsData } = props;
  const { action, refer, skill_list, title, type } = skillsData;
  const navigate = useNavigate();

  return (
    <Stack spacing={2} sx={{ padding: "15px" }}>
      <Typography variant="p" sx={{ fontSize: "14pt", fontWeight: 600 }}>
        {title}
      </Typography>
      {skill_list.map((element) => (
        <Typography
          variant="p"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/" + action + "/" + element.refer);
          }}
        >
          {element.title}
        </Typography>
      ))}
      <Typography
        variant="p"
        color="#22AB7D"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/" + action);
        }}
      >
        View More
      </Typography>
    </Stack>
  );
};

export default BrowseSkillItem;
