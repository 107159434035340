import { CheckBox, CheckBoxOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

const Keypoints = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ marginTop: "40px" }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ width: { xs: "70%", md: "45%" } }}>
          <Box
            component="img"
            sx={{
              padding: "0 12px",
              width: "90%",

              //   display: { xs: "none", sm: "none", md: "block" },
            }}
            alt="The house from the offer."
            src="https://ghorami.com/image/atop_banner/left_freelancer_home.png"
          />
        </Box>
        <Stack sx={{ width: { xs: "100%", md: "55%" } }} spacing={3}>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "14pt", color: "#9F9FA0" }}
          >
            Forget the old style
          </Typography>
          <Stack>
            <Typography
              variant="p"
              sx={{ fontWeight: 700, fontSize: "24pt", color: "black" }}
            >
              Find great work with a click.
            </Typography>
          </Stack>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "14pt", color: "#9F9FA0" }}
          >
            Meet awesome client's you're excited to work with & show your skill
            to new height.
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "14pt", color: "#9F9FA0" }}
          >
            With Ghorami, you can show your creativity for others who are
            looking for remote resources
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 600, fontSize: "18pt", color: "black" }}
          >
            Key Points
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
              >
                <CheckBoxOutlined sx={{ color: "red" }} />
                <Typography variant="p" sx={{ fontSize: "13pt" }}>
                  Explore Different way to earn
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
              >
                <CheckBoxOutlined sx={{ color: "red" }} />
                <Typography variant="p" sx={{ fontSize: "13pt" }}>
                  Control your work as you want
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
              >
                <CheckBoxOutlined sx={{ color: "red" }} />
                <Typography variant="p" sx={{ fontSize: "13pt" }}>
                  Find Opportunities for every stage of your career
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
              >
                <CheckBoxOutlined sx={{ color: "red" }} />
                <Typography variant="p" sx={{ fontSize: "13pt" }}>
                  Spend time with great clients with earning
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              window.open("https://community.ghorami.com/forum/");
            }}
            // onClick={() => {
            //   navigate("/joblist");
            // }}
            sx={{
              width: { xs: "35%", md: "20%" },
              borderRadius: "20px",
              bgcolor: "#009C8B",
              "&:hover": {
                bgcolor: "#14506A",
              },
            }}
          >
            Read more
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Keypoints;
