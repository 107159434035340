import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Accounts from "./LeftComponents/Accounts";
import { OutlinedInput, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import {
  Cancel,
  Email,
  Facebook,
  Language,
  LinkedIn,
  Twitter,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import { useState } from "react";
import { useRef } from "react";
import SocialShare from "../SocialShare/SocialShare";

const ShareModal = ({ open, handleClose, profileDetails, style }) => {
  console.log("profileDetails22", profileDetails);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ position: "absolute", top: "5%", right: "2%" }}>
          <Cancel onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Box>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#3b5998",
                borderRadius: "50%",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0cc78a",
                },
              }}
            >
              <Facebook
                sx={{
                  color: "white",
                }}
                onClick={() => {}}
              />
            </Stack> */}

          <SocialShare
            url={window.location.href}
            title={profileDetails?.cname}
            hashTag={profileDetails?.cname}
            description={profileDetails?.cinfo?.slice(0, 120)}
          />
          {/* <Paper
            elevation={8}
            sx={{
              borderRadius: "50%",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#00ACEE",
                borderRadius: "50%",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0cc78a",
                },
              }}
            >
              <Twitter
                sx={{
                  color: "white",
                }}
                onClick={() => {}}
              />
            </Stack>
          </Paper> */}
          {/* <Paper
            elevation={8}
            sx={{
              borderRadius: "50%",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#0072b1",
                borderRadius: "50%",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0cc78a",
                },
              }}
            >
              <LinkedIn
                sx={{
                  color: "white",
                }}
                onClick={() => {}}
              />
            </Stack>
          </Paper>
          <Paper
            elevation={8}
            sx={{
              borderRadius: "50%",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#25D366",
                borderRadius: "50%",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0cc78a",
                },
              }}
            >
              <WhatsApp
                sx={{
                  color: "white",
                }}
                onClick={() => {}}
              />
            </Stack>
          </Paper>
          <Paper
            elevation={8}
            sx={{
              borderRadius: "50%",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "gray",
                borderRadius: "50%",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0cc78a",
                },
              }}
            >
              <Email
                sx={{
                  color: "white",
                }}
                onClick={() => {}}
              />
            </Stack>
          </Paper> */}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            width: "100%",
            bgcolor: "#e3dfdf",
            height: "40px",
            marginTop: "30px",
          }}
        >
          <OutlinedInput
            size="small"
            sx={{ width: "82%", padding: "0px" }}
            value={window.location.href}
            placeholder="Please enter text"
          />

          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={() => navigator.clipboard.writeText(window.location.href)}
          >
            Copy
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ShareModal;
