import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import "./Header.css";
import {
  Button,
  Card,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import logo from "../../images/ghorami.ico";
import {
  AccountCircle,
  Add,
  ArrowDropDown,
  ArrowRightAlt,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Menu,
  Remove,
  Search,
  Star,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { categoryApi, menuBarApi } from "../API/Api";
import CardItem from "./CardItem";
const drawerWidth = 240;
const navItems = ["হোম", "বই"];
const pages = [
  "Corporate",
  "Printing",
  "Event",
  "Promotion",
  "Mobile",
  "Website",
  "Design & Animation",
  "Marketing",
  "Writing",
  "Legal",
  "Admin",
  "Photography",
  "All",
];
const Header = (props) => {
  const appBarRef = useRef();
  const [barHeight, setBarHeight] = useState(0);

  console.log("height:", barHeight);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [activeMainMenu, setActiveMainMenu] = useState(false);
  const [activeSmallList1, setActiveSmallList1] = useState(false);
  const [activeSmallList2, setActiveSmallList2] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [showData, setShowData] = useState(1);
  const focus = useRef(0);
  const [menuBarData, setMenuBarData] = useState([]);
  console.log("menuBarData:", menuBarData);

  const handleClear = () => {
    focus.current.firstChild.firstChild.value = "";
    console.log("focusdsds:", focus.current.firstChild.firstChild);
  };

  useEffect(() => {
    fetch(menuBarApi)
      .then((res) => res.json())
      .then((result) => {
        setMenuBarData(result);
      })
      .catch((error) => console.log("categoryData Error => ", error));
  }, []);
  useEffect(() => {
    fetch(categoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryData(result.slice(0, 6));
      })
      .catch((error) => console.log("categoryData Error => ", error));
  }, []);
  // console.log("categoryDataHeader:", categoryData);

  useEffect(() => {
    setBarHeight(appBarRef.current.offsetHeight);
  }, [activeMainMenu, activeSmallList1, activeSmallList2]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleRoute = (path) => {
    if (path === "হোম") {
      navigate("/");
    } else if (path === "বই") {
      navigate("/book");
    }
  };
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Ghorami
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText
                primary={item}
                onClick={() => {
                  handleRoute(item);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", transition: "all 1s", position: "relative" }}>
      <AppBar
        component="nav"
        position="fixed"
        // className={activeMainMenu && "menuHeight"}
        sx={{
          bgcolor: "white",
          padding: "0 20px",
          // height: { xs: "auto", md: "70px !important" },
          // height: "{ barHeight }px",
        }}
        ref={appBarRef}
      >
        {/* <Toolbar sx={{ justifyContent: "space-between" }}> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" justifyContent="left" alignItems="center">
            <Box
              component="img"
              sx={{
                padding: "0 12px",
                width: 60,
                cursor: "pointer",

                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="cat Logo"
              src={logo}
              onClick={() => {
                navigate("/");
              }}
            />
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                bgcolor: "#f1f1f1 !important",
                borderRadius: "20px",
              }}
            >
              <Stack
                direction="row"
                spacing={0}
                justifyContent="left"
                alignItems="center"
                // className="headerTextField"
              >
                <Select
                  variant="standard"
                  value={""}
                  onChange={handleTypeChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    disableUnderline: true,
                  }}
                  sx={{
                    m: 1,
                    width: 20,
                    border: 0,
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:active": {
                      m: 1,
                      width: 20,
                      border: 0,
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    },
                  }}
                  size="small"
                  disableUnderline
                >
                  <MenuItem value="job" sx={{ width: "100%" }}>
                    <Typography variant="p" sx={{ width: "100%" }}>
                      Job
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"service"} sx={{ width: "100%" }}>
                    Service
                  </MenuItem>
                  <MenuItem value={"freelancer"} sx={{ width: "100%" }}>
                    Freelancer
                  </MenuItem>
                  <MenuItem value={"agency"} sx={{ width: "100%" }}>
                    Agency
                  </MenuItem>
                </Select>
                <TextField
                  ref={focus}
                  variant="standard"
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  // value={age}
                  autoFocus
                  sx={{ paddingLeft: "20px" }}
                  onChange={handleChange}
                  placeholder="Search Jobs"
                  InputProps={{
                    // startAdornment: <AccountCircle />, // <== adjusted this
                    disableUnderline: true, // <== added this
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{ margin: "0 5px" }}
                  onClick={() => {
                    handleClear();
                    navigate("/search/" + searchType + "/" + search);
                  }}
                >
                  <Search />
                </IconButton>
              </Stack>
            </FormControl>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="right"
            alignItems="center"
            // sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <Box
              sx={{
                // position: "relative",
                display: { xs: "none", sm: "none", md: "block" },
              }}
              className="item1"
            >
              <Stack direction="row" alignItems="center" className="pass">
                <Typography
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    padding: "23% 0%",
                  }}
                >
                  Find Work
                </Typography>
                <KeyboardArrowDown
                  className="item1Icon"
                  sx={{
                    color: "black",
                    fontSize: "14pt",
                    transition: "all 0.4s",
                    cursor: "pointer",
                  }}
                />
              </Stack>
              <Paper
                elevation={3}
                className="item1MenuList"
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  zIndex: 2,
                  bgcolor: "white",
                  width: "100%",
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  display: "none",
                }}
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        margin: 3,
                        padding: 2,
                        "&:hover": {
                          bgcolor: "#f2f7e0",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Stack>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "11pt", fontWeight: 600 }}
                        >
                          Way to earn
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "9pt", fontWeight: 400 }}
                          onClick={() => {
                            window.open("https://community.ghorami.com/forum/");
                          }}
                        >
                          Learn why Ghorami has the right opportunities for you.
                        </Typography>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        margin: 3,
                        padding: 2,
                        "&:hover": {
                          bgcolor: "#f2f7e0",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Stack>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "11pt", fontWeight: 600 }}
                        >
                          Find work for your skills
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "9pt", fontWeight: 400 }}
                          onClick={() => {
                            window.open("https://community.ghorami.com/forum/");
                          }}
                        >
                          Explore the kind of work available in your field.
                        </Typography>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        margin: 3,
                        padding: 2,
                        "&:hover": {
                          bgcolor: "#f2f7e0",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Stack>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "11pt", fontWeight: 600 }}
                        >
                          Find ways to promote yourself
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "9pt", fontWeight: 400 }}
                          onClick={() => {
                            window.open("https://community.ghorami.com/forum/");
                          }}
                        >
                          Show clients you’re the one they want.
                        </Typography>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Paper>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
              }}
              className="item1"
            >
              <Stack direction="row" alignItems="center" className="pass">
                <Typography
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    padding: "23% 0%",
                  }}
                >
                  Find Expert
                </Typography>
                <KeyboardArrowDown
                  className="item1Icon"
                  sx={{
                    color: "black",
                    fontSize: "14pt",
                    transition: "all 0.4s",
                    cursor: "pointer",
                  }}
                />
              </Stack>
              <Paper
                elevation={3}
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  zIndex: 2,
                  bgcolor: "white",
                  width: "100%",
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  display: "none",
                }}
                className="item1MenuList"
              >
                <Grid container rowSpacing={0} columnSpacing={1}>
                  <Grid item xs={4}>
                    <Stack
                      spacing={1}
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Paper
                        onMouseEnter={() => {
                          setShowData(1);
                        }}
                        elevation={0}
                        sx={{
                          marginTop: 2,
                          padding: 2,
                          width: "60%",
                          "&:hover": {
                            bgcolor: "#f2f7e0",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                            >
                              Post a job and hire a pro
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Talent Marketplace
                            </Typography>
                          </Stack>
                          <KeyboardArrowRight />
                        </Stack>
                      </Paper>
                      <Paper
                        onMouseEnter={() => {
                          setShowData(2);
                        }}
                        elevation={0}
                        sx={{
                          // margin: 3,
                          width: "60%",
                          padding: 2,
                          "&:hover": {
                            bgcolor: "#f2f7e0",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                            >
                              Browse and Buy Projects
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Project Catalog
                            </Typography>
                          </Stack>
                          <KeyboardArrowRight />
                        </Stack>
                      </Paper>
                      <Paper
                        onMouseEnter={() => {
                          setShowData(3);
                        }}
                        elevation={0}
                        sx={{
                          // margin: 3,
                          width: "60%",
                          padding: 2,
                          "&:hover": {
                            bgcolor: "#f2f7e0",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                            >
                              Post a service and sell it
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Service Marketplace
                            </Typography>
                          </Stack>
                          <KeyboardArrowRight />
                        </Stack>
                      </Paper>
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>
                    {showData === 1 && (
                      <Box
                        sx={{
                          borderLeft: "1px dotted gray",
                          mt: 1,
                          paddingLeft: 3,
                        }}
                      >
                        <Grid container rowSpacing={1} columnSpacing={1}>
                          <Grid item xs={3} sx={{}}>
                            <Box
                              mt={2}
                              sx={{
                                bgcolor: "lightgreen",
                                height: "4px",
                                width: "50px",
                              }}
                            ></Box>
                            <Stack mt={2} spacing={2}>
                              <Typography variant="p">
                                Talent MarketplaceTM
                              </Typography>
                              <Typography variant="p" sx={{ fontSize: "10pt" }}>
                                Browse and buy projects that have a clear scope
                                and price.
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent={"left"}
                                alignItems="center"
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "10pt",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Hire on Talent Marketplace
                                </Typography>
                                <ArrowRightAlt
                                  sx={{
                                    color: "blue",
                                    fontSize: "14pt",
                                    cursor: "pointer",
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={7} my={5} sx={{ marginLeft: "40px" }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                              {categoryData.map((element) => (
                                <Grid item xs={4} key={element.refer}>
                                  <CardItem categoryData={element} />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {showData === 2 && (
                      <Box
                        sx={{
                          borderLeft: "1px dotted gray",
                          mt: 1,
                          paddingLeft: 3,
                        }}
                      >
                        <Grid container rowSpacing={1} columnSpacing={1}>
                          <Grid item xs={3} sx={{}}>
                            <Box
                              mt={2}
                              sx={{
                                bgcolor: "lightgreen",
                                height: "4px",
                                width: "50px",
                              }}
                            ></Box>
                            <Stack mt={2} spacing={2}>
                              <Typography variant="p">
                                Project CatalogTM
                              </Typography>
                              <Typography variant="p" sx={{ fontSize: "10pt" }}>
                                Browse and buy projects that have a clear scope
                                and price.
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent={"left"}
                                alignItems="center"
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "10pt",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Browse Project Catalog
                                </Typography>
                                <ArrowRightAlt
                                  sx={{
                                    color: "blue",
                                    fontSize: "14pt",
                                    cursor: "pointer",
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={7} my={5} sx={{ marginLeft: "40px" }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                              {categoryData.map((element) => (
                                <Grid item xs={4} key={element.refer}>
                                  <CardItem categoryData={element} />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {showData === 3 && (
                      <Box
                        sx={{
                          borderLeft: "1px dotted gray",
                          mt: 1,
                          paddingLeft: 3,
                        }}
                      >
                        <Grid container rowSpacing={1} columnSpacing={1}>
                          <Grid item xs={3} sx={{}}>
                            <Box
                              mt={2}
                              sx={{
                                bgcolor: "lightgreen",
                                height: "4px",
                                width: "50px",
                              }}
                            ></Box>
                            <Stack mt={2} spacing={2}>
                              <Typography variant="p">
                                Talent ScoutTM{" "}
                              </Typography>
                              <Typography variant="p" sx={{ fontSize: "10pt" }}>
                                Browse and buy projects that have a clear scope
                                and price.
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent={"left"}
                                alignItems="center"
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "10pt",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Talk to Talent Scout
                                </Typography>
                                <ArrowRightAlt
                                  sx={{
                                    color: "blue",
                                    fontSize: "14pt",
                                    cursor: "pointer",
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={7} my={5} sx={{ marginLeft: "40px" }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                              {categoryData.map((element) => (
                                <Grid item xs={4} key={element.refer}>
                                  <CardItem categoryData={element} />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Box
              sx={{
                // position: "relative",
                display: { xs: "none", sm: "none", md: "block" },
              }}
              className="item1"
            >
              <Stack direction="row" alignItems="center" className="pass">
                <Typography
                  variant="h1"
                  sx={{
                    color: "black",
                    fontSize: "12pt",
                    cursor: "pointer",
                    padding: "23% 0%",
                  }}
                >
                  Why Ghorami
                </Typography>
                <KeyboardArrowDown
                  className="item1Icon"
                  sx={{
                    color: "black",
                    fontSize: "14pt",
                    transition: "all 0.4s",
                    cursor: "pointer",
                  }}
                />
              </Stack>
              <Paper
                elevation={3}
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  zIndex: 2,
                  bgcolor: "white",
                  width: "100%",
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                }}
                className="item1MenuList"
              >
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      m={3}
                      sx={{ paddingX: 5 }}
                    >
                      <Grid item xs={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            // margin: 3,
                            padding: 2,
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 600 }}
                            >
                              Success Stories
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "9pt", fontWeight: 400 }}
                              onClick={() => {
                                window.open(
                                  "https://community.ghorami.com/forum/"
                                );
                              }}
                            >
                              Learn why Ghorami has the right opportunities for
                              you.
                            </Typography>
                          </Stack>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            // margin: 3,
                            padding: 2,
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 600 }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "9pt", fontWeight: 400 }}
                              onClick={() => {
                                window.open(
                                  "https://community.ghorami.com/forum/"
                                );
                              }}
                            >
                              Explore the kind of work available in your field.
                            </Typography>
                          </Stack>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            // margin: 3,
                            padding: 2,
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 600 }}
                            >
                              How to hire
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "9pt", fontWeight: 400 }}
                              onClick={() => {
                                window.open(
                                  "https://community.ghorami.com/forum/"
                                );
                              }}
                            >
                              Learn why Ghorami has the right opportunities for
                              you.
                            </Typography>
                          </Stack>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            // margin: 3,
                            padding: 2,
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 600 }}
                            >
                              How to find work
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{ fontSize: "9pt", fontWeight: 400 }}
                              onClick={() => {
                                window.open(
                                  "https://community.ghorami.com/forum/"
                                );
                              }}
                            >
                              Explore the kind of work available in your field.
                            </Typography>
                          </Stack>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        margin: 3,
                        padding: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "11pt", fontWeight: 600 }}
                        >
                          Where work gets done
                        </Typography>
                        <Card
                          elevation={0}
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            width: "40%",
                            border: "1px solid #57B7E7",
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Guides
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                              onClick={() => {
                                window.open("http://community.ghorami.com/");
                              }}
                            >
                              Getting Started as a Freelancer
                            </Typography>
                          </Stack>
                        </Card>
                        <Card
                          elevation={0}
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            width: "40%",
                            border: "1px solid #57B7E7",
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Guides
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                              onClick={() => {
                                window.open("http://community.ghorami.com/");
                              }}
                            >
                              Getting Started as an Agency
                            </Typography>
                          </Stack>
                        </Card>
                        <Card
                          elevation={0}
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            width: "40%",
                            border: "1px solid #57B7E7",
                            "&:hover": {
                              bgcolor: "#f2f7e0",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Stack>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "10pt", fontWeight: 400 }}
                            >
                              Guides
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              sx={{ fontSize: "11pt", fontWeight: 500 }}
                              onClick={() => {
                                window.open("http://community.ghorami.com/");
                              }}
                            >
                              Getting Started as a Buyer
                            </Typography>
                          </Stack>
                        </Card>
                        <Stack
                          direction="row"
                          justifyContent={"left"}
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              color: "black",
                              cursor: "pointer",
                              fontSize: "10pt",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              window.open("http://community.ghorami.com/");
                            }}
                          >
                            See Resources
                          </Typography>
                          <ArrowRightAlt
                            sx={{
                              color: "blue",
                              fontSize: "14pt",
                              cursor: "pointer",
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  window.open("http://user.ghorami.com/");
                }}
              >
                <Typography variant="outlined" sx={{ fontWeight: 600 }}>
                  SignIn
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: "#009C8B" }}
                // onClick={() => {
                //   navigate("/usertype");
                // }}
                onClick={() => {
                  window.open("http://user.ghorami.com/signup");
                }}
              >
                <Typography variant="caption">SignUp</Typography>
              </Button>
            </Stack>
          </Stack>
          <IconButton
            color="inherit"
            style={{ transitionDuration: "5s", transitionDelay: "2s" }}
            onClick={() => {
              setActiveMainMenu(!activeMainMenu);
            }}
            sx={{ mr: 2, display: { md: "none" }, transition: "transform .5s" }}
          >
            <Menu
              sx={{ color: "black" }}
              className={activeMainMenu ? "iconFlip" : ""}
            />
          </IconButton>
        </Stack>
        {activeMainMenu && (
          <List
            sx={{
              display: { xs: "block", md: "none" },
              overflow: "hidden",
            }}
          >
            {menuBarData?.map((element) => (
              <ListItem disablePadding className="item1Small">
                <Stack direction="column" sx={{ width: "100%" }}>
                  <ListItemButton>
                    <ListItemText
                      primary={element?.title}
                      sx={{ color: "black" }}
                    />
                    <IconButton
                      aria-label="delete"
                      size="small"
                      // onClick={() => {
                      //   setActiveSmallList1(!activeSmallList1);
                      // }}
                      onClick={() => {
                        navigate("/explore/" + element.id);
                      }}
                    >
                      {/* {!activeSmallList1 && <Add fontSize="inherit" />}
                      {activeSmallList1 && <Remove fontSize="inherit" />} */}
                    </IconButton>
                  </ListItemButton>
                  {/* {activeSmallList1 && (
                    <List
                      sx={{
                        bgcolor: "white",
                        width: "100%",
                      }}
                      className="item1SmallMenuList"
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemText
                            primary="Trash"
                            sx={{ color: "black" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton component="a" href="#simple-list">
                          <ListItemText
                            primary="Spam"
                            sx={{ color: "black" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  )} */}
                </Stack>
              </ListItem>
            ))}
            {/* <ListItem disablePadding className="item1Small">
              <Stack direction="column" sx={{ width: "100%" }}>
                <ListItemButton>
                  <ListItemText primary="Main Page" sx={{ color: "black" }} />
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setActiveSmallList1(!activeSmallList1);
                    }}
                  >
                    {!activeSmallList1 && <Add fontSize="inherit" />}
                    {activeSmallList1 && <Remove fontSize="inherit" />}
                  </IconButton>
                </ListItemButton>
                {activeSmallList1 && (
                  <List
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                    }}
                    className="item1SmallMenuList"
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary="Trash" sx={{ color: "black" }} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component="a" href="#simple-list">
                        <ListItemText primary="Spam" sx={{ color: "black" }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                )}
              </Stack>
            </ListItem>
            <ListItem disablePadding>
              <Stack direction="column" sx={{ width: "100%" }}>
                <ListItemButton>
                  <ListItemText primary="Browse Jobs" sx={{ color: "black" }} />
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setActiveSmallList2(!activeSmallList2);
                    }}
                  >
                    {!activeSmallList2 && <Add fontSize="inherit" />}
                    {activeSmallList2 && <Remove fontSize="inherit" />}
                  </IconButton>
                </ListItemButton>
                {activeSmallList2 && (
                  <List
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                    }}
                    className="item1SmallMenuList"
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText
                          primary="page 1"
                          sx={{ color: "black" }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component="a" href="#simple-list">
                        <ListItemText
                          primary="page 2"
                          sx={{ color: "black" }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component="a" href="#simple-list">
                        <ListItemText
                          primary="page 3"
                          sx={{ color: "black" }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component="a" href="#simple-list">
                        <ListItemText primary="Spam" sx={{ color: "black" }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                )}
              </Stack>
            </ListItem> */}
            <ListItem disablePadding sx={{ mt: "10px" }}>
              <Button
                variant="contained"
                sx={{ bgcolor: "black" }}
                onClick={() => {
                  window.open("http://user.ghorami.com/signup");
                }}
              >
                <Typography variant="caption">Register</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: "tomato", ml: "20px" }}
                onClick={() => {
                  window.open("http://user.ghorami.com/");
                }}
              >
                <Typography variant="caption">Sign In</Typography>
              </Button>
            </ListItem>
          </List>
        )}
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

// Header.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };
export default Header;
