import { Grid, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { topSkillApi } from "../../API/Api";
import BrowseSkillItem from "./BrowseSkillItem";

const BrowseSkill = ({ id }) => {
  const [skillsData, setSkillsData] = useState([]);

  useEffect(() => {
    console.log("client dhukse");
    fetch(
      `https://ghorami.com/profile/login/api_webland/top_skill.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setSkillsData(result);
      })
      .catch((error) => {
        console.log("fetch skills Failed", error);
      });
  }, []);

  return (
    <Container sx={{ mt: "40px" }}>
      <Stack justifyContent="center" alignItems="center">
        <Typography
          variant="p"
          mt={3}
          sx={{ fontWeight: 700, fontSize: "25pt" }}
        >
          Browse Skills and Locations
        </Typography>
        <Typography
          variant="p"
          mb={3}
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          Look for the best meeting your skills and most preferred locations
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {skillsData.map((element) => (
          <Grid item xs={6} sm={4} md={2} key={element.refer}>
            <BrowseSkillItem skillsData={element} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BrowseSkill;
