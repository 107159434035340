import {
  ArrowRightAlt,
  CalendarMonth,
  CheckCircleRounded,
  Comment,
  Favorite,
  ModeComment,
  Star,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
const BlogItemShort = (props) => {
  const navigate = useNavigate();
  const { blogData } = props;
  const { blog_id, pic, category, con_image, headline } = blogData;
  // let detail = details.slice(0, 200);
  return (
    <Stack
      sx={{ width: "95%", border: "1px solid #dfdddd", borderRadius: "12px" }}
    >
      <Box
        sx={{ height: "23vh", position: "relative" }}
        onClick={() => {
          navigate("/blogdetails/" + pic);
        }}
      >
        {con_image?.[0]?.pic_url ? (
          <CardMedia
            component="img"
            height="100%"
            image={"https://yeapbe.com/" + con_image?.[0]?.pic_url}
            // image={blogDetails?.con_image?.[0]?.pic_url}
            alt={headline}
            sx={{ borderRadius: "12px" }}
          />
        ) : (
          <CardMedia
            component="img"
            height="100%"
            image={"https://ghorami.com/image/catPic/creative_work.jpg"}
            // image={blogDetails?.con_image?.[0]?.pic_url}
            alt={headline}
            sx={{ borderRadius: "12px" }}
          />
        )}

        {/* <CardMedia
          component="img"
          height="100%"
          image={""}
          alt={headline}
          sx={{ borderRadius: "12px" }}
        /> */}
      </Box>

      <Stack spacing={0} sx={{ mt: "10px" }}>
        <Stack
          spacing={1}
          sx={{
            paddingTop: "2%",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingBottom: "2%",
            height: "9vh",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{}}>
            <Typography
              gutterBottom
              variant="p"
              sx={{ fontSize: { xs: "10pt", md: "15px" }, fontWeight: 600 }}
              component="div"
            >
              {headline}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BlogItemShort;
