import {
  ArrowRightAlt,
  CalendarMonth,
  CheckCircleRounded,
  Comment,
  Favorite,
  ModeComment,
  Star,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./BlogItem.css";

const BlogItem = (props) => {
  const navigate = useNavigate();
  const { blogData } = props;
  console.log("blogDataaaa:", blogData);
  const { blog_id, pic, category, article_info, con_image, title, timeStamp } =
    blogData;
  let detail = article_info?.[0]?.details.slice(0, 200);
  return (
    <Card
      sx={{
        width: "100%",
        height: "auto",
        cursor: "pointer",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
      onClick={() => {
        navigate("/blogdetails/" + pic);
      }}
    >
      <Stack
        sx={{
          width: "100%",
          //   border: "1px solid #dfdddd",
          borderRadius: "12px",
        }}
      >
        <Box sx={{ height: "30vh", position: "relative" }}>
          {con_image?.[0]?.pic_url ? (
            <CardMedia
              component="img"
              height="100%"
              image={"https://yeapbe.com/" + con_image?.[0]?.pic_url}
              alt={title}
              sx={{ borderRadius: "12px" }}
            />
          ) : (
            <CardMedia
              component="img"
              height="100%"
              image={"https://ghorami.com/image/catPic/creative_work.jpg"}
              alt={title}
              sx={{ borderRadius: "12px" }}
            />
          )}
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{
              width: "100px",
              height: "50px",
              bgcolor: "black",
              transform: "skewX(40deg)",
              borderTopRightRadius: "7%",
              borderBottomLeftRadius: "7%",
              position: "absolute",
              bottom: 0,
              left: -16,
            }}
          >
            <Typography
              sx={{
                transform: "skewX(-40deg)",
                fontSize: "18pt",
                fontWeight: 700,
                color: "white",
              }}
            >
              #{blog_id}
            </Typography>
          </Stack>
        </Box>

        <Stack spacing={0} sx={{ mt: "10px" }}>
          <Stack
            spacing={0}
            sx={{
              paddingTop: "4%",
              paddingLeft: "8%",
              paddingRight: "8%",
              paddingBottom: "4%",
              height: "9vh",
            }}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box sx={{}}>
              <Typography
                gutterBottom
                variant="p"
                sx={{ fontSize: { xs: "10pt", md: "10pt" }, fontWeight: 600 }}
                component="div"
              >
                {title}
              </Typography>
            </Box>
            <Stack
              spacing={1}
              direction={"row"}
              jusatifyContent="left"
              alignItems={"flex-start"}
            >
              <Typography variant="p" sx={{ color: "gray", fontSize: "11pt" }}>
                {timeStamp}
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "gray", fontSize: "11pt", fontWeight: 400 }}
              >
                |
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "#397FE6", fontSize: { xs: "10pt", md: "10pt" } }}
              >
                {category}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default BlogItem;
