import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const FreelancerCommentItem = (props) => {
  const navigate = useNavigate();
  console.log("props-22:", props);
  const { data, order, align, textAlign } = props;
  const { banner_pic, title, info, subtitle, refer, type, freelancer_id } =
    data;
  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ height: "70vh", width: "100%" }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems={"center"}
          sx={{ width: "50%", height: "100%", order: order }}
        >
          <Box
            component="img"
            sx={{
              // padding: "0 12px",
              height: "80%",

              // display: { xs: "none", sm: "none", md: "block" },
            }}
            alt={title}
            src={banner_pic}
          />
        </Stack>
        <Stack spacing={2} sx={{ width: "50%", alignItems: align }}>
          <Typography
            variant="p"
            color="black"
            sx={{ fontSize: "22pt", fontWeight: 700, textAlign: textAlign }}
          >
            {title}
          </Typography>
          <Typography
            variant="p"
            color="black"
            sx={{ fontSize: "14pt", fontWeight: 600, textAlign: textAlign }}
          >
            {subtitle}
          </Typography>
          <Typography variant="p" color={"black"} sx={{ textAlign: textAlign }}>
            {info}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              bgcolor: "#14506A",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#22AB7D",
              },
            }}
            onClick={() => {
              navigate("/profiledetails/" + freelancer_id);
            }}
            size="small"
          >
            Read more
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default FreelancerCommentItem;
