import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { serviceApi } from "../../API/Api";

import ServiceCardItem from "./ServiceCardItem";

const TrendingServices = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(serviceApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result.slice(0, 4));
      });
  }, []);

  return (
    <Box mt={5}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ paddingX: "15px" }}
      >
        <Typography
          variant="p"
          sx={{ fontWeight: 700, fontSize: { xs: "22pt", md: "29pt" } }}
        >
          Popular Services
        </Typography>
        <Typography
          variant="p"
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          Most viewed and all-time top-selling services
        </Typography>
      </Stack>
      <Container sx={{ mt: "20px" }}>
        <Grid container spacing={2} mt={4} mb={4}>
          {data?.map((element) => (
            <Grid item xs={6} sm={4} md={3} key={element.service_refer}>
              <ServiceCardItem data={element} key={element.service_refer} />
            </Grid>
          ))}
        </Grid>
        <Stack direction={"row"} justifyContent="center" alignItems={"center"}>
          <Button
            variant="outlined"
            color="green"
            sx={{
              fontFamily: "Roboto Condensed",
              fontWeight: 400,
              fontSize: "23pt",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              navigate("/servicelist");
            }}
          >
            Load more...
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default TrendingServices;
