import { Box } from "@mui/system";
import React from "react";
import Banner from "../Component/Homepage Components/Banner/Banner";
import BetterMatch from "../Component/Homepage Components/BetterMatch/BetterMatch";
import BrowseSkill from "../Component/Homepage Components/BrowseSkills/BrowseSkill";
import Categories from "../Component/Homepage Components/Categories/Categories";
import Agency from "../Component/Homepage Components/Agency/Agency";
import Features from "../Component/Homepage Components/TopClientConnect/TopClientConnect";
import Freelancer from "../Component/Homepage Components/Freelancer/Freelancer";
import GetStarted from "../Component/Get Started/GetStarted";
import Header from "../Component/header/Header";
import Keypoints from "../Component/Homepage Components/Keypoints/Keypoints";
import LatestJob from "../Component/Homepage Components/Latest Job/LatestJob";
import LatestBlogNews from "../Component/Homepage Components/LatestBlogNews/LatestBlogNews";
import MenuTags from "../Component/Menu/MenuTags";
import StartEarning from "../Component/Homepage Components/Start Earning/StartEarning";
import Testimonial from "../Component/Homepage Components/Testimonial/Testimonial";
import TrendingServices from "../Component/Homepage Components/Trending Services/TrendingServices";
import TopClientConnect from "../Component/Homepage Components/TopClientConnect/TopClientConnect";
import AppDownload from "../Component/Homepage Components/App Download/AppDownload";
import Footer from "../Component/Homepage Components/Footer/Footer";
import FooterBottom from "../Component/Homepage Components/Footer/FooterBottom";
import HomePageJobs from "../Component/Homepage Components/HomePageJobs";

const Home = () => {
  return (
    <Box>
      <Banner />
      <Categories />
      <Keypoints />
      <TrendingServices />

      {/* <FreelancerCard /> */}
      <Freelancer />
      <StartEarning />
      {/* <LatestJob /> */}
      <HomePageJobs />
      <Testimonial />
      <Agency />
      <LatestBlogNews />
      <TopClientConnect />
      {/* <BetterMatch /> */}
      <BrowseSkill />
      <GetStarted />
      <AppDownload />
    </Box>
  );
};

export default Home;
