import { Apps, FormatListBulleted, Menu, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { serviceApi } from "../Component/API/Api";
import ServiceCardItem from "../Component/Homepage Components/Trending Services/ServiceCardItem";
import ServiceCardItemFlat from "../Component/Homepage Components/Trending Services/ServiceCardItemFlat";
import CategoryList from "../Component/ServiceList Components/CategoryList";

import loading from "../images/loader.gif";
import ResCategoryList from "../Component/ServiceList Components/ResCategoryList";
import { useParams } from "react-router-dom";

const ServiceListExplore = () => {
  const { refer } = useParams();
  console.log("refdsdsder:", refer);
  const [firstLoadIndegator, setFirstLoadIndegator] = useState(true);
  const [resLeftToggle, setResLeftToggle] = useState(false);
  console.log("resLeftToggle:", resLeftToggle);
  const [age, setAge] = useState("");
  const [searchFieldText, setSearchFieldText] = useState("");
  const [viewMode, setViewMode] = useState("grid");
  const [exRefer, setExRefer] = useState("1");
  const [serviceListData, setServiceListData] = useState([]);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setFirstLoadIndegator(false);
    setPage(value);
  };
  // useEffect(() => {
  //   if (window.innerWidth > 1000) {
  //     console.log("window.screen.width:", window.innerWidth);
  //     setResLeftToggle(false);
  //   }
  //   console.log("window.screen.width:", window.innerWidth);
  // }, [window.innerWidth]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("pref", exRefer);
    formData.append("page", page);

    if (refer !== "" || refer !== "undefined") {
      fetch("https://ghorami.com/profile/login/api_service/j_service_cat.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setServiceListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    } else {
      fetch(serviceApi)
        .then((res) => res.json())
        .then((result) => {
          setServiceListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
  }, [page, refer]);

  // useEffect(() => {
  //   fetch(serviceApi)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setServiceListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, []);
  // console.log("serviceListDatahhhhhh:", serviceListData);

  const handleSearch = () => {
    setFirstLoadIndegator(false);

    fetch(
      `https://ghorami.com/profile/login/api_service/all_service_search.php?s_query=${searchFieldText}`
    )
      .then((res) => res.json())
      .then((result) => {
        setServiceListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Box>
      <Container sx={{ marginTop: { xs: "70px", md: "20px" } }}>
        <Grid container spacing={2}>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              display: { xs: "none", md: "grid" },
            }}
          >
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                boxSizing: "border-box",
                minHeight: "90vh",
                padding: "20px",
                overflow: "auto",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <CategoryList
                  setServiceListData={setServiceListData}
                  page={page}
                  setFirstLoadIndegator={setFirstLoadIndegator}
                />

                <Divider light sx={{ marginBottom: "10px" }} />
              </Stack>
            </Paper>
          </Grid>

          <Paper
            elevation={3}
            sx={{
              display: { xs: resLeftToggle ? "grid" : "none", md: "none" },
              width: "100%",
              boxSizing: "border-box",
              minHeight: "90vh",
              padding: "20px",
              overflow: "auto",
              position: "absolute",
              top: "60px",
              left: "0px",
              zIndex: 5,
              // marginTop: "50px",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <ResCategoryList
                setServiceListData={setServiceListData}
                page={page}
                setFirstLoadIndegator={setFirstLoadIndegator}
                setResLeftToggle={setResLeftToggle}
              />

              <Divider light sx={{ marginBottom: "10px" }} />
            </Stack>
          </Paper>

          <Grid item xs={12} md={9}>
            <Stack
              justifyContent="flex-start"
              sx={{
                //  width: { xs: "100%", md: "70%" },
                padding: "2%",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{ width: { xs: "100%", md: "50%" } }}
                  spacing={2}
                >
                  <IconButton
                    size="small"
                    sx={{ display: { xs: "block", md: "none" } }}
                    onClick={() => setResLeftToggle(!resLeftToggle)}
                  >
                    <Menu />
                  </IconButton>
                  <OutlinedInput
                    color="success"
                    name="search_text"
                    size="small"
                    type="text"
                    sx={{ width: "60%" }}
                    onChange={(e) => {
                      setSearchFieldText(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          sx={{ marginTop: "5px", marginLeft: "10px" }}
                          onClick={() => {
                            handleSearch();
                          }}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"right"}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginRight: "5px" }}
                    onClick={() => {
                      setViewMode("grid");
                    }}
                  >
                    <Apps />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setViewMode("list");
                    }}
                  >
                    <FormatListBulleted />
                  </Button>
                </Stack>
              </Stack>

              {serviceListData[0]?.service_refer ? (
                <Box mt={2}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {viewMode === "list" &&
                      serviceListData?.map((data) => (
                        <Grid item xs={12} key={data.service_refer}>
                          <ServiceCardItemFlat data={data} />
                        </Grid>
                      ))}
                    {viewMode === "grid" &&
                      serviceListData?.map((data) => (
                        <Grid
                          item
                          xs={2}
                          sm={4}
                          md={4}
                          key={data.service_refer}
                        >
                          <ServiceCardItem data={data} />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginLeft: "200px",
                  }}
                >
                  {/* <Spinner animation="border" role="status" variant="success" /> */}
                  {firstLoadIndegator ? (
                    <img
                      src={loading}
                      alt="loading"
                      style={{
                        width: "120px",
                        height: "120px",
                      }}
                    />
                  ) : (
                    <Typography
                      variant="p"
                      sx={{ fontSize: "25pt", fontWeight: 600 }}
                    >
                      No data found!
                    </Typography>
                  )}
                </Box>
              )}

              <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />

              <Pagination
                count={10}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                color="primary"
                sx={{ margin: "auto" }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServiceListExplore;
