import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { dummyApi } from "../../API/Api";

const Contact = () => {
  const [inputField, setInputField] = useState({});
  const [contactInfo, setContactInfo] = useState({ sopnoId: "" });
  console.log("contactInfo:", contactInfo);

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "name") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (event.target.name === "email") {
      isFieldValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value);
    }
    if (event.target.name === "phone") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }
    if (event.target.name === "user_type") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "message") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newcontactInfo = contactInfo;
      newcontactInfo[event.target.name] = event.target.value;
      setContactInfo({ ...newcontactInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);

      const newcontactInfo = { ...contactInfo };
      newcontactInfo[event.target.name] = "";
      setContactInfo(newField);
    }
  };

  const handleSendReq = () => {
    const formData = new FormData();
    formData.append("request_info", JSON.stringify(contactInfo));

    fetch(dummyApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resuadasdadsdlt:", result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  return (
    <Container>
      <Stack mt={4}>
        {/* <Typography variant="p" sx={{ fontSize: "20pt", fontWeight: 600 }}>
          Send Us Following Information
        </Typography> */}
        <Box sx={{ paddingY: "5%", paddingX: "10%" }}>
          <Grid container spacing={2} sx={{ bgcolor: "" }}>
            <Grid item xs={12} md={6}>
              <Box>
                <Container>
                  <h3>Who are we?</h3>

                  <p class="text-left text-muted">
                    ghorami.com is largest service provider community in
                    Bangladesh. You can find/sell your service via ghorami.
                  </p>

                  <p class="text-left text-muted">
                    Navana Mokhles Monarch, room no- 60 (3/1) 2nd floor, 3-b,
                    purana paltan, Dhaka-1000.
                  </p>

                  <p class="text-left text-muted">
                    Website: https://ghorami.com
                  </p>
                  <p class="text-left text-muted">
                    email: ghoramidesk@ghorami.com
                  </p>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: "91%", height: "90%", padding: "15px" }}>
                <Box>
                  <Stack
                    spacing={2}
                    sx={{ paddingY: "5%", paddingX: "5%", bgcolor: "" }}
                  >
                    <Stack spacing={0.5}>
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Full Name
                      </Typography>

                      <OutlinedInput
                        placeholder="Please enter your name"
                        type="text"
                        name="name"
                        size="small"
                        id="bootstrap-input"
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Email
                      </Typography>

                      <OutlinedInput
                        //   defaultValue="react-bootstrap"
                        name="email"
                        placeholder="Please enter email"
                        type="email"
                        size="small"
                        id="bootstrap-input"
                        onChange={handleChange}
                      />
                    </Stack>

                    <Stack>
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Phone
                      </Typography>

                      <OutlinedInput
                        name="phone"
                        placeholder="Please enter phone"
                        type="number"
                        size="small"
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        User Type
                      </Typography>

                      <Select
                        size="small"
                        name="user_type"
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ borderRadius: "30px" }}
                      >
                        <MenuItem value="">Please Select One</MenuItem>
                        <MenuItem value={"Individual"}>Individual</MenuItem>
                        <MenuItem value={"Company"}>Company</MenuItem>
                      </Select>
                    </Stack>
                    <Stack>
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Message
                      </Typography>

                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={2}
                        name="message"
                        placeholder="Write message"
                        onChange={handleChange}
                        style={{
                          width: "100%",
                          borderRadius: "30px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          boxSizing: "border-box",
                        }}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      {contactInfo?.name &&
                      contactInfo?.phone &&
                      contactInfo?.email &&
                      contactInfo?.message &&
                      contactInfo?.user_type ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            handleSendReq();
                            // navigate("/joblist");
                          }}
                          sx={{
                            width: "20%",
                            borderRadius: "20px",
                            bgcolor: "#009C8B",
                            "&:hover": {
                              bgcolor: "#14506A",
                            },
                          }}
                        >
                          Send
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          size="small"
                          sx={{
                            width: "20%",
                            borderRadius: "20px",
                            bgcolor: "#009C8B",
                            "&:hover": {
                              bgcolor: "#14506A",
                            },
                          }}
                        >
                          Send
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};

export default Contact;
