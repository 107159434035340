import { Facebook, Language, LinkedIn, YouTube } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Accounts = (props) => {
  const { profileDetails } = props;
  const { facebook, linkedin, youtube, web } = profileDetails;

  const handleGo = (value) => {
    window.open(value, "_blank");
  };
  return (
    <Paper
      sx={{ padding: "30px", marginTop: "5px", border: "1px solid #dfdddd" }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paper
          elevation={8}
          sx={{
            borderRadius: "50%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "#3b5998",
              borderRadius: "50%",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#0cc78a",
              },
            }}
          >
            <Facebook
              sx={{
                color: "white",
              }}
              onClick={() => {
                handleGo(facebook);
              }}
            />
          </Stack>
        </Paper>
        <Paper
          elevation={8}
          sx={{
            borderRadius: "50%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "darkred",
              borderRadius: "50%",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#0cc78a",
              },
            }}
          >
            <YouTube
              sx={{
                color: "white",
              }}
              onClick={() => {
                handleGo(youtube);
              }}
            />
          </Stack>
        </Paper>
        <Paper
          elevation={8}
          sx={{
            borderRadius: "50%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "#0072b1",
              borderRadius: "50%",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#0cc78a",
              },
            }}
          >
            <LinkedIn
              sx={{
                color: "white",
              }}
              onClick={() => {
                handleGo(linkedin);
              }}
            />
          </Stack>
        </Paper>
        <Paper
          elevation={8}
          sx={{
            borderRadius: "50%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "#efb03e",
              borderRadius: "50%",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#0cc78a",
              },
            }}
          >
            <Language
              sx={{
                color: "white",
              }}
              onClick={() => {
                handleGo(web);
              }}
            />
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  );
};

export default Accounts;
