import React from "react";
export const masterApi = "https://ghorami.com";

export const nagadApi = "https://yeapbe.com/ghpay/nagad/index.php";

export const countryListApi =
  masterApi + "/profile/login/api_booking/country_list.php";
export const menuBarApi =
  masterApi + "/profile/login/api_webland/top_nav_category.php";
export const bannerApi =
  masterApi + "/profile/login/api_webland/top_banner.php";
export const keywordApi =
  masterApi + "/profile/login/api_webland/top_keyword.php";
export const serviceApi =
  masterApi + "/profile/login/api_service/all_service_list.php";
export const categoryApi =
  masterApi + "/profile/login/api_webland/top_category.php";
export const agencyApi =
  masterApi + "/profile/login/api_webland/top_agency.php";
export const AgencyListApi =
  masterApi + "/profile/login/api_webland/top_agency.php";
export const freelancerApi =
  masterApi + "/profile/login/api_freelancer/expert_all.php";
export const blogApi =
  masterApi + "/profile/login/api_spForum/fun_blog.php?requ_type=allBlog";
export const jobApi = masterApi + "/profile/login/api_job/job_all.php";
export const jobDetailsApi =
  masterApi + "/profile/login/api_job/job_details.php";
export const topClientApi =
  masterApi + "/profile/login/api_webland/top_Client_connect.php";
export const topSkillApi =
  masterApi + "/profile/login/api_webland/top_skill.php";
export const getStartedApi =
  masterApi + "/profile/login/api_webland/bottom_banner.php";
export const testimonialApi =
  masterApi + "/profile/login/api_webland/top_comment.php";

// Explore pages api

export const catBannerApi =
  masterApi + "/profile/login/api_webland/cat_banner.php";
export const catSublistApi =
  masterApi + "/profile/login/api_webland/cat_sublist.php";
export const catListInfoApi =
  masterApi + "/profile/login/api_webland/cat_listInfo.php";
export const freelancerCommentApi =
  masterApi + "/profile/login/api_webland/cat_freelancer.php";

// Service List Pages Api

export const serviceCategoryApi =
  masterApi + "/profile/login/api_service/all_service_category.php";

// Expert List Pages Api
export const ExpertSubCategoryApi =
  masterApi + "/profile/login/api/ap_get_subcat.php";

export const expertAllCategoryApi =
  masterApi + "/profile/login/api_freelancer/expert_all_category.php";

export const expertAllSubCategoryApi =
  masterApi + "/profile/login/api_freelancer/expert_all_sub.php";

// Booknow Pages Api

export const fetchUserApi =
  masterApi + "/profile/login/api_booking/fetch_user_info.php";

// ServiceDetails Pages Api

export const fetchedServiceDetailsApi =
  masterApi + "/profile/login/api_service/al_service_details.php";
export const fetchedServicePicApi =
  masterApi + "/profile/login/api_service/all_service_pic.php";
export const fetchedReviewApi =
  masterApi + "/profile/ac_rating/review_fetch.php";
export const fetchedPortfolioApi =
  masterApi + "/profile/login/api_freelancer/expert_portfolio_all.php";
export const PortfolioDetailsApi =
  masterApi + "/profile/login/api_freelancer/expert_portfolio_details.php";
export const SimilarServiceApi =
  masterApi + "/profile/login/api_service/al_similar_service_list.php";

// profileDetails Pages Api

export const fetchedPublicProfileDetailsApi =
  masterApi + "/profile/login/api_freelancer/expert_profile_public.php";

// Chatbot Api

export const ChatbotNewUserApi =
  masterApi + "/profile/ac_chatbot/ap_chat_user_new.php";

export const BotReplyOldApi =
  masterApi + "/profile/ac_chatbot/ap_get_bot_replay_old.php";

export const dummyApi =
  masterApi + "/profile/ac_chatbot/ap_get_bot_replay_old_dummy.php";

export const BotReplyNewApi =
  masterApi + "/profile/ac_chatbot/ap_get_bot_replay.php";

// BlogDetails Pages Api

export const fetchedBlogDetailsApi =
  "https://sopnobari.com/api/api_new_web/blog_details_api.php";

const Api = () => {
  return <div></div>;
};

export default Api;
