import { Button, Hidden, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

const StartEarning = () => {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Box
        sx={{
          width: "30%",
          height: "45vh",
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            // height: "100%",
            // objectFit: "contain",
            overflow: "hidden",

            // display: { xs: "none", sm: "none", md: "block" },
          }}
          alt="The house from the offer."
          src="https://amentotech.com/projects/workreap/wp-content/uploads/2021/03/icon-01-1.png"
        />
      </Box>
      <Stack
        sx={{
          width: { xs: "100%", md: "40%" },
          marginY: { xs: "50px", md: "0px" },
        }}
        spacing={2}
        justifyContent={"center"}
      >
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            color: "#323232",
            fontSize: "27px",
            fontWeight: 500,
          }}
        >
          Join Now & Start Earning
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            color: "gray",
            fontSize: "15px",
            fontWeight: 400,
          }}
        >
          Develop meaningful relationships with talented ghorami expert
          worldwide that will last far beyond your traditional connection
          requests!
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Button
            variant="contained"
            sx={{
              width: "45%",
              backgroundColor: "black",
              borderRadius: "20px",
              fontWeight: 600,
              fontSize: "14px",
            }}
            onClick={() => {
              window.open("http://user.ghorami.com/");
            }}
          >
            I Want To service
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "45%",
              backgroundColor: "#009C8B",
              borderRadius: "20px",
              fontWeight: 600,
              fontSize: "14px",
            }}
            onClick={() => {
              window.open("http://user.ghorami.com/");
            }}
          >
            I Want To Work
          </Button>
        </Stack>
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            color: "gray",
            fontSize: "15px",
            fontWeight: 400,
          }}
        >
          <span
            style={{ color: "#59b8d7", cursor: "pointer" }}
            onClick={() => {
              window.open("https://ghorami.com/terms");
            }}
          >
            Terms and Conditions
          </span>{" "}
          applies. read our{" "}
          <span
            style={{ color: "#59b8d7", cursor: "pointer" }}
            onClick={() => {
              window.open("https://ghorami.com/privacypolicy");
            }}
          >
            Privacy Policy
          </span>{" "}
          to get started
        </Typography>
      </Stack>
      <Box
        sx={{
          width: "30%",
          height: "45vh",
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            overflow: "hidden",
            // display: { xs: "none", sm: "none", md: "block" },
          }}
          alt="gDesk"
          src="https://amentotech.com/projects/workreap/wp-content/uploads/2021/03/img-02-1.png"
        />
      </Box>
    </Stack>
  );
};

export default StartEarning;
