import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { categoryApi, serviceCategoryApi } from "../Component/API/Api";
import { useRef } from "react";
import { Box, Stack } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import "../Component/ServiceList Components/CategoryList.css";

const CategoryListJob = ({
  setServiceListData,
  page,
  setFirstLoadIndegator,
  setCategory,
  setSubCategory,
}) => {
  console.log("setServiceListData:", setServiceListData);

  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [subCatToggle, setSubCatToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  const [subCategoryHeight, setSubCategoryHeight] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  console.log("categoryListData:", categoryListData);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [source, setSource] = useState("category");
  const [sourceValueId, setSourceValueId] = useState("");
  console.log("subCategoryListData:", subCategoryListData);

  const [open, setOpen] = React.useState(false);
  console.log("opesasasn:", open);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  useEffect(() => {
    fetch(serviceCategoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [categoryListData[0], open]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("pref", sourceValueId);
    formData.append("page", page);
    console.log("posteka", "");

    if (source === "subCategory") {
      fetch("https://ghorami.com/profile/login/api_job/job_cate_sub.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setServiceListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    } else {
      fetch("https://ghorami.com/profile/login/api_job/job_cate.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setServiceListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
  }, [page]);

  const handleCategoryItems = (cat_id) => {
    setCategory(cat_id);
    const formData = new FormData();
    formData.append("pref", cat_id);
    console.log("posteka", "");

    fetch("https://ghorami.com/profile/login/api_job/job_cate.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setServiceListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };
  const handleSubCategory = (cat_id) => {
    setCategory(cat_id);
    setFirstLoadIndegator(false);

    if (open === cat_id) {
      setOpen(false);
    } else {
      const formData = new FormData();
      formData.append("category_id", cat_id);
      console.log("posteka", "");

      fetch(
        "https://ghorami.com/profile/login/api_service/all_service_subcategory.php",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setSubCategoryListData(result);
          if (result[0]?.subcategory_id) {
            setOpen(cat_id);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
    setSource("category");
    setSourceValueId(cat_id);
  };

  const handleSubCatItems = (sub_cat) => {
    setSubCategory(sub_cat);
    setFirstLoadIndegator(false);

    setSource("subCategory");
    setSourceValueId(sub_cat);

    const formData = new FormData();
    formData.append("pref", sub_cat);
    console.log("posteka", "");

    fetch("https://ghorami.com/profile/login/api_job/job_cate_sub.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setServiceListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };

  // const handleCatItems = (cat) => {
  //   setSource("category");
  //   const formData = new FormData();
  //   formData.append("pref", cat);
  //   console.log("posteka:", localData?.sopnoid);

  //   fetch("https://ghorami.com/profile/login/api_job/job_cate.php", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setServiceListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // };
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
          Categories
        </Typography>
        <IconButton
          size="medium"
          onClick={() => {
            handleToggle();
          }}
        >
          <KeyboardArrowDown
            fontSize="inherit"
            color="black"
            sx={{
              transform: toggle === false ? "rotate(-180deg)" : "",
              transition: "transform 1s",
            }}
          />
        </IconButton>
      </Stack>
      <Box
        className={toggle === false ? "lowCategoryHeight" : ""}
        sx={{
          height:
            categoryHeight && toggle ? `${categoryHeight}px !important` : "0px",
          // overflow: "hidden",
          transition: "height 1s",
        }}
      >
        <List
          sx={{ width: "100%", backgroundColor: "background.paper" }}
          ref={focus}
        >
          {categoryListData.map((value) => (
            <>
              <ListItem
                key={value.title}
                disableGutters
                sx={{ padding: 0, cursor: "pointer" }}
                secondaryAction={
                  <IconButton
                    aria-label="comment"
                    size="small"
                    sx={{}}
                    variant="outlined"
                    onClick={() => {
                      handleSubCategory(value?.category_id);
                    }}
                  >
                    <KeyboardArrowDown
                      fontSize="inherit"
                      color="black"
                      sx={{
                        transform:
                          open === value?.category_id && open != false
                            ? "rotate(-180deg)"
                            : "",
                        transition: "transform 2s",
                      }}
                    />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`${value.category_name}`}
                  sx={{}}
                  onClick={() => {
                    handleCategoryItems(value?.category_id);
                  }}
                />
              </ListItem>
              <Collapse
                in={open === value?.category_id}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {subCategoryListData.map((sub) => (
                    <ListItem
                      key={sub.title}
                      disableGutters
                      sx={{ padding: 0, cursor: "pointer" }}
                    >
                      <ListItemText
                        primary={`${sub?.subcategory_name}`}
                        sx={{ paddingLeft: "15px" }}
                        onClick={() => {
                          handleSubCatItems(sub?.subcategory_id);
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CategoryListJob;
