import {
  Button,
  Grid,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchedPortfolioApi } from "../../../API/Api";
import useGridPhoto from "../../../Custom Hooks/useGridPhoto";
import PortfolioModal from "../PortfolioModal/PortfolioModal";
import "./portfolio.css";

const Portfolio = (props) => {
  const { s_id } = props;
  console.log("s_id:", s_id);

  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useState([]);
  const [hover, sethover] = useState(false);
  console.log("portfolio:", portfolio);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // let found = serviceDataList?.find(
    //   (element) => element.service_refer === refer
    // );
    const formData = new FormData();
    formData.append("SopnoID", s_id);
    formData.append("action", "fetch");

    fetch(fetchedPortfolioApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setPortfolio(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [s_id]);
  //   let rightImages;
  //   if (portfolio) {
  //     rightImages = portfolio.slice(2, 6);
  //   }
  return (
    <Box>
      {portfolio.map((element) => {
        let rightPicsList = element.pic_object.slice(1, 5);
        return (
          <Grid
            container
            spacing={1}
            sx={{ height: "auto" }}
            rowSpacing={{ xs: 1, md: "auto" }}
          >
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              {console.log(element.pic_object[0]?.file_url)}
              <Box
                sx={{ position: "relative" }}
                classname="pCon"
                onMouseOver={() => {
                  sethover(element.pic_object[0]?.port_refer);
                }}
                onMouseLeave={() => {
                  sethover(" ");
                }}
                onClick={handleOpen}
              >
                <Box
                  component="img"
                  sx={{
                    //   padding: "0 12px",
                    // zIndex: 1,
                    width: "100%",
                    height: "50vh",
                    cursor: "pointer",
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={element.pic_object[0].file_url}
                  onClick={() => {
                    navigate("/");
                  }}
                />
                {hover == element.pic_object[0]?.port_refer && (
                  <Box
                    className="blackShadow"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "50vh",
                      top: "0%",
                      backgroundImage:
                        "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7))",
                      //   backgroundColor: "rgba(0,0,0,.5)",
                      color: "black",
                      zIndex: 10,
                    }}
                  ></Box>
                )}
                {hover == element.pic_object[0]?.port_refer && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "88%",
                      left: "5%",
                      color: "white",
                      zIndex: 10,
                      // display: "none",
                      // opacity: 0,
                    }}
                    className="title"
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "12pt",
                        fontWeight: 600,
                      }}
                    >
                      {element.pic_object[0].title}
                      {console.log("element:", element.pic_object[0].title)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              {/* <Box sx={{ height: "100%" }}> */}
              <Grid container spacing={1} sx={{ height: "51vh" }}>
                {rightPicsList?.map((element) => (
                  // let picList =  element.list.slice(1,4)
                  <Grid item xs={6} sx={{ padding: 0 }}>
                    <Box
                      sx={{ position: "relative" }}
                      classname="pCon"
                      onMouseOver={() => {
                        sethover(element?.port_refer);
                      }}
                      onMouseLeave={() => {
                        sethover(" ");
                      }}
                      onClick={handleOpen}
                    >
                      <Box
                        component="img"
                        sx={{
                          //   padding: "0 12px",
                          //   width: 60,
                          width: "100%",
                          height: "23.8vh",
                          objectFit: "cover",
                          cursor: "pointer",

                          // display: { xs: "none", sm: "none", md: "block" },
                        }}
                        alt="The house from the offer."
                        src={element.file_url}
                        onClick={() => {
                          navigate("/");
                        }}
                      />
                      {hover == element?.port_refer && (
                        <Box
                          className="blackShadow"
                          sx={{
                            position: "absolute",
                            width: "100%",
                            height: "23.8vh",
                            top: "0%",
                            backgroundImage:
                              "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7))",
                            //   backgroundColor: "rgba(0,0,0,.5)",
                            color: "black",
                            zIndex: 10,
                          }}
                        ></Box>
                      )}
                      {hover == element?.port_refer && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "80%",
                            left: "5%",
                            color: "white",
                            zIndex: 10,
                            // display: "none",
                            // opacity: 0,
                          }}
                          className="title"
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                              fontWeight: 600,
                            }}
                          >
                            {element.title}
                            {console.log("element:", element.title)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {/* </Box> */}
            </Grid>
          </Grid>
        );
      })}
      <PortfolioModal style={style} open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Portfolio;
const style = {
  padding: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
