import {
  ArrowDownward,
  Circle,
  Facebook,
  Language,
  LinkedIn,
  Reply,
  Share,
  ThumbUpOffAlt,
  YouTube,
} from "@mui/icons-material";
import { Button, IconButton, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ShareModal from "./ShareModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const FloatingInfo = (props) => {
  const { profileDetails, handleGeneratePdf } = props;
  const { cinfo } = profileDetails;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Paper sx={{ padding: "35px", marginTop: "5px", position: "relative" }}>
      <Typography
        variant="p"
        sx={{
          fontSize: { xs: "10pt", md: "12pt" },
          fontWeight: "500",
          // lineHeight: "6px",
        }}
      >
        {cinfo}
      </Typography>
      <Stack
        spacing={1}
        direction={"row"}
        justifyContent="left"
        alignItems={"center"}
        sx={{ marginTop: "10px" }}
      >
        <Button
          startIcon={<ThumbUpOffAlt />}
          size="small"
          sx={{
            paddingX: "10px",
            color: "black",
            bgcolor: "#e3dfdf",
            fontSize: "9pt",
            textTransform: "capitalize",
            borderRadius: "30px",
          }}
        >
          Follow
        </Button>
        <Button
          startIcon={<Reply sx={{ transform: "scaleX(-1)" }} />}
          size="small"
          sx={{
            paddingX: "10px",
            color: "black",
            bgcolor: "#e3dfdf",
            fontSize: "9pt",
            textTransform: "capitalize",
            borderRadius: "30px",
          }}
          onClick={handleOpen}
        >
          Share
        </Button>

        <Button
          startIcon={<ArrowDownward />}
          size="small"
          // onClick={toPdf}
          sx={{
            paddingX: "10px",
            color: "black",
            bgcolor: "#e3dfdf",
            fontSize: "9pt",
            textTransform: "capitalize",
            borderRadius: "30px",
          }}
          onClick={handleGeneratePdf}
        >
          Download
        </Button>
      </Stack>
      <ShareModal
        handleClose={handleClose}
        open={open}
        profileDetails={profileDetails}
        style={style}
      />
    </Paper>
  );
};

export default FloatingInfo;
