import { Clear, Share } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { PortfolioDetailsApi } from "../../../API/Api";
import "./PortfolioModal.css";

const PortfolioModal = (props) => {
  const { handleClose, open, style } = props;
  const [portfolioDetails, setPortfolioDetails] = useState({});
  console.log("portfolioDetails:", portfolioDetails);
  useEffect(() => {
    // setReferId(refer);
    fetch(PortfolioDetailsApi)
      .then((res) => res.json())
      .then((result) => {
        setPortfolioDetails(result[0]);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        spacing={3}
        sx={style}
      >
        <Stack
          sx={{ width: "40%" }}
          justifyContent="left"
          alignItems={"flex-start"}
        >
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
            Title :{" "}
            <span style={{ fontSize: "11pt", fontWeight: 500 }}>
              {portfolioDetails?.title}
            </span>
          </Typography>
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
            Category :{" "}
            <span style={{ fontSize: "11pt", fontWeight: 500 }}>
              {portfolioDetails?.category}
            </span>
          </Typography>
          <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 600 }}>
            Sub Category :{" "}
            <span style={{ fontSize: "11pt", fontWeight: 500 }}>
              {portfolioDetails?.sub_category}
            </span>
          </Typography>
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
            Details :{" "}
            <span style={{ fontSize: "11pt", fontWeight: 500 }}>
              {portfolioDetails?.details}
            </span>
          </Typography>
          <IconButton size="small">
            <Share sx={{ color: "#23a2cb" }} onClick={() => {}} />
          </IconButton>
        </Stack>
        <Stack spacing={2} sx={{ width: "60%" }} className="slickS">
          <Slider {...settings}>
            {portfolioDetails?.file_info?.map((element) => (
              <Box>
                <Stack direction={"row"} justifyContent="center">
                  <Box
                    component="img"
                    sx={{
                      //   padding: "0 12px",
                      //   width: 60,
                      width: "auto",
                      height: "400px",
                      objectFit: "cover",
                      cursor: "pointer",

                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt="The house from the offer."
                    src={element.file_url}
                    //   onClick={() => {
                    //     navigate("/");
                    //   }}
                  />
                </Stack>
              </Box>
            ))}
          </Slider>
        </Stack>
        <Clear
          sx={{ position: "absolute", top: 5, right: 5, cursor: " pointer" }}
          onClick={handleClose}
        />
      </Stack>
    </Modal>
  );
};

export default PortfolioModal;
