import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { catBannerApi, catSublistApi } from "../Component/API/Api";
import ExpertStories from "../Component/Explore Components/ExpertStories";
import FreelancerComment from "../Component/Explore Components/FreelancerComment";
import SubList from "../Component/Explore Components/SubList";
import GetStarted from "../Component/Get Started/GetStarted";
import BrowseSkill from "../Component/Homepage Components/BrowseSkills/BrowseSkill";
import "./css/Explore.css";

const Explore = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [catBannerData, setCatBannerData] = useState([]);
  const [catSublistData, setCatSublistData] = useState([]);

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_webland/cat_banner.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setCatBannerData(result);
      })
      .catch((error) => console.log("bannerData Error => ", error));
  }, [id]);
  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_webland/cat_sublist.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setCatSublistData(result);
      })
      .catch((error) => console.log("bannerData Error => ", error));
  }, [id]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // draggable: false,
    // arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Box className="categoryBanner">
      <Slider {...settings}>
        {catBannerData.map((element) => (
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
              }}
              alt="The house from the offer."
              src={element?.banner_pic}
            />
          </Box>
        ))}
      </Slider>

      <Box sx={{ bgcolor: "#d9e7f95c" }}>
        <Container sx={{ mt: "-7px" }}>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            pt={5}
          >
            <Typography
              variant="p"
              sx={{
                fontWeight: 600,
                fontSize: "29pt",
                color: "black",
              }}
            >
              Trusted services and professionals
            </Typography>
          </Stack>

          <Grid container spacing={2} mt={4} mb={4} pb={7}>
            {catSublistData?.map((element) => (
              <Grid item xs={6} sm={4} md={3} key={element.service_refer}>
                <SubList data={element} key={element.refer} />
              </Grid>
            ))}
          </Grid>
          <ExpertStories id={id} />
          <FreelancerComment id={id} />
          <BrowseSkill id={id} />
          <GetStarted id={id} />
        </Container>
      </Box>
    </Box>
  );
};

export default Explore;
