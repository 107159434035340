import { Grid, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import CategoryItem from "./CategoryItem";
import { categoryApi } from "../../API/Api";
import { useState } from "react";
import { useEffect } from "react";

const Categories = () => {
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    fetch(categoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryData(result);
      })
      .catch((error) => console.log("categoryData Error => ", error));
  }, []);
  return (
    <Container sx={{ mt: { xs: "150px", sm: "0px" } }}>
      <Stack justifyContent="center" alignItems="center">
        <Typography
          variant="p"
          mt={3}
          sx={{ fontWeight: 700, fontSize: "25pt", marginTop: "-105px" }}
        >
          Trending Top Categories
        </Typography>
        <Typography
          variant="p"
          mb={3}
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          Largest globally spread network of talented people
        </Typography>
      </Stack>
      <Grid container spacing={2} mt={1} sx={{ marginTop: "-25px" }}>
        {categoryData.map((element) => (
          <Grid item xs={12} sm={4} md={4} lg={4} key={element.refer}>
            <CategoryItem data={element} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Categories;
