import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { categoryApi, serviceCategoryApi } from "../API/Api";
import { useRef } from "react";
import { Box, Stack } from "@mui/material";
import { KeyboardArrowDown, StarBorder } from "@mui/icons-material";
import "./BlogTitleList.css";
import { useNavigate } from "react-router-dom";

// const decodeLocalData = (name) => {
//   let result = atob(window.localStorage.getItem(name));
//   return JSON.parse(result);
// };
const BlogCategoryList = ({
  setServiceListData,
  page,
  setFirstLoadIndegator,
}) => {
  // let localData = decodeLocalData("gh_sl@g");

  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [subCatToggle, setSubCatToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  console.log("categoryHeight:", categoryHeight);
  const [subCategoryHeight, setSubCategoryHeight] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  console.log("categoryListData:", categoryListData);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [source, setSource] = useState("category");
  const [sourceValueId, setSourceValueId] = useState("");
  console.log("subCategoryListData:", subCategoryListData);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  console.log("opesasasn:", open);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  useEffect(() => {
    fetch(serviceCategoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  // useEffect(
  //   () => {
  //     handleCategoryHeight(focus.current.offsetHeight);
  //   },
  //   [subCategoryListData?.[subCategoryListData?.length - 1]?.subcategory_id],
  //   categoryListData?.[categoryListData?.length - 1]?.category_id,
  //   sourceValueId
  // );

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("pref", sourceValueId);
  //   formData.append("page", page);

  //   if (source === "subCategory") {
  //     fetch("https://ghorami.com/profile/login/api_service/j_service_sub.php", {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setServiceListData(result);
  //       })
  //       .catch((error) => console.log("ServiceApiError=> ", error));
  //   } else {
  //     fetch("https://ghorami.com/profile/login/api_service/j_service_cat.php", {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setServiceListData(result);
  //       })
  //       .catch((error) => console.log("ServiceApiError=> ", error));
  //   }
  // }, [page]);

  // const handleSubCategoryData = (cat_id) => {
  //   const formData = new FormData();
  //   formData.append("pref", cat_id);

  //   fetch("https://ghorami.com/profile/login/api_service/j_service_cat.php", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setServiceListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // };

  // const handleSubCategory = (cat_id) => {
  //   setFirstLoadIndegator(false);
  //   if (open === cat_id) {
  //     setOpen(false);
  //   } else {
  //     const formData = new FormData();
  //     formData.append("category_id", cat_id);

  //     fetch(
  //       "https://ghorami.com/profile/login/api_service/all_service_subcategory.php",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setSubCategoryListData(result);
  //         if (result[0]?.subcategory_id) {
  //           setOpen(cat_id);
  //         }
  //       })
  //       .catch((error) => console.log("ServiceApiError=> ", error));
  //   }
  //   setSource("category");
  //   setSourceValueId(cat_id);
  // };

  // const handleSubCatItems = (sub_cat) => {
  //   setFirstLoadIndegator(false);

  //   setSource("subCategory");
  //   setSourceValueId(sub_cat);

  //   const formData = new FormData();
  //   formData.append("pref", sub_cat);

  //   fetch("https://ghorami.com/profile/login/api_service/j_service_sub.php", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setServiceListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // };

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="p"
          sx={{ fontSize: "13pt", marginTop: "10px", fontWeight: 600 }}
        >
          Popular Category
        </Typography>
        {/* <IconButton
          size="medium"
          onClick={() => {
            handleToggle();
          }}
        >
          <KeyboardArrowDown
            fontSize="inherit"
            color="black"
            sx={{
              transform: toggle === false ? "rotate(-180deg)" : "",
              transition: "transform 1s",
            }}
          />
        </IconButton> */}
      </Stack>
      <Box
        // className={toggle === false ? "lowCategoryHeight" : ""}
        sx={{
          height: categoryHeight ? `${categoryHeight}px !important` : "0px",
          // overflow: "hidden",
          transition: "height 1s",
          padding: "5px",
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          ref={focus}
        >
          {categoryListData.map((value) => (
            <>
              <ListItem
                key={value.category_name}
                disableGutters
                sx={{ padding: 0, cursor: "pointer" }}
                secondaryAction={
                  <IconButton
                    aria-label="comment"
                    size="small"
                    sx={{}}
                    variant="outlined"

                    // onClick={() => {
                    //   handleSubCategory(value?.category_id);
                    // }}
                  ></IconButton>
                }
              >
                <ListItemText
                  primary={`${value.category_name}`}
                  sx={{}}
                  onClick={() => {
                    navigate("/bloglist/" + value.category_id);
                  }}
                  // onClick={() => {
                  //   handleSubCategoryData(value?.category_id);
                  // }}
                />
              </ListItem>
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default BlogCategoryList;
