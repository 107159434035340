import { Box } from "@mui/system";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NagadAccountDetails from "../Component/Booknow Component/NagadAccountDetails";
import Booknow from "./Booknow";
import Category from "./Category";
import ExpertList from "./ExpertList";
import Explore from "./Explore";
import Home from "./Home";
import ServiceList from "./ServiceList";
import UserType from "./UserType";
import ServiceDetails from "../Component/ServiceList Components/ServiceDetails/ServiceDetails";
import BlogList from "./BlogList";
import Blogdetails from "../Component/BlogList Components/Blogdetails";
import ProfileDetails from "../Component/Profile Components/ProfileDetails";
import JobDetails from "../Component/JobList Components/JobDetails";
import Footer from "../Component/Homepage Components/Footer/Footer";
import FooterBottom from "../Component/Homepage Components/Footer/FooterBottom";
import BookPage from "../Component/BookItem/BookPage";
import Quotation from "../Component/BookItem/Quotation/Quotation";
import SearchPage from "../Component/Search/SearchPage";
import PrivacyPolicy from "../Component/Homepage Components/Footer/PrivacyPolicy";
import Contact from "../Component/Homepage Components/Footer/Contact";
import Terms from "../Component/Homepage Components/Footer/Terms";
import About from "../Component/Homepage Components/Footer/About";
import ProfileAg from "../Component/Profile Components/ProfileAg";
import ServiceListExplore from "./ServiceListExplore";
import ExpertListExplore from "./ExpertListExplore";
import JobList from "./JobList";
import AgencyList from "./AgencyList";

// import PropertyDetails from "../Component/ServiceList Components/ServiceDetails/PropertyDetails";

const Pages = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Category />} />
        <Route path="/usertype" element={<UserType />} />
        <Route path="/explore/:id" element={<Explore />} />
        <Route path="/servicelist" element={<ServiceList />} />
        <Route path="/servicelist/:refer" element={<ServiceListExplore />} />
        <Route path="/servicedetails/:refer" element={<ServiceDetails />} />
        <Route
          path="/jobDetails/:jobId/:jobRefer/:poid"
          element={<JobDetails />}
        />
        <Route path="/book/:refer" element={<BookPage />} />
        <Route path="/search/:type/:text" element={<SearchPage />} />
        <Route path="/quotation/:refer" element={<Quotation />} />
        <Route path="/joblist" element={<JobList />} />
        <Route path="/joblist" element={<JobList />} />
        <Route path="/joblist/:refer" element={<JobList />} />
        <Route path="/expertlist" element={<ExpertList />} />

        <Route path="/expertlist/:refer" element={<ExpertListExplore />} />

        <Route path="/agentlist" element={<AgencyList />} />
        <Route path="/agentlist/:refer" element={<AgencyList />} />
        <Route path="/bloglist" element={<BlogList />} />
        <Route path="/bloglist/:caten" element={<BlogList />} />
        <Route path="/blogdetails/:refer" element={<Blogdetails />} />
        <Route path="/profiledetails/:sopnoId" element={<ProfileDetails />} />
        <Route path="/profile/:sopnoId" element={<ProfileAg />} />
        <Route path="/booknow/:refer" element={<Booknow />} />
        {/* <Route path="/nagad" element={<NagadAccountDetails />} /> */}

        <Route path="/contact" element={<Contact />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Box sx={{ marginTop: "40px" }}>
        <Footer />
        <FooterBottom />
      </Box>
    </Box>
  );
};

export default Pages;
