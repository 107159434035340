import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BooknowTopSection from "./BooknowTopSection";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { fetchedServiceDetailsApi } from "../API/Api";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// const decodeLocalData = (name) => {
//   let result = atob(window.localStorage.getItem(name));
//   return JSON.parse(result);
// };
const BookPage = () => {
  // let localData = decodeLocalData("gh_sl@g");
  const navigate = useNavigate();
  const { refer, price, currency } = useParams();
  const [next, setNext] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [inputField, setInputField] = useState({});
  const [bookInfo, setBookInfo] = useState({ sopnoId: "" });
  const [serviceDetails, setServiceDetails] = useState([]);
  const [requestData, setRequestData] = useState("");
  const [fetchedPhone, setFetchedPhone] = useState("");
  const [fetchedEmail, setFetchedEmail] = useState("");
  const [fetchedName, setFetchedName] = useState("");
  const [fetchedCountry, setFetchedCountry] = useState("");
  const [fetchedAddress, setFetchedAddress] = useState("");
  const [fetchedSopnoId, setFetchedSopnoId] = useState("");
  const [countryList, setCountryList] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("pref", refer);
    formData.append("action", "fetch");

    fetch(fetchedServiceDetailsApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setServiceDetails(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [refer]);

  useEffect(() => {
    fetch("https://ghorami.com/profile/login/api_booking/country_list.php")
      .then((res) => res.json())
      .then((result) => {
        setCountryList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch("https://ghorami.com/profile/login/api_booking/fetch_user_info.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resassult:", result);
        // setFetchUser(result);
        if (result?.p_phone) {
          setFetchedPhone(result?.p_phone);
          let newUser = bookInfo;
          newUser.phone = result?.p_phone;
          setBookInfo(newUser);
        }
        if (result?.p_email) {
          setFetchedEmail(result?.p_email);
          let newUser = bookInfo;
          newUser.email = result?.p_email;
          setBookInfo(newUser);
        }
        if (result?.p_nameS) {
          setFetchedName(result?.p_nameS);
          let newUser = bookInfo;
          newUser.name = result?.p_nameS;
          setBookInfo(newUser);
        }

        if (result?.posterCountry) {
          setFetchedCountry(result?.posterCountry);
          let newUser = bookInfo;
          newUser.country = result?.posterCountry;
          setBookInfo(newUser);
        }
        if (result?.p_address) {
          setFetchedAddress(result?.p_address);
          let newUser = bookInfo;
          newUser.address = result?.p_address;
          setBookInfo(newUser);
        }
        if (result?.posterSID) {
          setFetchedSopnoId(result?.posterSID);
          let newUser = bookInfo;
          newUser.sopnoId = result?.posterSID;
          setBookInfo(newUser);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [requestData]);

  const handleSend = () => {
    const formData = new FormData();
    bookInfo.totalAmount = Math.round(
      quantity * serviceDetails?.price * 0.018 +
        quantity * serviceDetails?.price
    );
    bookInfo.gatewayCharge = Math.round(
      quantity * serviceDetails?.price * 0.018
    );
    bookInfo.subtotal = Math.round(quantity * serviceDetails?.price);
    bookInfo.quantity = quantity;
    bookInfo.currency = serviceDetails.currency;

    formData.append("bookInfo", JSON.stringify(bookInfo));

    fetch(
      "https://ghorami.com/profile/login/api_booking/gh_user_serviceBook.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        result[0]?.stats === "success" && handleClick();
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "delivery_date") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (event.target.name === "start_date") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "message") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newbookInfo = bookInfo;
      newbookInfo[event.target.name] = event.target.value;
      setBookInfo({ ...newbookInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setBookInfo(newField);
    }
  };
  const handlePhoneChange = (event) => {
    setFetchedPhone(event.target.value);
    setRequestData(event.target.value);
    let newUser = bookInfo;
    newUser[event.target.name] = event.target.value;
    setBookInfo(newUser);
  };
  const handleEmailChange = (event) => {
    setFetchedEmail(event.target.value);
    setRequestData(event.target.value);
    let newUser = bookInfo;
    newUser[event.target.name] = event.target.value;
    setBookInfo(newUser);
  };
  const handleNameChange = (event) => {
    setFetchedName(event.target.value);
    let newUser = bookInfo;
    newUser[event.target.name] = event.target.value;
    setBookInfo(newUser);
  };
  const handleCountryChange = (event) => {
    setFetchedCountry(event.target.value);
    let newUser = bookInfo;
    newUser[event.target.name] = event.target.value;
    setBookInfo(newUser);
  };
  const handleAddressChange = (event) => {
    setFetchedAddress(event.target.value);
    let newUser = bookInfo;
    newUser[event.target.name] = event.target.value;
    setBookInfo(newUser);
  };
  const addQuantity = () => {
    let newQ = quantity;
    newQ = newQ + 1;
    setQuantity(newQ);
  };
  const reduceQuantity = () => {
    if (quantity < 2) {
      setQuantity(1);
    } else {
      let newQ = quantity;
      newQ = newQ - 1;
      setQuantity(newQ);
    }
  };
  return (
    <Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
      <Container>
        <Grid container spacing={0} mt={5}>
          <Grid item xs={6} sx={{ borderRight: "1px solid gray" }}>
            <Box sx={{ padding: "20px" }}>
              <Box>
                <BooknowTopSection serviceDetails={serviceDetails} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={0} sx={{ padding: "20px" }}>
              {!next && (
                <Paper sx={{ padding: "20px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Phone
                        </Typography>

                        <OutlinedInput
                          value={fetchedPhone}
                          name="phone"
                          placeholder="Please enter text"
                          type="number"
                          size="small"
                          sx={{ borderRadius: "4px" }}
                          onChange={handlePhoneChange}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Email
                        </Typography>
                        <OutlinedInput
                          name="email"
                          value={fetchedEmail}
                          placeholder="Please enter text"
                          type="email"
                          size="small"
                          sx={{ borderRadius: "4px" }}
                          onChange={handleEmailChange}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Name
                        </Typography>
                        <OutlinedInput
                          value={fetchedName}
                          name="name"
                          placeholder="Please enter text"
                          type="text"
                          size="small"
                          sx={{ borderRadius: "4px" }}
                          onChange={handleNameChange}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Country
                        </Typography>
                        <Select
                          size="small"
                          value={fetchedCountry}
                          name="country"
                          sx={{ borderRadius: "4px", width: "100%" }}
                          onChange={handleCountryChange}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {countryList?.map((element) => (
                            <MenuItem value={element?.country_name}>
                              {element?.country_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>{" "}
                    <Grid item xs={12}>
                      <Stack>
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Address
                        </Typography>
                        {/* <OutlinedInput
                        //   defaultValue="react-bootstrap"
                        name="address"
                        value={fetchedAddress}
                        placeholder="Please enter text"
                        type="text"
                        size="small"
                        sx={{ borderRadius: "4px" }}
                        onChange={handleAddressChange}
                      />{" "} */}
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={2}
                          placeholder="Enter Details"
                          name="address"
                          type="text"
                          size="small"
                          value={fetchedAddress}
                          className="customTextField"
                          style={{
                            fontSize: "11pt",
                            fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                      sans-serif`,
                            width: "100%",
                            maxWidth: "100%",
                            boxSizing: "border-box",
                            border: "1px solid #c8c9cb",
                            borderRadius: "4px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "10px",
                          }}
                          onChange={handleAddressChange}
                        />
                      </Stack>
                    </Grid>
                  </Grid>{" "}
                </Paper>
              )}
              {next && (
                <>
                  <Paper sx={{ padding: "20px" }}>
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{ width: "100%" }}
                    >
                      <Stack sx={{ width: "50%" }}>
                        <Typography variant="p" sx={{ fontSize: "11pt" }}>
                          <strong>Name</strong> : {fetchedName}
                        </Typography>
                        <Typography variant="p" sx={{ fontSize: "11pt" }}>
                          <strong>Email</strong> : {fetchedEmail}
                        </Typography>
                      </Stack>
                      <Stack sx={{ width: "50%" }}>
                        {" "}
                        <Typography variant="p" sx={{ fontSize: "11pt" }}>
                          <strong>Phone</strong> : {fetchedPhone}
                        </Typography>
                        <Typography variant="p" sx={{ fontSize: "11pt" }}>
                          <strong>Country</strong> : {fetchedCountry}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="p" sx={{ fontSize: "11pt" }}>
                      <strong>Address</strong> : {fetchedAddress}
                    </Typography>{" "}
                  </Paper>
                  <Paper sx={{ padding: "20px", marginTop: "20px" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Stack spacing={0}>
                          <Typography variant="p" sx={{ fontSize: "12pt" }}>
                            Service Start Date
                          </Typography>
                          <OutlinedInput
                            size="small"
                            type="date"
                            name="start_date"
                            sx={{ width: "100%" }}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={0}>
                          <Typography variant="p" sx={{ fontSize: "12pt" }}>
                            Expect Delivery Date
                          </Typography>
                          <OutlinedInput
                            size="small"
                            type="date"
                            name="delivery_date"
                            sx={{ width: "100%" }}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={0.5}>
                          <Typography variant="p" sx={{ fontSize: "12pt" }}>
                            Message
                          </Typography>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Enter Details"
                            name="message"
                            className="customTextField"
                            style={{
                              fontSize: "11pt",
                              fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                              width: "100%",
                              maxWidth: "100%",
                              boxSizing: "border-box",
                              border: "1px solid #c8c9cb",
                              borderRadius: "4px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              paddingTop: "10px",
                            }}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack spacing={1} mt={3}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{}}
                      >
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Service Quantity
                        </Typography>
                        <Stack
                          spacing={0.5}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <IconButton
                            size="small"
                            onClick={reduceQuantity}
                            color="error"
                          >
                            <RemoveCircleOutline style={{ fontSize: "18pt" }} />
                          </IconButton>
                          <Stack
                            spacing={3}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width: "40px",
                              height: "40px",
                              border: "2px solid gray",
                              borderRadius: "12px",
                            }}
                          >
                            {quantity}
                          </Stack>
                          <IconButton
                            size="small"
                            onClick={addQuantity}
                            color="success"
                          >
                            <AddCircleOutline style={{ fontSize: "18pt" }} />
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{}}
                      >
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Net price
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 600,
                            paddingRight: "40px",
                          }}
                        >
                          {serviceDetails?.currency} {serviceDetails?.price}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{}}
                      >
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Subtotal
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 600,
                            paddingRight: "40px",
                          }}
                        >
                          {serviceDetails?.currency}{" "}
                          {Math.round(quantity * serviceDetails?.price)}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{}}
                      >
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Gateway Charge
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 600,
                            paddingRight: "40px",
                          }}
                        >
                          {serviceDetails?.currency}{" "}
                          {Math.round(quantity * serviceDetails?.price * 0.018)}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{}}
                      >
                        <Typography variant="p" sx={{ fontSize: "12pt" }}>
                          Total Amount
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 600,
                            paddingRight: "40px",
                          }}
                        >
                          {serviceDetails?.currency}{" "}
                          {Math.round(
                            quantity * serviceDetails?.price * 0.018 +
                              quantity * serviceDetails?.price
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </>
              )}
              <Stack
                direction="row"
                justifyContent="right"
                alignItems="center"
                spacing={2}
                mt={2}
              >
                {next ? (
                  <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "11pt",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      setNext(false);
                    }}
                  >
                    Back
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "11pt",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </Button>
                )}
                {!next ? (
                  fetchedPhone &&
                  fetchedEmail &&
                  fetchedName &&
                  fetchedAddress &&
                  fetchedCountry ? (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "11pt",
                        borderRadius: "30px",
                      }}
                      onClick={() => {
                        setNext(true);
                      }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "11pt",
                        borderRadius: "30px",
                      }}
                      disabled
                    >
                      Next
                    </Button>
                  )
                ) : inputField?.message &&
                  inputField?.start_date &&
                  fetchedPhone &&
                  fetchedEmail &&
                  fetchedName &&
                  fetchedAddress &&
                  fetchedCountry &&
                  inputField?.delivery_date ? (
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "11pt",
                      borderRadius: "30px",
                    }}
                    onClick={handleSend}
                  >
                    Send request
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    disabled
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "11pt",
                      borderRadius: "30px",
                    }}
                  >
                    Send request
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BookPage;
