import {
  Badge,
  CheckCircleRounded,
  LocalAtm,
  LocationOn,
  Star,
} from "@mui/icons-material";
import {
  Card,
  CardMedia,
  Divider,
  IconButton,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect } from "react";
import "./PromotedFreelancerItem.css";
const PromotedFreelancerItem = (props) => {
  const { data } = props;
  const {
    category,
    active_state,
    email,
    location,
    name,
    pic,
    refer_id,
    sopnoId,
    sub_category,
    user_rate,
    user_state,
    user_skill,
  } = data;
  const statusRef = React.useRef();
  var color = ["green", "gray"];

  useEffect(() => {
    let i = 0;
    setInterval(function () {
      statusRef.current.style.backgroundColor = color[i];
      i = (i + 1) % color.length;
    }, 2000);
  }, []);
  return (
    <Card sx={{ width: "91%", height: "90%", padding: "15px" }}>
      <Stack sx={{}}>
        <Box sx={{ height: "50%", position: "relative" }}>
          <CardMedia
            component="img"
            height="100%"
            image={pic}
            alt="green iguana"
          />
          <Box
            className="box"
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            {active_state === 1 ? (
              <Box
                ref={statusRef}
                sx={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  transition: "backgroundColor 1s",
                  border: "3px solid white",
                  // position: "absolute",
                  cursor: "pointer",
                }}
              ></Box>
            ) : (
              <Box
                ref={statusRef}
                sx={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  border: "3px solid white",
                  cursor: "pointer",
                }}
              ></Box>
            )}
            {active_state === 1 ? (
              <Typography
                className="innerBox"
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "0px",
                  cursor: "pointer",
                  color: "white",
                  opacity: 0,
                }}
              >
                Active
              </Typography>
            ) : (
              <Typography
                className="innerBox"
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "0px",
                  color: "white",
                  cursor: "pointer",
                  opacity: 0,
                }}
              >
                Inactive
              </Typography>
            )}
          </Box>

          {false && (
            <Box
              sx={{
                position: "absolute",
                top: "4%",
                left: "2%",
                padding: "1% 2%",
                bgcolor: "black",
                borderRadius: "5px",
              }}
            >
              <Typography variant="p" sx={{ color: "white", fontWeight: 600 }}>
                Featured
              </Typography>
            </Box>
          )}
        </Box>

        <Stack spacing={0} sx={{ position: "relative" }}>
          <Stack spacing={1} sx={{ padding: "1%" }}>
            <Stack
              spacing={1}
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              {user_state === 1 ? (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                >
                  <CheckCircleRounded color="green" sx={{ fontSize: "12pt" }} />
                  <Typography>Verified</Typography>
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                >
                  <CheckCircleRounded
                    sx={{ fontSize: "12pt", color: "gray" }}
                  />
                  <Typography>Unverified</Typography>
                </Stack>
              )}
            </Stack>

            <Typography
              gutterBottom
              variant="p"
              sx={{
                fontSize: { xs: "10pt", md: "15pt" },
                fontWeight: 600,
              }}
              component="div"
            >
              {name}
            </Typography>

            {/* <Stack
              spacing={1}
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              <LocalAtm sx={{ fontSize: "18pt", color: "gray" }} /> */}
            <Typography> {user_skill}</Typography>
            {/* </Stack> */}

            <Divider />
            {user_rate === 0 ? (
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <IconButton aria-label="delete">
                  <Star sx={{ color: "orange" }} />
                </IconButton>

                <Typography
                  variant="p"
                  color="text.secondary"
                  sx={{
                    fontSize: { xs: "7pt", md: "13pt" },
                    fontWeight: 600,
                  }}
                >
                  No Reviews
                </Typography>
              </Stack>
            ) : (
              <Rating name="read-only" value={user_rate} readOnly />
            )}

            <Stack
              spacing={1}
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              <IconButton aria-label="delete">
                <LocationOn sx={{ color: "orange" }} />
              </IconButton>

              <Typography
                variant="p"
                color="text.secondary"
                sx={{
                  fontSize: { xs: "7pt", md: "13pt" },
                  fontWeight: 600,
                }}
              >
                {location}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PromotedFreelancerItem;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
