import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStartedApi } from "../API/Api";
import "./GetStarted.css";

const GetStarted = ({ id }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_webland/bottom_banner.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result[0]);
      })
      .catch((error) => {
        console.log("fetch Failed ", error);
      });
  }, []);
  console.log("get Started data:", data);

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: "20px", bgcolor: "#eff9f9" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, md: 0 }}
        >
          <Stack sx={{ width: { xs: "100%", md: "70%" } }} spacing={1}>
            <Typography variant="p" sx={{ fontSize: "16pt", fontWeight: 600 }}>
              {data.title}
            </Typography>
            <Typography variant="p" sx={{ fontSize: "13pt" }}>
              {data.info}
            </Typography>
            <Stack
              direction={"row"}
              justifyContent="Left"
              alignItems={"center"}
              spacing={1}
            >
              {data.button_1 && (
                <Button
                  variant="contained"
                  href={data.button_1_url}
                  sx={{
                    width: "auto",
                    bgcolor: "black",
                    borderRadius: "20px",
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#22AB7D",
                    },
                  }}
                >
                  {data.button_1}
                </Button>
              )}
              {data.button_2 && (
                <Button
                  variant="contained"
                  href={data.button_2_url}
                  sx={{
                    width: "auto",
                    bgcolor: "#20932e",
                    borderRadius: "20px",
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#22AB7D",
                    },
                  }}
                >
                  {data.button_2}
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack
            sx={{ width: { xs: "100%", md: "30%" } }}
            direction="row"
            justifyContent={{ xs: "center", md: "right" }}
            alignItems="center"
          >
            <Box
              component="img"
              sx={{
                width: "60%",
                cursor: "pointer",
              }}
              alt="Get Start"
              src={data.banner_pic}
            />
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export default GetStarted;
