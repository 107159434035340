import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Mail,
  PhoneIphone,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../../../images/ghorami.ico";

const Footer = () => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  console.log("showMore:", showMore);
  return (
    <Box sx={{ bgcolor: "black", zIndex: 5 }}>
      <Container>
        <Grid
          container
          rowSpacing={4}
          spacing={{ xs: 0, md: 2 }}
          sx={{ width: "100%", paddingY: "20px" }}
        >
          <Grid item xs={6} md={4} sx={{ order: 0 }}>
            <Stack spacing={2} sx={{ width: "80%" }}>
              <Box
                component="img"
                sx={{
                  //   objectFit: "cover",

                  width: "80px",
                  height: "auto",
                }}
                alt="ghorami Logo"
                src={logo}
              />
              <Typography
                variant="p"
                sx={{ color: "#b7b7b7", fontSize: "10pt" }}
              >
                ghorami helps you discover a service where you'll love to work
                with. ghorami is an all-in-one service provider site that gives
                you the local scoop about graphics, printing, app creation, web
                making,{" "}
                {!showMore && (
                  <span
                    style={{
                      color: "#5c5ce7",
                      cursor: "pointer",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      setShowMore(true);
                    }}
                  >
                    see more...
                  </span>
                )}
                {showMore && (
                  <span>
                    admin work, photography, event making, lawyer finding etc.
                    You can also find a real estate agent, view prices of
                    recently sold homes, and see home values in your community.
                    Get advice and opinions from designer, developer, lawyer,
                    photoghrapher & other local experts on ghorami Voices,
                    ghorami's online service provider community.
                    {showMore && (
                      <span
                        style={{ color: "#5c5ce7", cursor: "pointer" }}
                        onClick={() => {
                          setShowMore(false);
                        }}
                      >
                        {" "}
                        see less...
                      </span>
                    )}
                  </span>
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
            <Grid container spacing={{ xs: 2, md: 6 }}>
              <Grid item xs={6}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "#22AB7D", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Important Links
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/about");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    About ghorami
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/contact");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Contact
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      // navigate("/ghoramidesk");
                      window.open("https://app.ghorami.com/");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Ghorami Desk
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      window.open("https://community.ghorami.com/allFaq");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    FAQ
                  </Typography>
                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/solutions");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Solutions
                  </Typography>
                  {/* <Typography
                    variant="p"
                    onClick={() => {
                      window.open("https://community.ghorami.com/blog");
                    }}
                    
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Blog
                  </Typography> */}
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Privacy
                  </Typography>

                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/terms");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Terms of uses
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "#22AB7D", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Popular
                  </Typography>
                  {/* <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/wishlist");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    আপনার পছন্দের তালিকা
                  </Typography> */}

                  <Typography
                    onClick={() => {
                      window.open("https://community.ghorami.com/allCourse");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Tutorial
                  </Typography>

                  <Typography
                    onClick={() => {
                      window.open("https://community.ghorami.com/forum/");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Forum
                  </Typography>
                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/blog");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Blog
                  </Typography>
                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/support");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Support
                  </Typography>
                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/videos");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Vlog
                  </Typography>

                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/community");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Community
                  </Typography>

                  <Typography
                    // /https://community.ghorami.com/forum
                    onClick={() => {
                      window.open("https://community.ghorami.com/campaign");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Campaign
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4} sx={{ order: { xs: 1, md: 2 } }}>
            <Grid container spacing={1}>
              <Grid item md={3} xs={0}></Grid>
              <Grid item xs={12} md={9}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "#22AB7D", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Contacts
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <LocationOn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "10pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Head Office:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Navana Mokhles Monarch, room no- 60 (3/1) 2nd floor, 3-b,
                    purana paltan, Dhaka-1000
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <PhoneIphone
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "10pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      01849-225187
                    </Typography>
                  </Stack>

                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Mail
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "10pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      info@ghorami.com
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Facebook
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <YouTube
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Instagram
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Twitter
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <LinkedIn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
