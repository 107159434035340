import { Star } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Rating,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";

const Review = (props) => {
  const { fetchedReview } = props;
  const {
    total_review,
    three_star_review,
    one_star_review,
    four_star_review,
    five_star_review,
    two_star_review,
    average_rating,
    review_data,
  } = fetchedReview;

  const [openReview, setOpenReview] = useState(false);
  const [LoggedIn, setLoggedIn] = useState(false);
  const [reviewRating, setReviewRating] = useState(0);
  console.log("reviewRating:", reviewRating);

  return (
    <Card sx={{ padding: "20px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, md: 0 } }}>
          <Stack
            spacing={0.5}
            justifyContent="space-between"
            alignItems={"center"}
            // sx={{ width: "100%", padding: "0px" }}
          >
            <Stack
              spacing={1}
              justifyContent="space-between"
              alignItems={"center"}
              //   sx={{ height: "60%" }}
            >
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "20pt", fontWeight: 600, color: "#FAAF00" }}
                >
                  {" "}
                  {average_rating} / 5
                </Typography>
                <Rating
                  name="read-only"
                  sx={{ fontSize: "20pt" }}
                  value={parseFloat(average_rating)}
                  readOnly
                />
              </Stack>
              <Typography
                variant="p"
                sx={{ fontSize: "20pt", fontWeight: 600, color: "black" }}
              >
                {" "}
                {total_review} Reviews
              </Typography>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setOpenReview(!openReview);
                }}
                sx={{
                  bgcolor: "#22AB7D",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: "#14506A",
                  },
                }}
              >
                Write a Review
              </Button>
              {openReview && LoggedIn && (
                <Card sx={{ width: "100%", padding: "15px" }}>
                  <Stack spacing={1}>
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <Rating
                        name="simple-controlled"
                        value={reviewRating}
                        sx={{ fontSize: "22pt", fontWeight: 600 }}
                        onChange={(event, newValue) => {
                          setReviewRating(newValue);
                        }}
                      />
                    </Stack>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={2}
                        placeholder="Express Your thoughts"
                        sx={{
                          // width: "100%",
                          border: "2px solid red",
                          fontSize: "12pt",
                        }}
                      />

                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setOpenReview(false);
                        }}
                        sx={{
                          width: "30%",
                          bgcolor: "#14506A",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#22AB7D",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              )}
              {!LoggedIn && openReview && (
                <Typography sx={{ fontSize: "9pt" }}>
                  To write a review,{" "}
                  <Button
                    size="small"
                    sx={{ fontSize: "9pt", textTransform: "capitalize" }}
                    href="http://user.ghorami.com/login"
                    target={"_blank"}
                  >
                    login now
                  </Button>
                  {/* <Stack direction={"row"} alignItems="center">
                    <Button
                      size="small"
                      sx={{ fontSize: "9pt", textTransform: "capitalize" }}
                      onClick={() => {
                        setOpenReview(false);
                      }}
                    >
                      Cancle
                    </Button>
                    <Button
                      size="small"
                      sx={{ fontSize: "9pt", textTransform: "capitalize" }}
                      href="https://ghorami.com/profile/login/login.php"
                      target={"_blank"}
                    >
                      login now
                    </Button>
                  </Stack> */}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { md: 1, xs: 0 } }}>
          <Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  5
                </Typography>
                <Star sx={{ color: "#FAAF00" }} />
              </Stack>

              {five_star_review > 0 ? (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "#FAAF00",
                    borderRadius: "4px",
                  }}
                ></Box>
              ) : (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "gray",
                    borderRadius: "4px",
                  }}
                ></Box>
              )}
              <Box
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  ({five_star_review})
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  4
                </Typography>
                <Star sx={{ color: "#FAAF00" }} />
              </Stack>

              {four_star_review > 0 ? (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "#FAAF00",
                    borderRadius: "4px",
                  }}
                ></Box>
              ) : (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "gray",
                    borderRadius: "4px",
                  }}
                ></Box>
              )}
              <Box
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  ({four_star_review})
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  3
                </Typography>
                <Star sx={{ color: "#FAAF00" }} />
              </Stack>

              {three_star_review > 0 ? (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "#FAAF00",
                    borderRadius: "4px",
                  }}
                ></Box>
              ) : (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "gray",
                    borderRadius: "4px",
                  }}
                ></Box>
              )}
              <Box
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  ({three_star_review})
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  2
                </Typography>
                <Star sx={{ color: "#FAAF00" }} />
              </Stack>

              {two_star_review > 0 ? (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "#FAAF00",
                    borderRadius: "4px",
                  }}
                ></Box>
              ) : (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "gray",
                    borderRadius: "4px",
                  }}
                ></Box>
              )}
              <Box
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  ({two_star_review})
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  1
                </Typography>
                <Star sx={{ color: "#FAAF00" }} />
              </Stack>

              {one_star_review > 0 ? (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "#FAAF00",
                    borderRadius: "4px",
                  }}
                ></Box>
              ) : (
                <Box
                  sx={{
                    width: "75%",
                    height: "12px",
                    bgcolor: "gray",
                    borderRadius: "4px",
                  }}
                ></Box>
              )}
              <Box
                sx={{
                  width: "10%",
                }}
              >
                <Typography sx={{ fontSize: "15pt", fontWeight: 700 }}>
                  ({one_star_review})
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack spacing={3} sx={style}>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography
              id="modal-modal-title"
              variant="p"
              sx={{ fontSize: "15pt", fontWeight: 600 }}
            >
              Submit Review
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Rating
                name="simple-controlled"
                value={reviewRating}
                sx={{ fontSize: "22pt", fontWeight: 600 }}
                onChange={(event, newValue) => {
                  setReviewRating(newValue);
                }}
              />
            </Stack>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Express Your thoughts"
              sx={{ width: "100%", border: "2px solid red", fontSize: "12pt" }}
            />
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleOpen}
                sx={{
                  width: "30%",
                  bgcolor: "#22AB7D",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: "#14506A",
                  },
                }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal> */}
    </Card>
  );
};

export default Review;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
