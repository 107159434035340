import {
  CalendarMonth,
  Folder,
  Home,
  NavigateNext,
  Search,
  Share,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
} from "react-share";
import Slider from "react-slick";
import { blogApi, fetchedBlogDetailsApi } from "../API/Api";
import BlogItem from "../Homepage Components/LatestBlogNews/BlogItem";
import BlogItemShort from "../Homepage Components/LatestBlogNews/BlogItemShort";
import CategoryList from "../ServiceList Components/CategoryList";
import MetaDecorator from "./MetaDecorator";
import BlogTitleList from "./BlogTitleList";
import SocialShare from "../SocialShare/SocialShare";
import DOMPurify from "dompurify";
const Blogdetails = () => {
  const navigate = useNavigate();
  const { refer } = useParams();

  const [blogDataList, setBlogDataList] = useState([]);

  const [blogDetails, setBlogDetails] = useState([]);
  console.log("blogDetails:", blogDetails);

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allBlog`
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogDataList(result);
      })
      .catch((error) => console.log("BlogData Error => ", error));
  }, []);
  useEffect(() => {
    let found = blogDataList?.find((element) => element.pic === refer);
    console.log("fooooppund:", found);
    // const formData = new FormData();
    // formData.append("blog_id", found?.blog_refer);
    // console.log("foundsds:", found?.blog_id);
    // formData.append("action", "fetch");

    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allBlogDetails&refer=${found?.pic}`,
      {
        method: "GET",
        // body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogDetails(result[0]);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [blogDataList[0], refer]);

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(blogDetails?.article_info?.[0]?.details),
  });

  return (
    <Box sx={{}}>
      <Container>
        <Grid container spacing={0} mt={4} mb={4} padding={0}>
          <Grid
            item
            xs={8}
            sx={{
              border: "1px solid #e1e6e9",
              borderRadius: "15px",
              padding: "25px",
              bgcolor: "white",
            }}
          >
            <Stack
              spacing={6}
              justifyContent="left"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Stack
                justifyContent="left"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Stack
                  spacing={0}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  {/* <Stack
                    spacing={2}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems="center"
                    sx={{ width: "90%" }}
                  > */}
                  <Box sx={{ width: "90%" }}>
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 600, fontSize: "20pt" }}
                    >
                      {blogDetails?.headline}
                    </Typography>
                  </Box>

                  {/* </Stack> */}
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    {/* <IconButton>
                      <Share sx={{ color: "#337ff1" }} />
                      
                    </IconButton> */}

                    <SocialShare
                      url={"https://ghorami.com/blogdetails/" + { refer }}
                      title={blogDetails?.headline}
                      hashTag={"ghorami"}
                      description={blogDetails?.article_info?.[0]?.details?.slice(
                        0,
                        120
                      )}
                    />
                    {/* <FacebookShareButton
                      url={blogDetails?.url_refer}
                      // imageUrl={"https:" + blogDetails?.picture}
                      quote={blogDetails?.headline}
                    >
                      <FacebookIcon size={30} round={true} />
                    </FacebookShareButton> */}
                  </Stack>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"left"}
                  alignItems="center"
                >
                  <Home sx={{ fontWeight: 500, fontSize: "11pt" }} />
                  <NavigateNext
                    sx={{ fontWeight: 500, fontSize: "11pt", color: "gray" }}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, fontSize: "12pt" }}
                  >
                    Blog
                  </Typography>
                  <NavigateNext
                    sx={{ fontWeight: 500, fontSize: "11pt", color: "gray" }}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 600, fontSize: "10.5pt" }}
                  >
                    {blogDetails?.category}
                  </Typography>
                  <NavigateNext
                    sx={{ fontWeight: 500, fontSize: "11pt", color: "gray" }}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, fontSize: "11pt" }}
                  >
                    {blogDetails?.headline}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, fontSize: "11pt" }}
                  >
                    |
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, fontSize: "11pt" }}
                  >
                    # {blogDetails?.pic}
                  </Typography>
                </Stack>
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"left"}
                  alignItems="center"
                >
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems="center"
                  >
                    <CalendarMonth sx={{ fontWeight: 500, fontSize: "11pt" }} />

                    <Typography
                      variant="p"
                      sx={{ fontWeight: 400, fontSize: "11pt" }}
                    >
                      {blogDetails?.timeStamp}
                      {/* {blogDetails?.timeStamp} */}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems="center"
                  >
                    <Folder sx={{ fontWeight: 500, fontSize: "11pt" }} />

                    <Typography
                      variant="p"
                      sx={{ fontWeight: 500, fontSize: "12pt" }}
                    >
                      Blog,{" "}
                      <span style={{ fontWeight: 600, fontSize: "10.5pt" }}>
                        {" "}
                        {blogDetails?.type}
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              >
                <Box sx={{ height: "auto", position: "relative" }}>
                  {blogDetails?.con_image?.[0]?.pic_url ? (
                    <CardMedia
                      component="img"
                      height="100%"
                      image={
                        "https://yeapbe.com/" +
                        blogDetails?.con_image?.[0]?.pic_url
                      }
                      // image={blogDetails?.con_image?.[0]?.pic_url}
                      alt={blogDetails?.title}
                      sx={{ borderRadius: "12px" }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      height="100%"
                      image={
                        "https://ghorami.com/image/catPic/creative_work.jpg"
                      }
                      // image={blogDetails?.con_image?.[0]?.pic_url}
                      alt={blogDetails?.title}
                      sx={{ borderRadius: "12px" }}
                    />
                  )}

                  <Stack
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{
                      width: "200px",
                      height: "120px",
                      bgcolor: "#141A37",
                      transform: "skewX(40deg)",
                      // borderTopRightRadius: "7%",
                      // borderBottomLeftRadius: "7%",
                      position: "absolute",
                      bottom: 0,
                      left: -45,
                    }}
                  >
                    <Typography
                      sx={{
                        // transform: "",
                        fontSize: "28pt",
                        fontWeight: 700,
                        color: "white",
                        transform: "skewX(-40deg) translate(10px)",
                      }}
                    >
                      #{blogDetails?.pic}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
              <Typography>
                <div dangerouslySetInnerHTML={sanitizedData()} />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack sx={{ paddingX: "30px" }}>
              <Box>
                {/* <OutlinedInput
                  sx={{ width: "100%", mb: "20px" }}
                  id="outlined-adornment-weight"
                  size="small"
                  placeholder="What are you looking for"
                  // value={values.weight}
                  // onChange={handleChange("weight")}
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                /> */}
              </Box>
              {/* <BlogItemShort /> */}
              <BlogTitleList />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Blogdetails;
