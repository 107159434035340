import { Box, Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

const SubList = (props) => {
  const { data } = props;
  const { banner_pic, title, refer, info, total_listing, type } = data;
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: "100%",
        paddingY: 3,
        cursor: "pointer",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
      onClick={() => {
        navigate("/expertlist/" + refer);
      }}
    >
      <Stack spacing={1} alignItems={"center"} justifyContent={"flex-start"}>
        <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="p" sx={{ fontSize: "10pt", fontWeight: 500 }}>
          Skills: {total_listing}
        </Typography>
        <Box
          component="img"
          sx={{
            width: 100,
            cursor: "pointer",
            borderRadius: "12px",
            // display: { xs: "none", sm: "none", md: "block" },
          }}
          alt="The house from the offer."
          src={banner_pic}
        />
      </Stack>
    </Card>
  );
};

export default SubList;
