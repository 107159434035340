import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import "../ServiceDetails.css";
import { BiShareAlt } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import img from "../../../../images/exertio-01_Homes.jpg";
import Rating from "@mui/material/Rating";
import { Stack } from "@mui/system";
import {
  ArrowRightAlt,
  Category,
  Done,
  Favorite,
  FavoriteBorder,
  Loop,
  Reply,
  Schedule,
  Subject,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const RightTop = (props) => {
  const navigate = useNavigate();

  const { fetchedServiceDetails, refer } = props;
  console.log("refesasasr:", refer);
  const {
    service_refer,
    jcategory,
    jschedule,
    jsubcat,
    price,
    currency,
    title,
    short_key,
  } = fetchedServiceDetails;
  console.log("fetchedServiceDetails:", fetchedServiceDetails);

  let keys;
  if (short_key) {
    keys = short_key.split(", ");
  }
  return (
    <Box>
      <Card sx={{ paddingX: "10px", border: "1px solid #dfdddd" }}>
        <CardContent>
          <Stack spacing={1}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Box sx={{ width: "65%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "13pt", fontWeight: 600 }}
                >
                  {title}
                </Typography>
              </Box>

              <Box sx={{ width: "32%" }}>
                {" "}
                <Typography
                  variant="p"
                  sx={{ fontSize: "15pt", fontWeight: 600 }}
                >
                  {currency} {price}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="left"
                alignItems={"center"}
                sx={{ width: "40%" }}
              >
                <Category sx={{ fontSize: "11pt", color: "#039faf" }} />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600 }}
                >
                  Category
                </Typography>
              </Stack>
              <Typography
                variant="p"
                sx={{ width: "2%", fontSize: "11pt", fontWeight: 600 }}
              >
                :
              </Typography>

              <Typography
                variant="p"
                sx={{ width: "50%", fontSize: "11pt", fontWeight: 500 }}
              >
                {jcategory}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="left"
                alignItems={"center"}
                sx={{ width: "40%" }}
              >
                <Subject sx={{ fontSize: "11pt", color: "#039faf" }} />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600 }}
                >
                  Sub Category
                </Typography>
              </Stack>
              <Typography
                variant="p"
                sx={{ width: "2%", fontSize: "11pt", fontWeight: 600 }}
              >
                :
              </Typography>{" "}
              <Typography
                variant="p"
                sx={{ width: "50%", fontSize: "11pt", fontWeight: 500 }}
              >
                {jsubcat}
              </Typography>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="left"
              alignItems={"flex-start"}
            >
              <Stack
                spacing={0.5}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Schedule
                  sx={{ fontSize: "12pt", fontWeight: 600, color: "#039faf" }}
                />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600 }}
                >
                  {jschedule}
                </Typography>
              </Stack>
              <Stack
                spacing={0.5}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Loop
                  sx={{
                    fontSize: "13pt",
                    fontWeight: 600,
                    transform: "rotate(-45deg)",
                    color: "#039faf",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600 }}
                >
                  2 times Revision
                </Typography>
              </Stack>
            </Stack>

            <Stack justifyContent="left" alignItems={"flex-start"}>
              {keys?.map((element) => (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Done
                    sx={{
                      fontSize: "13pt",
                      fontWeight: 700,
                      color: "darkgreen",
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 600, color: "gray" }}
                  >
                    {element}
                  </Typography>
                </Stack>
              ))}

              {/* <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Done
                  sx={{ fontSize: "13pt", fontWeight: 700, color: "darkgreen" }}
                />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600, color: "gray" }}
                >
                  Responsive design
                </Typography>
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Done
                  sx={{ fontSize: "13pt", fontWeight: 700, color: "darkgreen" }}
                />
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 600, color: "gray" }}
                >
                  Source file
                </Typography>
              </Stack> */}
            </Stack>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                bgcolor: "#cd4646",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              onClick={() => {
                navigate("/book/" + refer);
              }}
            >
              Book Now
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RightTop;
