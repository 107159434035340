import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { FaRegComment } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { BsCheckLg } from "react-icons/bs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    // backgroundColor: "#eaf7f4",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    // fontWeight:"bold"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const iconStyle = {
  backgroundColor: "#00c194",
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  lineHeight: "30px",
  textAlign: "center",
  display: "flex",
  color: "#ffffff",
  // fontSize: "15px",
  transition: "0.4s",
  padding: "4px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
};
const iconTitleStyle = {
  fontSize: "21px",
  color: "#878c9f",
  textTransform: "capitalize",
};
const iconTitleAreaStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: "32px",
  color: "#fff",
  cursor: "pointer",
  lineHeight: "1.2",
};
function DetailsFeature(props) {
  const { fetchedServiceDetails } = props;
  const { jcategory, jschedule, jsubcat, price, currency, title, short_key } =
    fetchedServiceDetails;
  const rows = [
    createData("Title", title),
    createData("Category", jcategory),
    createData("SubCategory", jsubcat),
    createData("Price", currency + " " + price),
    createData("Timeline", jschedule),
    createData("Terms", short_key),
  ];
  let keys;
  if (short_key) {
    keys = short_key.split(", ");
  }
  return (
    <>
      <Card style={{ padding: "30px" }}>
        <CardContent>
          <Typography
            style={{
              fonSize: "22px",
              fontWeight: "600",
              color: "#212121",
            }}
            variant="h5"
            component="Box"
          >
            Details
          </Typography>
          <Box sx={{ marginTop: "30px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead></TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 700 }}
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.fat}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.carbs}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <Divider sx={{ borderColor: "#ede2e2" }} variant="strong" />
      <Card style={{ padding: "30px" }}>
        <CardContent>
          <Typography
            style={{
              // marginBottom: "40px",
              fonSize: "22px",
              fontWeight: "600",
              color: "#212121",
            }}
            variant="h5"
            component="Box"
          >
            Features & Terms
          </Typography>
          <Box sx={{ marginTop: "30px" }}>
            <Grid container spacing={1}>
              {keys?.map((element) => (
                <Grid item xs={12} md={6} lg={6}>
                  <Box sx={iconTitleAreaStyle}>
                    <BsCheckLg style={iconStyle} />
                    <Typography
                      variant="subtitle"
                      component="p"
                      sx={iconTitleStyle}
                    >
                      {element}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default DetailsFeature;
