import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ghorami from "../../../images/ghorami.ico";

const ChatBox = ({
  profileDetails,
  pic,
  name,
  urlId,
  botOldConversation,
  botNewResponse,
}) => {
  return (
    <Box sx={{}}>
      {botOldConversation?.map((element) => (
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"left"}
            alignItems="flex-start"
            spacing={1}
          >
            <Box
              component="img"
              sx={{
                //   padding: "0 12px",
                height: "35px",
                width: "35px",
                //   objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={pic}
            />
            <Stack spacing={0.2} sx={{ maxWidth: "50%" }}>
              <Box
                sx={{
                  bgcolor: "#ebecedba",
                  padding: "5px",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 500 }}
                >
                  {element?.response}
                </Typography>
              </Box>
              <Typography variant="p" sx={{ fontSize: "7pt", fontWeight: 500 }}>
                09.49
              </Typography>
            </Stack>
          </Stack>
          {element?.message && (
            <Stack
              direction={"row"}
              justifyContent={"right"}
              alignItems="center"
              spacing={1}
            >
              <Stack
                spacing={0.2}
                sx={{ maxWidth: "60%" }}
                alignItems="flex-end"
              >
                <Box
                  sx={{
                    bgcolor: "lightcyan",
                    padding: "5px",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", fontWeight: 500 }}
                  >
                    {element?.message}
                  </Typography>
                </Box>
                <Typography
                  variant="p"
                  sx={{ fontSize: "7pt", fontWeight: 500 }}
                >
                  09.40
                </Typography>
              </Stack>
              <Box
                component="img"
                sx={{
                  //   padding: "0 12px",
                  height: "35px",
                  width: "35px",
                  //   objectFit: "cover",
                  cursor: "pointer",

                  borderRadius: "50%",
                  // display: { xs: "none", sm: "none", md: "block" },
                }}
                alt="The house from the offer."
                src={ghorami}
              />
            </Stack>
          )}
        </Box>
      ))}

      {botNewResponse?.message && (
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"right"}
            alignItems="center"
            spacing={1}
          >
            <Stack spacing={0.2} sx={{ maxWidth: "60%" }} alignItems="flex-end">
              <Box
                sx={{
                  bgcolor: "lightcyan",
                  padding: "5px",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 500 }}
                >
                  {botNewResponse?.message}
                </Typography>
              </Box>
              <Typography variant="p" sx={{ fontSize: "7pt", fontWeight: 500 }}>
                09.40
              </Typography>
            </Stack>
            <Box
              component="img"
              sx={{
                //   padding: "0 12px",
                height: "35px",
                width: "35px",
                //   objectFit: "cover",
                cursor: "pointer",

                borderRadius: "50%",
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src="https://app.ghorami.com/static/media/ghorami_tracker_icon.aa0e7b4a3ef5a8a446af.png"
            />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"left"}
            alignItems="flex-start"
            spacing={1}
          >
            <Box
              component="img"
              sx={{
                //   padding: "0 12px",
                height: "35px",
                width: "35px",
                //   objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={pic}
            />
            <Stack spacing={0.2} sx={{ maxWidth: "50%" }}>
              <Box
                sx={{
                  bgcolor: "#ebecedba",
                  padding: "5px",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 500 }}
                >
                  {botNewResponse?.response}
                </Typography>
              </Box>
              <Typography variant="p" sx={{ fontSize: "7pt", fontWeight: 500 }}>
                09.49
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default ChatBox;
