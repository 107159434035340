import { ArrowRightAlt } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FaAtom, FaBeer } from "react-icons/fa";
import React from "react";
import Slider from "react-slick";
import logo from "../../../images/download.png";
import "./CategoryItem.css";
import { useNavigate } from "react-router-dom";

const CategoryItem = (props) => {
  const { banner_pic, refer, title, total_listing, type } = props.data;
  const navigate = useNavigate();
  const nameArray = title.split(" ");
  const polutedName = nameArray.join("");
  const routeName = polutedName.replace("&", "_");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider {...settings} sx={{}}>
      <Box sx={{ position: "relative" }} className="c1">
        <Box
          component="img"
          sx={{
            width: "100%",
          }}
          alt={title}
          src={banner_pic}
        />
        <Box
          className="c1Black"
          sx={{
            height: "0%",
            width: "100%",
            bgcolor: "rgba(0,0,0,1)",
            position: "absolute",
            overflow: "hidden",
            top: "100%",
            opacity: 1,
          }}
        ></Box>
        <Box
          className="c1logo"
          component="img"
          sx={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            top: "20%",
            left: "6%",
            borderRadius: "50%",
            opacity: 0,
          }}
          alt="Category Icon."
          src={logo}
          onClick={() => {
            navigate("/explore/" + refer);
          }}
        />
        <Button
          className="c1btn"
          variant="contained"
          sx={{
            bgcolor: "#c5460a",
            color: "white",
            position: "absolute",
            top: "100%",
            left: "7%",
            opacity: 0,
            borderRadius: "30px",
          }}
          onClick={() => {
            navigate("/explore/" + refer);
          }}
          endIcon={<ArrowRightAlt />}
        >
          <Typography
            sx={{
              fontSize: "8pt",
              color: "white",
            }}
          >
            Explore
          </Typography>
        </Button>
        <Box
          className="c1Details"
          sx={{ position: "absolute", top: "65%", left: "7%" }}
        >
          <Button
            className="listing"
            size="small"
            variant="contained"
            sx={{
              color: "white",
              bgcolor: "#088c4d",
              fontSize: "8pt",
              textTransform: "capitalize",
              borderRadius: "30px",
              marginTop: "20px",
            }}
          >
            Skills: {total_listing}
          </Button>

          <Typography
            className="textT1"
            sx={{
              fontSize: { xs: "13px", md: "18px" },
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Slider>
  );
};

export default CategoryItem;
