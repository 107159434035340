import { BrowserRouter } from "react-router-dom";
import Header from "../src/Component/header/Header";
import MenuTags from "./Component/Menu/MenuTags";
import Pages from "./Pages/Pages";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <MenuTags />
      <Pages />
    </BrowserRouter>
  );
}
export default App;
