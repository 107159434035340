import React from "react";
import "./styles.css";
import {
  FacebookShareButton,
  LinkedinIcon,
  TwitterShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import ShareLink from "react-linkedin-share-link";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import { CopyAll } from "@mui/icons-material";

const SocialShare = ({ url, title, hashTag, description }) => {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  console.log("copySuccess:", copySuccess);

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    setCopySuccess("Copied");
  }

  return (
    <Stack
      className="App"
      direction={"row"}
      spacing={1}
      justifyContent={"right"}
      alignItems={"center"}
    >
      <CopyAll
        size={52}
        sx={{ cursor: "pointer" }}
        round
        onClick={copyToClip}
      />

      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={"#" + hashTag}
        description={description}
        // className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton title={title} url={url} hashtags={[hashTag]}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <ShareLink link={url}>
        {(link) => (
          <a href={link} target="_blank">
            <LinkedinIcon size={32} round />
          </a>
        )}
      </ShareLink>
    </Stack>
  );
};

export default SocialShare;
