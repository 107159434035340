import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../Component/Custom Hooks/useFetch";

const Category = () => {
  const { type } = useParams();
  console.log("params:", type);
  const { data } = useFetch("");
  return <Box></Box>;
};

export default Category;
