import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import HouseGallery from "./houseGallery/HouseGallery";
import "./ServiceDetails.css";

import { Link, useNavigate, useParams } from "react-router-dom";
import ContactAgent from "./contactAgent/ContactAgent";
// import LatestListing from "./../PropertyList/propertyListComponent/leftSide/LatestListing";
import Overview from "./overView/Overview";
import Details from "./detailsFeature/DetailsFeature";
import BooknowTopSection from "../../Booknow Component/BooknowTopSection";
import { ArrowRightAlt, Reply, Schedule, Share } from "@mui/icons-material";
import RightTop from "./rightSection/RightTop";
import { useEffect } from "react";
import {
  fetchedReviewApi,
  fetchedServiceDetailsApi,
  fetchedServicePicApi,
  serviceApi,
  SimilarServiceApi,
} from "../../API/Api";
import { useState } from "react";
import GalleryContainer from "./houseGallery/GalleryContainer";
import DescriptionTab from "./Description Tab/DescriptionTab";
import AboutSeller from "./rightSection/AboutSeller";
import { useRef } from "react";
import Reviewer from "./Reviewer/Reviewer";
import Review from "./Review/Review";
import Portfolio from "./Portfolio/Portfolio";
import SimilarProducts from "./Similar Products/SimilarProducts";
import FloatingContactMe from "../../Profile Components/ChatBot/FloatingContactMe";
import useChatBot from "../../Custom Hooks/useChatBot";
import ReqNewQuotation from "./rightSection/ReqNewQuotation";
import SocialShare from "../../SocialShare/SocialShare";

function ServiceDetails() {
  // const focus = useRef("");
  const navigate = useNavigate();
  const { refer } = useParams();
  console.log("refdsdsder:", refer);

  const [serviceDataList, setServiceDataList] = useState([]);
  const [referId, setReferId] = useState([]);
  const [fetchedServiceDetails, setFetchedServiceDetails] = useState([]);
  console.log("fetchedServiceDetails:", fetchedServiceDetails);
  const [scrollH, setScrollH] = useState(0);
  const [s_id, setS_id] = useState(fetchedServiceDetails.s_id);

  const [fetchedServicePic, setFetchedServicePic] = useState([]);
  const [fetchedSimilarService, setFetchedSimilarService] = useState([]);
  console.log("fetchedSimilarService:", fetchedSimilarService);
  const [fetchedReview, setFetchedReview] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      setScrollH(window.pageYOffset);
    });
  }, [scrollH]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page_refer", refer);
    formData.append("action", "fetch");

    fetch(fetchedReviewApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchedReview(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [serviceDataList[0]]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("c_refer", fetchedServiceDetails.c_refer);
    formData.append("seid", fetchedServiceDetails.s_id);

    formData.append("action", "fetch");

    fetch(SimilarServiceApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchedSimilarService(result.slice(0, 6));
      })
      .catch((error) => console.log("fetchedServicePic=> ", error));
  }, [fetchedServiceDetails.s_id, fetchedServiceDetails.c_refer]);

  useEffect(() => {
    setReferId(refer);
    fetch(serviceApi)
      .then((res) => res.json())
      .then((result) => {
        setServiceDataList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    // let found = serviceDataList?.find(
    //   (element) => element.service_refer === refer
    // );
    const formData = new FormData();
    formData.append("pref", refer);
    formData.append("action", "fetch");

    fetch(fetchedServiceDetailsApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchedServiceDetails(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(fetchedServicePicApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchedServicePic(result);
      })
      .catch((error) => console.log("fetchedServicePic=> ", error));
  }, [serviceDataList[0]]);

  return (
    <Box
      sx={{
        paddingY: "0px",
        paddingX: { lg: "80px", md: "50px", sm: "30px", xs: "20px" },
        overflow: "hidden",
        position: "relative",
      }}
    >
      {useChatBot(
        fetchedServiceDetails?.agency_logo
          ? fetchedServiceDetails?.agency_logo
          : fetchedServiceDetails?.poster_pic,
        fetchedServiceDetails.agency_title,
        fetchedServiceDetails.agency_refer,
        chatOpen,
        setChatOpen
      )}
      <Grid container columnSpacing={9} mt={0} sx={{}}>
        <Grid item xs={12} md={8} lg={8}>
          <Stack spacing={3}>
            <Box>
              <GalleryContainer
                fetchedServicePic={fetchedServicePic}
                fetchedServiceDetails={fetchedServiceDetails}
              />
            </Box>
            <Stack
              spacing={1}
              sx={{
                width: "100%",
                display: { xs: "block", md: "none" },
              }}
            >
              <RightTop
                fetchedServiceDetails={fetchedServiceDetails}
                refer={refer}
              />

              <ReqNewQuotation refer={refer} />
            </Stack>

            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Box
                  sx={{
                    paddingX: "9px",
                    paddingY: "1px",
                    bgcolor: "#e2efe2",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ color: "#09b909", fontWeight: 600 }}
                  >
                    {fetchedServiceDetails.total_rating}
                  </Typography>
                </Box>
                <Rating
                  name="read-only"
                  value={parseFloat(fetchedServiceDetails.rating_state)}
                  readOnly
                  sx={{ fontSize: "13pt" }}
                />
                {fetchedServiceDetails.review_key?.map((element) => (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      bgcolor: element.bg_color,
                      color: element.color_c,
                      // border: "1px solid #14506A",
                      // width: "47%",
                      borderRadius: "30px",
                      paddingY: "0px",
                    }}
                  >
                    {element.keyw}
                  </Button>
                ))}
              </Stack>

              {/* <IconButton aria-label="delete" size="large">
                <Share
                  sx={{
                    color: "#14506A",
                    fontSize: "26pt",
                  }}
                />
              </IconButton> */}

              <SocialShare
                url={"https://ghorami.com/servicedetails/" + { refer }}
                title={fetchedServiceDetails?.title}
                hashTag={fetchedServiceDetails?.agency_title}
                description={fetchedServiceDetails?.details?.slice(0, 120)}
              />
            </Stack>
            <Stack>
              <Typography
                variant="p"
                sx={{ fontSize: "20pt", fontWeight: 600 }}
              >
                {fetchedServiceDetails.title}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Dhaka, Bangladesh
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              spacing={0.5}
              justifyContent="left"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                {fetchedServiceDetails.jcategory}
              </Typography>
              <ArrowRightAlt sx={{ fontSize: "12pt" }} />
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                {fetchedServiceDetails.jsubcat}
              </Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Schedule
                sx={{ fontSize: "12pt", fontWeight: 600, color: "#039faf" }}
              />
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                {fetchedServiceDetails.jschedule}
              </Typography>
            </Stack>
            <DescriptionTab fetchedServiceDetails={fetchedServiceDetails} />
            <AboutSeller
              fetchedServiceDetails={fetchedServiceDetails}
              setChatOpen={setChatOpen}
            />
            <Details fetchedServiceDetails={fetchedServiceDetails} />
            <Portfolio s_id={fetchedServiceDetails.s_id} />
            <Review fetchedReview={fetchedReview} />
            {fetchedReview.review_data?.map(
              (element) =>
                element.type === "service" && (
                  <Reviewer
                    refer={refer}
                    fetchedReviewItem={element}
                    fetchedReviewList={fetchedReview.review_data}
                  />
                )
            )}
            <SimilarProducts fetchedSimilarService={fetchedSimilarService} />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Stack
            spacing={1}
            sx={{
              position: "fixed",
              top: "24%",
              width: "28%",
              right: "6%",
              transition: "all 0.1s",
            }}
            className={scrollH > 90 ? "translate" : ""}
          >
            <RightTop
              fetchedServiceDetails={fetchedServiceDetails}
              refer={refer}
            />

            <ReqNewQuotation refer={refer} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServiceDetails;
