import { Stack, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import React from "react";
import HouseGallery from "./HouseGallery";
import playButton from "../../../../images/playButton.png";
import { PlayCircle } from "@mui/icons-material";
import { useState } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";
import "./videoplayer.css";
import GhoramiPlayer from "../../../Custom Hooks/Video Player/GhoramiPlayer";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const GalleryContainer = (props) => {
  const focus = useRef("");
  // const videoRef = useRef(null);

  const { fetchedServicePic, fetchedServiceDetails } = props;
  const [value, setValue] = useState(0);
  const [play, setPlay] = useState(false);
  const [hide, setHide] = useState(false);
  console.log("playyyyy:", play);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: { xs: "60px", md: "0px" } }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Picture" {...a11yProps(0)} sx={{ width: "50%" }} />
          <Tab label="Video" {...a11yProps(1)} sx={{ width: "50%" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <HouseGallery fetchedServicePic={fetchedServicePic} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          {fetchedServiceDetails?.videoUrl?.[0] == null ||
          fetchedServiceDetails?.videoUrl?.[0] === "" ? (
            <Box sx={{ width: "100%" }}>
              <GhoramiPlayer
                videoUrl="https://ghorami.com/image/ghorami_Intro.mp4"
                playerHeight={"45vh"}
              />
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <GhoramiPlayer
                videoUrl={
                  "https://yeapbe.com/" + fetchedServiceDetails?.videoUrl?.subId
                }
                playerHeight={"45vh"}
              />
            </Box>
          )}

          {fetchedServiceDetails?.service_video_type === "youtube" && (
            <ReactPlayer
              controls={false}
              playing={play}
              url={fetchedServiceDetails?.service_video_url}
            />
          )}
          {/* {fetchedServiceDetails?.service_video_type === "youtube" && (
            <>
              <Box
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "78%",
                  position: "absolute",
                  top: 0,
                  left: "11%",
                  height: "48.5vh",
                  // bgcolor: "red",
                  // display: hide ? "none" : "block",
                }}
                onClick={() => {
                  setPlay(false);
                }}
              ></Box>

              <Box
                component="img"
                sx={{
                  // padding: "0 12px",
                  position: "absolute",
                  top: "42%",
                  left: "48%",
                  width: 40,
                  cursor: "pointer",
                  display: play ? "none" : "block",
                }}
                alt="The house from the offer."
                src={playButton}
                onClick={() => {
                  setPlay(true);
                  // setHide(true);
                }}
              />
            </>
          )} */}
        </Stack>
      </TabPanel>
    </Box>
  );
};

export default GalleryContainer;
