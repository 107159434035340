import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { menuBarApi } from "../API/Api";

const pages = [
  "Corporate",
  "Printing",
  "Event",
  "Promotion",
  "Mobile",
  "Website",
  "Design & Animation",
  "Marketing",
  "Writing",
  "Legal",
  "Admin",
  "Photography",
  "All",
];

const MenuTags = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [menuBarData, setMenuBarData] = useState([]);

  useEffect(() => {
    fetch(menuBarApi)
      .then((res) => res.json())
      .then((result) => {
        setMenuBarData(result);
      })
      .catch((error) => console.log("categoryData Error => ", error));
  }, []);
  // console.log("menuBarData:", menuBarData);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleClick = (path) => {

  // };

  return (
    <Box
      sx={{
        bgcolor: "black",
        border: "1px solid #dee2e6",
        display: { xs: "none", sm: "none", md: "block" },
        mt: "70px",
        position: "relative",
        top: 0,
        zIndex: 2,
      }}
    >
      <Container>
        <AppBar
          elevation={0}
          position="static"
          sx={{ backgroundColor: "black", color: "white" }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages?.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" sx={{ color: "white" }}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              {menuBarData?.map((element) => (
                <Button
                  key={element.id}
                  onClick={() => {
                    navigate("/explore/" + element.id);
                  }}
                  sx={{
                    my: 2,
                    // color: "white",
                    display: "block",
                    color: "#ACACAC",
                  }}
                >
                  <Typography variant="p" sx={{ fontSize: "9pt" }}>
                    {element.title}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
};

export default MenuTags;
