import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AgencyListApi,
  expertAllCategoryApi,
  expertAllSubCategoryApi,
  ExpertSubCategoryApi,
  freelancerApi,
  serviceCategoryApi,
} from "../Component/API/Api";

import { Search } from "@mui/icons-material";
import AgencyCardItem from "../Component/Homepage Components/Agency/AgencyCardItem";

// const decodeLocalData = (name) => {
//   let result = atob(window.localStorage.getItem(name));
//   return JSON.parse(result);
// };
const AgencyList = () => {
  // let localData = decodeLocalData("gh_sl@g");
  const navigate = useNavigate();
  const [firstLoadIndegator, setFirstLoadIndegator] = useState(true);
  const [source, setSource] = useState("category");
  const [sourceValueId, setSourceValueId] = useState("");

  const [page, setPage] = useState(1);
  const [searchFieldText, setSearchFieldText] = useState("");
  const [freelancerData, setFreelancerData] = useState([]);
  console.log("freelancerData006:", freelancerData);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [fetchedCategoryListData, setFetchedCategoryListData] = useState([]);
  const [fetchedSubCategoryListData, setfetchedSubCategoryListData] = useState(
    []
  );
  useEffect(() => {
    fetch(AgencyListApi)
      .then((res) => res.json())
      .then((result) => {
        setFreelancerData(result);
      })
      .catch((error) => console.log("freelancerData Error => ", error));
  }, []);

  useEffect(() => {
    fetch(serviceCategoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  // useEffect(() => {
  //   let found = categoryListData?.find(
  //     (element) => element.category_name === category
  //   );
  //   const formData = new FormData();
  //   formData.append("pref", found?.category_id);
  //   formData.append("action", "fetch");

  //   fetch(ExpertSubCategoryApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setSubCategoryListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));

  //   fetch(expertAllCategoryApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setFreelancerData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, [category]);

  // useEffect(() => {
  //   let found = subCategoryListData?.find(
  //     (element) => element.sub_name === subCategory
  //   );

  //   const formData = new FormData();
  //   formData.append("pref", found?.sub_id);
  //   formData.append("action", "fetch");

  //   fetch(expertAllSubCategoryApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setFreelancerData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, [subCategory]);

  // useEffect(() => {
  //   const formData = new FormData();

  //   if (source === "category") {
  //     let found = categoryListData?.find(
  //       (element) => element.category_name === category
  //     );
  //     const formData = new FormData();
  //     formData.append("pref", found?.category_id);
  //     formData.append("page", page);
  //     formData.append("action", "fetch");

  //     fetch(expertAllCategoryApi, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setFreelancerData(result);
  //       })
  //       .catch((error) => console.log("ServiceApiError=> ", error));
  //   } else {
  //     let found = subCategoryListData?.find(
  //       (element) => element.sub_name === sourceValueId
  //     );
  //     formData.append("pref", found?.sub_id);
  //     formData.append("page", page);
  //     fetch(ExpertSubCategoryApi, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setFreelancerData(result);
  //       })
  //       .catch((error) => console.log("ServiceApiError=> ", error));
  //   }
  // }, [page]);

  const handlePageChange = (event, value) => {
    setFirstLoadIndegator(false);
    setPage(value);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setSource("category");
    setSourceValueId(event.target.value);
  };
  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
    setSource("subCategory");
    setSourceValueId(event.target.value);
  };
  const handleSearch = () => {
    setFirstLoadIndegator(false);

    fetch(
      `https://ghorami.com/profile/login/api_freelancer/expert_all_search.php?s_query=${searchFieldText}`
    )
      .then((res) => res.json())
      .then((result) => {
        setFreelancerData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };
  return (
    <Box>
      <Container sx={{ mt: "0px", width: "100%" }}>
        <Box mt={5}>{/* <ExpertListBanner /> */}</Box>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Stack
            direction={"row"}
            justifyContent="left"
            alignItems="center"
            spacing={1}
            sx={{ width: "35%" }}
          >
            <Typography variant="p" sx={{ fontWeight: 600 }}>
              Category:{" "}
            </Typography>
            <FormControl sx={{ width: "60%" }}>
              <Select
                value={category}
                onChange={handleCategoryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {categoryListData.map((element) => (
                  <MenuItem value={element.category_name}>
                    {element.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="left"
            alignItems="center"
            spacing={1}
            sx={{ width: "35%" }}
          >
            <Typography variant="p" sx={{ fontWeight: 600 }}>
              Sub Category:{" "}
            </Typography>
            <FormControl sx={{ width: "60%" }}>
              <Select
                value={subCategory}
                onChange={handleSubCategoryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {subCategoryListData.map((element) => (
                  <MenuItem value={element.sub_name} key={element.sub_id}>
                    {element.sub_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <OutlinedInput
            color="success"
            name="search_text"
            type="text"
            onChange={(e) => {
              setSearchFieldText(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  size="small"
                  sx={{ marginTop: "5px", marginLeft: "10px" }}
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </Stack>
        {freelancerData[0]?.crefer ? (
          <Grid container spacing={2} mt={4} mb={4}>
            {freelancerData?.map((element) => (
              <Grid item xs={6} sm={6} md={3} key={element.crefer}>
                <AgencyCardItem agencyData={element} key={element.crefer} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              height: "57vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {firstLoadIndegator ? (
              //   <img
              //     src={loading}
              //     alt="loading"
              //     style={{
              //       width: "120px",
              //       height: "120px",
              //     }}
              //   />
              <Typography
                variant="p"
                sx={{ fontSize: "25pt", fontWeight: 600 }}
              >
                Wait for sometime
              </Typography>
            ) : (
              <Typography
                variant="p"
                sx={{ fontSize: "25pt", fontWeight: 600 }}
              >
                No data found!
              </Typography>
            )}
          </Box>
        )}
        <Stack justifyContent={"space-between"} alignItems="center">
          <Pagination
            count={10}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            sx={{ margin: "auto", marginBottom: "30px" }}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default AgencyList;
