import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const CardItem = (props) => {
  const navigate = useNavigate();
  const { categoryData } = props;
  const { banner_pic, title, total_listing, refer } = categoryData;
  return (
    <Card
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigate("/explore/" + refer);
      }}
    >
      <CardMedia component="img" height="100" image={banner_pic} alt={title} />
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        sx={{ height: "55px" }}
      >
        <Typography
          gutterBottom
          variant="p"
          sx={{ textAlign: "center", fontSize: "9pt" }}
        >
          {title}
        </Typography>
      </Stack>
    </Card>
  );
};

export default CardItem;
