import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/writing_work.jpg";
import "./css/UserType.css";

const UserType = () => {
  const navigate = useNavigate()  ;
  const [activeBox, setActiveBox] = useState("client");
  return (
    <DetailWrapper>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        sx={{ height: "79vh" }}
      >
        <Stack
          spacing={3}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: "50%",
            height: "70vh",
            border: "1px solid #D5E0D5",
            borderRadius: "12px",
          }}
        >
          <Typography variant="p" sx={{ fontSize: "23pt", fontWeight: 600 }}>
            Join as a client or freelancer
          </Typography>

          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"center"}
            alignItems="center"
          >
            <Box
              onClick={() => {
                setActiveBox("client");
              }}
              className={activeBox === "client" ? "active" : ""}
              sx={{
                width: "45%",
                height: "20vh",
                position: "relative",
                border: "2px solid gray",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              {activeBox === "freelancer" && (
                <RadioButtonUncheckedOutlined
                  sx={{
                    position: "absolute",
                    right: 3,
                    top: 2,
                    color: "gray",
                  }}
                />
              )}
              {activeBox === "client" && (
                <RadioButtonChecked
                  sx={{
                    position: "absolute",
                    right: 3,
                    top: 2,
                    color: "#009C8B",
                  }}
                />
              )}

              <Box mt={2}>
                <Box
                  component="img"
                  sx={{
                    padding: "0 12px",
                    width: 50,
                    cursor: "pointer",
                  }}
                  alt="The house from the offer."
                  src={logo}
                />
              </Box>
              <Box sx={{ paddingLeft: "10px" }}>
                <Typography variant="p">
                  I’m a client, hiring for a project
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() => {
                setActiveBox("freelancer");
              }}
              className={activeBox === "freelancer" ? "active" : ""}
              sx={{
                width: "45%",
                height: "20vh",
                position: "relative",
                border: "2px solid gray",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              {activeBox === "client" && (
                <RadioButtonUncheckedOutlined
                  sx={{
                    position: "absolute",
                    right: 3,
                    top: 2,
                    color: "gray",
                  }}
                />
              )}
              {activeBox === "freelancer" && (
                <RadioButtonChecked
                  sx={{
                    position: "absolute",
                    right: 3,
                    top: 2,
                    color: "#009C8B",
                  }}
                />
              )}
              <Box mt={2}>
                <Box
                  component="img"
                  sx={{
                    padding: "0 12px",
                    width: 50,
                    cursor: "pointer",

                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={logo}
                />
              </Box>
              <Box sx={{ paddingLeft: "10px" }}>
                <Typography variant="p">
                  I’m a freelancer, looking for work
                </Typography>
              </Box>
            </Box>
          </Stack>

          {activeBox === "client" && (
            <Button
              variant="contained"
              sx={{ bgcolor: "#009C8B", width: "35%" }}
              onClick={() => {
                navigate("/usertype");
              }}
            >
              Join as a client
            </Button>
          )}
          {activeBox === "freelancer" && (
            <Button
              variant="contained"
              sx={{ bgcolor: "#009C8B", width: "35%" }}
              onClick={() => {
                navigate("/usertype");
              }}
            >
              Apply as a freelancer
            </Button>
          )}
          <Typography variant="p">
            Already have an account?
            <span style={{ color: "blue", cursor: "pointer" }}> Log In</span>
          </Typography>
        </Stack>
      </Stack>
    </DetailWrapper>
  );
};

export default UserType;

const DetailWrapper = styled.div`
  .active {
    background: rgba(234, 242, 215, 0.9);
    border: 2px solid #009c8b !important;
  }
`;
