import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { freelancerApi } from "../../API/Api";
import FreelancerItem from "./FreelancerItem";

const Freelancer = () => {
  const navigate = useNavigate();
  const [freelancerData, setFreelancerData] = useState([]);
  useEffect(() => {
    fetch(freelancerApi)
      .then((res) => res.json())
      .then((result) => {
        setFreelancerData(result.slice(0, 4));
      })
      .catch((error) => console.log("freelancerData Error => ", error));
  }, []);

  return (
    <Box mt={5}>
      <Stack
        justifyContent="center"
        alignItems="center"
        mb={-3}
        sx={{ paddingX: "15px" }}
      >
        <Typography
          variant="p"
          sx={{ fontWeight: 700, fontSize: { xs: "22pt", md: "29pt" } }}
        >
          Verified Professionals
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: 600,
            fontSize: "13pt",
            color: "gray",
            textAlign: "center",
          }}
        >
          Get connected with verified & experienced Professionals
        </Typography>
        <Typography
          variant="p"
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          for getting your job done with quality.
        </Typography>
      </Stack>
      <Container sx={{ mt: "0px" }}>
        <Grid container spacing={2} mt={4} mb={4}>
          {freelancerData?.map((element) => (
            <Grid item xs={6} sm={6} md={3} key={element.refer_id}>
              <FreelancerItem freelancerData={element} key={element.refer_id} />
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={6} md={4}>
            <FreelancerItem />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FreelancerItem />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FreelancerItem />
          </Grid> */}
        </Grid>
        <Stack direction={"row"} justifyContent="center" alignItems={"center"}>
          <Button
            variant="outlined"
            color="green"
            sx={{
              fontFamily: "Roboto Condensed",
              fontWeight: 400,
              fontSize: "23pt",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              navigate("/expertlist");
            }}
          >
            Load more...
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default Freelancer;
