import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "./css/Bloglist.css";
import { blogApi } from "../Component/API/Api";
import BlogItemShort from "../Component/Homepage Components/LatestBlogNews/BlogItemShort";
import BlogNewsItem from "../Component/Homepage Components/LatestBlogNews/BlogNewsItem";
import BlogItem from "../Component/Homepage Components/LatestBlogNews/BlogItem";
import CategoryList from "../Component/ServiceList Components/CategoryList";
import { Filter, FilterList, Search } from "@mui/icons-material";
import ResBlogListModal from "../Component/BlogList Components/ResBlogListModal";
import BlogCategoryList from "../Component/BlogList Components/BlogCategoryList";

const style = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  height: "0px",
  bgcolor: "background.paper",
  boxShadow: 1,
  overflow: "hidden",
  overflowY: "scroll",
  zIndex: 5,
  transition: "all .5s",
};
const BlogList = () => {
  const { caten } = useParams();

  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  console.log("blogData:", blogData);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setCategory(caten);
    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allBlog&category=${caten}`
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogData(result);
      })
      .catch((error) => console.log("BlogData Error => ", error));
  }, [caten]);

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allBlog`
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogData(result);
      })
      .catch((error) => console.log("BlogData Error => ", error));
  }, []);

  const handleSearch = () => {
    setLoading(true);

    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allBlog&keyword=${searchText}&page=${page}&category=${category}`
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogData(result);
        // if (result.length > 0) {
        //   setLoading(false);
        //   setNotFound(false);
        // } else {
        //   setLoading(false);
        //   setNotFound(true);
        // }
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    // arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box>
      <ResBlogListModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        style={style}
      />
      <Container>
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          mt={{ xs: 10, md: 4 }}
        >
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: "22pt" }}>
            Most <span style={{ color: "rgb(230, 130, 48)" }}>Popular</span>
          </Typography>
        </Stack>
        <Box className="blogListCarousel" marginTop={"30px"}>
          <Slider {...settings}>
            {blogData?.map((element) => (
              <Box>
                <BlogItemShort blogData={element} key={element.blog_refer} />
              </Box>
            ))}
          </Slider>
        </Box>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: "22pt" }}>
            All <span style={{ color: "rgb(230, 130, 48)" }}>Post</span>
          </Typography>

          <Button
            variant="contained"
            startIcon={<FilterList />}
            onClick={handleOpen}
            size="small"
            sx={{
              display: { xs: "block", md: "none" },
              textTransform: "capitalize",
            }}
          >
            Filter
          </Button>
        </Stack>

        <Grid container spacing={0} mt={4} mb={4} padding={0}>
          <Grid item xs={12} md={8}>
            <Grid container rowSpacing={4} columnSpacing={1} mt={0}>
              {blogData.map((element) => (
                <Grid item xs={12} sm={6} md={6} key={element.pic}>
                  <BlogItem blogData={element} key={element.pic} />
                </Grid>
              ))}
            </Grid>
            <Stack justifyContent="center" alignItems="center" mt={4}>
              <Pagination count={10} />
            </Stack>
          </Grid>
          <Grid item xs={0} md={4}>
            <Stack sx={{ paddingX: "30px" }}>
              <Box>
                <OutlinedInput
                  color="success"
                  sx={{ width: "90%" }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSearch}
                        size="small"
                        sx={{ marginTop: "5px", marginLeft: "10px" }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <BlogCategoryList />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogList;
