import { Box, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { freelancerCommentApi } from "../API/Api";
import FreelancerCommentItem from "./FreelancerCommentItem";

const FreelancerComment = ({ id }) => {
  const [freelancerCommentData, setFreelancerCommentData] = useState([]);
  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_webland/cat_freelancer.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setFreelancerCommentData(result);
      })
      .catch((error) => console.log("listInfoData Error => ", error));
  }, [id]);
  console.log("freelancerCommentData-:", freelancerCommentData);
  return (
    <Box>
      {freelancerCommentData.length > 0 && (
        <FreelancerCommentItem
          data={freelancerCommentData[0]}
          order={0}
          align="flex-start"
          textAlign="left"
        />
      )}
      {freelancerCommentData.length > 1 && (
        <FreelancerCommentItem
          data={freelancerCommentData[1]}
          order={1}
          align="flex-end"
          textAlign="right"
        />
      )}
    </Box>
  );
};

export default FreelancerComment;
