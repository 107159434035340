import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { nagadApi } from "../API/Api";
import "./PaymentMethod.css";

const PaymentMethod = (props) => {
  const navigate = useNavigate();
  const { gatewayCharge, handleGatewayCharge, service_refer, grandTotal } =
    props;
  console.log("grandTotal:", grandTotal);
  const [activeTab, setActiveTab] = useState("");
  const [paymentType, setPaymentType] = useState(0);
  const [paymentApi, setPaymentApi] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [organization, setOrganization] = useState("ghorami");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    let x = Math.floor(Math.random() * 1000 + 1);
    let orderId = x + service_refer;
    setOrderId(orderId);
    setPayAmount(grandTotal);
  }, [service_refer, grandTotal]);

  const handlePay = () => {
    const formData = new FormData();
    formData.append("pamount", "105");
    formData.append("organization", "ghoir");
    formData.append("order_id", "sd1254");
    console.log("orderId:", orderId);
    console.log("organization:", organization);
    console.log("payAmount:", payAmount);

    // .catch((error) => console.log("FetchUserApiError=> ", error));
    fetch(nagadApi, {
      method: "POST",
      body: formData,
    }).then((res) => console.log(res.json()));
    // .then((data) => console.log("finally getting data ", data));

    // axios
    //   .post("https://yeapbe.com/ghpay/nagad/index.php", {
    //     method: "POST",
    //     body: formData,
    //   })
    //   .then((res) => res.json())
    //   .then((result) => {
    //     // setPayAmount(result.p_phone);
    //     // setOrganization(result.p_email);
    //     // setOrderId(result.p_nameS);
    //     console.log("resultc:", result);
    //   });
    //   .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleOpen = () => {
    setOpen(true);
    if (!activeTab) {
      setAlert(1);
    } else {
      setAlert(0);
      // navigate("/nagad");
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ marginTop: "40px" }}>
      <Paper sx={{}} elevation={5}>
        {alert === 1 && (
          <Alert
            severity="warning"
            onClose={() => {
              setAlert(0);
            }}
            sx={{ marginBottom: "5px", marginTop: "-6%" }}
          >
            Must choose a payment method
          </Alert>
        )}
        <Grid
          container
          rowSpacing={0}
          columnSpacing={0}
          sx={{ paddingTop: "0px" }}
        >
          <Grid item xs={4}>
            <Stack spacing={2} justifyContent="center" alignItems="flex-start">
              <Stack sx={{ paddingTop: "15px" }}>
                <Stack
                  spacing={1}
                  justifyContent="center"
                  alignItems={"center"}
                  className="listContainer"
                >
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    className={paymentType === 0 ? "selected" : ""}
                    size="small"
                    onClick={() => {
                      setPaymentType(0);
                    }}
                  >
                    Mobile Wallet
                  </Button>
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    size="small"
                    onClick={() => {
                      setPaymentType(1);
                    }}
                    className={paymentType === 1 ? "selected" : ""}
                  >
                    Credit / Debit Card
                  </Button>

                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    className={paymentType === 2 ? "selected" : ""}
                    size="small"
                    onClick={() => {
                      setPaymentType(2);
                    }}
                  >
                    Net Banking
                  </Button>

                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    size="small"
                    className={paymentType === 3 ? "selected" : ""}
                    onClick={() => {
                      setPaymentType(3);
                    }}
                  >
                    Cash on
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Box>
              {paymentType === 0 && (
                <Paper
                  sx={{
                    backgroundColor: "#E0E8BF",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      width: "100%",
                      height: "25vh",
                      backgroundColor: "#E0E8BF",
                      "&:hover": {},
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {gatewayCharge.map((element) => (
                      <Box
                        sx={{
                          //   padding: "0 12px",
                          width: "30%",
                          "&:hover": {
                            transform: "TranslateY(-5px)",
                            transition: "all .5s",
                          },
                        }}
                        className={
                          activeTab === element.type
                            ? "selectedPaymentGateway"
                            : ""
                        }
                      >
                        <Box
                          component="img"
                          sx={{
                            //   padding: "0 12px",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setActiveTab(element.type);
                            setPaymentApi(element.api);
                            handleGatewayCharge(element.charge);
                          }}
                          alt={element.type}
                          src={element.pic}
                        />
                      </Box>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingBottom: "20px" }}
                  >
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "#009C8B" }}
                      onClick={() => {
                        // handleOpen();
                        handlePay();
                      }}
                      // href={paymentApi}
                    >
                      Pay Now
                    </Button>
                  </Stack>
                </Paper>
              )}
              {paymentType === 1 && (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
                  justifyContent="left"
                  alignItems="center"
                >
                  Debit & Credit Card
                </Stack>
              )}
              {paymentType === 2 && (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
                  justifyContent="left"
                  alignItems="center"
                >
                  NET BANKING
                </Stack>
              )}
              {paymentType === 3 && (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
                  justifyContent="left"
                  alignItems="center"
                >
                  CASH ON
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PaymentMethod;
