import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { catListInfoApi } from "../API/Api";
import "./ExpertStories.css";
import { useNavigate } from "react-router-dom";

const ExpertStories = ({ id }) => {
  const navigate = useNavigate();
  const [showData, setShowData] = useState("");
  const [found, setFound] = useState({});
  console.log("listInfoDatafound:", found);
  const [listInfoData, setlistInfoData] = useState([]);
  console.log("listInfoData:", listInfoData);
  //   let found = [];

  useEffect(() => {
    setlistInfoData([]);
    fetch(
      `https://ghorami.com/profile/login/api_webland/cat_listInfo.php?eid=${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setlistInfoData(result);
        setFound(result[0]);
      })
      .catch((error) => console.log("listInfoData Error => ", error));
  }, [id]);
  console.log("listInfoData:", listInfoData);

  useEffect(() => {
    let ff = listInfoData.find((element) => element.refer === showData);
    setFound(ff);
  }, [showData]);
  console.log("found:", found);

  return (
    <Box sx={{ paddingBottom: "40px" }}>
      <Stack justifyContent="center" alignItems="flex-start">
        <Typography variant="p" sx={{ fontWeight: 600, fontSize: "26pt" }}>
          {found?.title}
        </Typography>
        <Typography variant="p" sx={{ fontWeight: 600, fontSize: "13pt" }}>
          {found?.info}
        </Typography>
      </Stack>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          bgcolor: "white",
          marginTop: 3,
          marginBottom: 3,
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          sx={{ paddingTop: "0px" }}
        >
          <Grid item xs={3}>
            <Stack spacing={2} justifyContent="center" alignItems="flex-start">
              <Stack sx={{ paddingTop: "15px" }}>
                {listInfoData?.map((element) => (
                  <Stack
                    // direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                    className="listContainer"
                  >
                    {element.refer === found.refer ? (
                      <Typography
                        key={element.refer}
                        variant="p"
                        sx={{
                          fontSize: "12pt",
                          fontWeight: 600,
                          paddingLeft: 3,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowData(element.refer);
                        }}
                      >
                        | {element?.type}
                      </Typography>
                    ) : (
                      <Typography
                        key={element.refer}
                        variant="p"
                        sx={{
                          fontSize: "12pt",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowData(element.refer);
                        }}
                      ></Typography>
                    )}
                    <Typography
                      className="listItem"
                      key={element.refer}
                      variant="p"
                      sx={{
                        fontSize: "12pt",
                        fontWeight: 600,
                        paddingLeft: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowData(element.refer);
                      }}
                    >
                      {element?.subcat}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    marginLeft: "30px",
                    fontSize: "10pt",
                    textTransform: "capitalize",
                    background: "green",
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    navigate("/servicelist/" + id);
                  }}
                >
                  Browse Service
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ height: "50vh" }}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                  // display: { xs: "none", sm: "none", md: "block" },
                }}
                alt="Service"
                src={found?.banner_pic}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ExpertStories;
