import {
  Badge,
  Box,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchedPublicProfileDetailsApi,
  fetchedReviewApi,
  freelancerApi,
} from "../API/Api";
import useChatBot from "../Custom Hooks/useChatBot";
import Review from "../ServiceList Components/ServiceDetails/Review/Review";
import Reviewer from "../ServiceList Components/ServiceDetails/Reviewer/Reviewer";
import FloatingContactMe from "./ChatBot/FloatingContactMe";
import FloatingInfo from "./FloatingInfo";
import Accounts from "./LeftComponents/Accounts";
import BasicInfo from "./LeftComponents/BasicInfo";
import OfficeLocation from "./LeftComponents/OfficeLocation";
import PageReach from "./LeftComponents/PageReach";
import Schedule from "./LeftComponents/Schedule";
import Skills from "./LeftComponents/Skills";
import ProfileCard from "./ProfileCard";
import Jobs from "./RightComponents/Jobs";
import Offers from "./RightComponents/Offers";
import Portfolios from "./RightComponents/Portfolios";
import Services from "./RightComponents/Services";

const ProfileAg = (props) => {
  const profileRef = useRef(null);
  const { sopnoId } = useParams();
  const [profileDetails, setProfileDetails] = useState({});
  const [userServiceList, setUserServiceList] = useState([]);
  const [userOfferList, setUserOfferList] = useState([]);
  console.log("userOfferList:", userOfferList);
  const [portfolios, setPortfolios] = useState([]);
  const [userJobList, setUserJobList] = useState([]);
  const [userSchedule, setUserSchedule] = useState([]);
  const [fetchedReview, setFetchedReview] = useState([]);
  console.log("consoldsadasdae:", fetchedReview);

  const handleGeneratePdf = () => {
    profileRef.current.save();
  };

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_freelancer/expert_profile_public.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setProfileDetails(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(
      `https://ghorami.com/profile/login/api_service/expert_service_list.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setUserServiceList(result.slice(0, 6));
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(
      `https://ghorami.com/profile/login/api_freelancer/expert_portfolio_list.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setPortfolios(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(
      `https://ghorami.com/profile/login/api_freelancer/expert_job_list.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setUserJobList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(
      `https://ghorami.com/profile/login/api_freelancer/expert_schedule.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setUserSchedule(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(
      `https://ghorami.com/profile/login/api_service/expert_offer_list.php?c_refer=${sopnoId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setUserOfferList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [sopnoId]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page_refer", sopnoId);
    formData.append("action", "fetch");

    fetch(`https://ghorami.com/profile/ac_rating/review_fetch_agency.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchedReview(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [sopnoId]);

  return (
    <PDFExport
      paperSize="A4"
      margin="0.5cm"
      scale={0.4}
      fileName={profileDetails.cname}
      ref={profileRef}
    >
      <Box>
        {useChatBot(profileDetails.profile_pic, profileDetails.cname, sopnoId)}
        <ProfileCard
          profileDetails={profileDetails}
          fetchedReview={fetchedReview}
        />
        <Container sx={{ marginTop: "-60px", zIndex: 5 }}>
          <FloatingInfo
            profileDetails={profileDetails}
            handleGeneratePdf={handleGeneratePdf}
          />
        </Container>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3.5}>
              <Stack spacing={3}>
                <PageReach profileDetails={profileDetails} />
                <Accounts profileDetails={profileDetails} />
                <BasicInfo profileDetails={profileDetails} />
                <OfficeLocation profileDetails={profileDetails} />
                <Schedule userSchedule={userSchedule} />
                <Skills profileDetails={profileDetails} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Stack spacing={5}>
                {userServiceList[0]?.service_refer && (
                  <Services serviceListData={userServiceList} />
                )}
                {userOfferList[0]?.service_refer && (
                  <Offers serviceListData={userOfferList} />
                )}
                {portfolios[0]?.pic_object[0]?.file_url && (
                  <Portfolios portfolio={portfolios} />
                )}
                {userJobList?.job_refer && <Jobs jobListData={userJobList} />}

                {fetchedReview?.average_rating && (
                  <Review fetchedReview={fetchedReview} />
                )}
                {fetchedReview.review_data?.map(
                  (element) =>
                    element.type === "service" && (
                      <Reviewer
                        refer={sopnoId}
                        fetchedReviewItem={element}
                        fetchedReviewList={fetchedReview.review_data}
                      />
                    )
                )}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </PDFExport>
  );
};

export default ProfileAg;
