import { Box } from "@mui/system";
import React from "react";
import FloatingContactMe from "../Profile Components/ChatBot/FloatingContactMe";

const useChatBot = (pic, name, urlId, chatOpen, setChatOpen) => {
  return (
    <Box>
      <FloatingContactMe
        pic={pic}
        name={name}
        urlId={urlId}
        chatOpen={chatOpen}
        setChatOpen={setChatOpen}
      />
    </Box>
  );
};

export default useChatBot;
