import { Circle } from "@mui/icons-material";
import { Box, Button, Card, Rating, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../../../../images/profile.jpg";
import { fetchedReviewApi } from "../../../API/Api";
import { useNavigate } from "react-router-dom";

const Reviewer = (props) => {
  const navigate = useNavigate();

  const { fetchedReviewItem, refer, fetchedReviewList } = props;
  const {
    datetime,
    id,
    rating,
    type,
    user_name,
    user_pic,
    user_review,
    poster,
  } = fetchedReviewItem;
  const [idReview, setReviewId] = useState(id);
  console.log("fetchedReviewItem222:", fetchedReviewItem);
  console.log("idddddd:", id);
  //   const [reply, setReply] = useState(id);
  //   console.log("setReply:", setReply);

  let found = fetchedReviewList?.filter((element) => element.refer == idReview);
  console.log("replyfound:", found.length);
  console.log("replyfound:", type);

  return (
    <Card sx={{ padding: "20px" }}>
      {found && type !== "replay" && (
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent="left"
          alignItems={"flex-start"}
        >
          <Box>
            <Box
              component="img"
              sx={{
                width: 60,
                height: 60,
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
                marginTop: "10px",

                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={user_pic}
              onClick={() => {
                navigate("/profiledetails/" + poster);
              }}
            />
          </Box>
          <Stack
            spacing={0}
            justifyContent="left"
            alignItems={"flex-start"}
            sx={{ padding: "5px", borderRadius: "8px", marginLeft: "-10px" }}
          >
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                {user_name}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              spacing={1}
              justifyContent="left"
              alignItems={"center"}
              sx={{ flexWarp: "wrap" }}
            ></Stack>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Rating
                name="read-only"
                value={parseFloat(rating)}
                readOnly
                sx={{ fontSize: "13pt" }}
              />
              <Box
                sx={{
                  paddingX: "9px",
                  paddingY: "1px",
                  bgcolor: "#e2efe2",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ color: "#09b909", fontWeight: 600 }}
                >
                  {rating}
                </Typography>
              </Box>
              <Typography
                variant="p"
                sx={{ fontSize: "18pt", fontWeight: 400 }}
              >
                |
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 500 }}>
                {datetime}
              </Typography>
            </Stack>
            <Typography variant="p" sx={{ fontWeight: 500 }}>
              {user_review}
            </Typography>
            {/* <Button
            variant="outlined"
            size="small"
            sx={{
              color: "black",
              border: "1px solid black",
              fontSize: "11pt",
              textTransform: "capitalize",
            }}
          >
            {" "}
            Reply
          </Button> */}
          </Stack>
        </Stack>
      )}
      {found?.map((element) =>
        element?.type === "replay" && found.length > 0 ? (
          <Stack
            mt={2}
            direction={"row"}
            justifyContent="left"
            alignItems={"center"}
          >
            <Box sx={{ width: "10%" }}></Box>
            <Box sx={{ width: "90%" }}>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                {console.log("dhukse map e")}
                <Box>
                  <Box
                    component="img"
                    sx={{
                      width: 40,
                      height: 40,
                      objectFit: "cover",
                      cursor: "pointer",
                      borderRadius: "50%",
                      marginTop: "10px",

                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt="The house from the offer."
                    src={element?.user_pic}
                    onClick={() => {
                      navigate("/profiledetails/" + element?.poster);
                    }}
                  />
                </Box>
                <Stack
                  spacing={0}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  sx={{
                    // bgcolor: "#ebe8e8",
                    padding: "10px",
                    borderRadius: "8px",
                    marginLeft: "-15px",
                  }}
                >
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontSize: "13pt", fontWeight: 700 }}
                    >
                      {element.user_name}
                    </Typography>
                  </Stack>

                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent="left"
                    alignItems={"center"}
                    sx={{ flexWarp: "wrap" }}
                  ></Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Rating
                      name="read-only"
                      value={parseFloat(element.rating)}
                      readOnly
                      sx={{ fontSize: "13pt" }}
                    />
                    <Box
                      sx={{
                        paddingX: "9px",
                        paddingY: "1px",
                        bgcolor: "#e2efe2",
                        borderRadius: "6px",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ color: "#09b909", fontWeight: 600 }}
                      >
                        {element.rating}
                      </Typography>
                    </Box>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "18pt", fontWeight: 400 }}
                    >
                      |
                    </Typography>
                    <Typography variant="p" sx={{ fontWeight: 500 }}>
                      {element.datetime}
                    </Typography>
                  </Stack>
                  <Typography variant="p" sx={{ fontWeight: 500 }}>
                    {element.user_review}
                  </Typography>
                  {/* <Button
              variant="outlined"
              size="small"
              sx={{
                color: "black",
                border: "1px solid black",
                fontSize: "11pt",
                textTransform: "capitalize",
              }}
            >
              {" "}
              Reply
            </Button> */}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        ) : (
          "f"
        )
      )}
    </Card>
  );
};

export default Reviewer;
