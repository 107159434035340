import { Call, FileDownload, Language } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import playstorelogo from "../../../images/playStore.png";
import appstorelogo from "../../../images/appStore.png";
import logo from "../../../images/ghorami.ico";

const AppDownload = () => {
  return (
    <Box sx={{ paddingBottom: "40px" }}>
      <Paper sx={{ padding: "50px", paddingBottom: "10px" }} elevation={0}>
        <Grid
          container
          rowSpacing={{ xs: 4, md: 2 }}
          spacing={0}
          sx={{ marginLeft: "50px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                // sx={{ height: "150px" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <Paper
                    sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <Call
                        sx={{
                          fontSize: "25pt",
                          color: "#3ecfcb",
                        }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{}}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "15pt", fontWeight: 700 }}
                  >
                    Whatsapp
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 500, color: "gray" }}
                  >
                    01849-225187
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                // sx={{ height: "150px" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    // navigate("/ghoramidesk");
                    window.location("https://app.ghorami.com/");
                  }}
                >
                  <Paper
                    sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          // padding: "0 12px",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        alt="gDesk"
                        src={logo}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{}}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "15pt", fontWeight: 700 }}
                  >
                    Ghorami Desk
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 500, color: "gray" }}
                  >
                    Ultimate Solution Of Team Work
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Stack
                // direction={"row"}
                justifyContent="center"
                alignItems={{ xs: "center", md: "left" }}
                sx={
                  {
                    // marginTop: { xs: "0px", md: "-20px" },
                    // marginLeft: { xs: "-120px", md: "0" },
                    // height: "150px",
                  }
                }
                onClick={() => {
                  // navigate("/ghoramidesk");
                  window.location("https://app.ghorami.com/");
                }}
              >
                <Button variant="contained" endIcon={<FileDownload />}>
                  Free Download
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AppDownload;
