import { Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import { topClientApi } from "../../API/Api";
import ClientItem from "./ClientItem";

const TopClientConnect = () => {
  const navigate = useNavigate();

  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    fetch(topClientApi)
      .then((res) => res.json())
      .then((result) => {
        setClientData(result);
      })
      .catch((error) => {
        console.log("fetch Failed ", error);
      });
  }, []);
  console.log("clientData:", clientData);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    // draggable: false,
    // arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container sx={{ mt: "40px" }}>
      <Slider {...settings}>
        {clientData?.map((element) => (
          <Box key={element.refer} sx={{}}>
            <ClientItem clientData={element} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default TopClientConnect;
