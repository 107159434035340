import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { blogApi } from "../../API/Api";
import BlogItem from "./BlogItem";
import BlogNewsItem from "./BlogNewsItem";

const LatestBlogNews = () => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetch(
      `https://ghorami.com/profile/login/api_spForum/fun_blog.php?requ_type=allTopBlog`
    )
      .then((res) => res.json())
      .then((result) => {
        setBlogData(result);
      })
      .catch((error) => console.log("BlogData Error => ", error));
  }, []);

  return (
    <Box mt={5}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ paddingX: "15px" }}
      >
        <Typography variant="p" sx={{ fontWeight: 700, fontSize: "29pt" }}>
          Latest Blog News
        </Typography>
        <Typography
          variant="p"
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          Trends and what are future techs to be master in
        </Typography>
      </Stack>
      <Container>
        <Grid container spacing={4} mt={1} mb={4}>
          {blogData.map((element) => (
            <Grid item xs={12} sm={6} md={4} key={element.pic}>
              <BlogItem blogData={element} key={element.pic} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Stack direction={"row"} justifyContent="center" alignItems={"center"}>
        <Button
          variant="outlined"
          color="green"
          sx={{
            fontFamily: "Roboto Condensed",
            fontWeight: 400,
            fontSize: "23pt",
            cursor: "pointer",
            // color: "black",
            // borderColor: "black",
            textAlign: "center",
          }}
          onClick={() => {
            navigate("/bloglist");
          }}
        >
          Load more...
        </Button>
      </Stack>
    </Box>
  );
};

export default LatestBlogNews;
