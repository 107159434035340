import { Facebook, Language, LinkedIn, YouTube } from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Schedule = (props) => {
  const { userSchedule } = props;
  console.log("userSchedule:", userSchedule);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Paper
      // elevation={2}
      sx={{ padding: "30px", marginTop: "5px", border: "1px solid #dfdddd" }}
    >
      <Stack spacing={1} justifyContent={"left"} alignItems={"flex-start"}>
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: "600" }}>
          Open Schedule
        </Typography>

        <Grid container spacing={0} sx={{ borderTop: "1px solid #dfdddd" }}>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "12pt", fontWeight: "600", marginLeft: "12px" }}
            >
              #
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "12pt", fontWeight: "600" }}
            >
              Day
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "12pt", fontWeight: "600" }}
            >
              Open
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "12pt", fontWeight: "600" }}
            >
              Close
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0} sx={{ borderTop: "2px solid #dfdddd" }}>
          <Grid item xs={3}>
            <Checkbox
              {...label}
              defaultChecked
              size="small"
              sx={{ marginLeft: "", marginTop: "-5px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: "500" }}
            >
              Friday
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: "500" }}
            >
              2.00am
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: "500" }}
            >
              5.00pm
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default Schedule;
