import { Container } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftPic from "../../../images/Ghorami home/cm-16.png";
import Slider from "react-slick";
import RightContent from "./RightContent";
import { useEffect } from "react";
import { useState } from "react";
import { testimonialApi } from "../../API/Api";

const Testimonial = () => {
  const [testimonialData, setTestimonialData] = useState([]);

  useEffect(() => {
    fetch(testimonialApi)
      .then((res) => res.json())
      .then((result) => {
        setTestimonialData(result);
      })
      .catch((error) => console.log("testimonial error => ", error));
  }, []);
  console.log("testimonialData:", testimonialData);

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Box sx={{ bgcolor: "#040610", mt: "0px" }}>
      <Stack direction="row" justifyContent="left" alignItems="center">
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{
            width: { xs: "0%", md: "50%" },
          }}
        >
          <Box
            component="img"
            sx={{
              paddingTop: "35px",
              paddingBottom: "40px",
              width: "70%",
              display: { xs: "none", sm: "none", md: "block", lg: "block" },

              //   display: { xs: "none", sm: "none", md: "block" },
            }}
            alt="The house from the offer."
            src={leftPic}
          />
        </Stack>
        <Box
          sx={{
            width: { xs: "85%", md: "50%" },
            marginLeft: { xs: "10%", md: "-40px" },
            // paddingLeft: { xs: "10%", md: 0 },
            // paddingRight: { xs: "10%", md: 0 },
          }}
        >
          <Slider {...settings}>
            {testimonialData.map((element) => (
              <Box key={element.refer}>
                <RightContent testimonialData={element} />
              </Box>
            ))}

            {/* <Box>
              <RightContent />
            </Box>
            <Box>
              <RightContent />
            </Box> */}
          </Slider>
        </Box>
      </Stack>
    </Box>
  );
};

export default Testimonial;
