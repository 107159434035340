import {
  AccessTime,
  Badge,
  CheckCircleRounded,
  Engineering,
  Favorite,
  LocationOn,
  Psychology,
  Schedule,
  WorkHistory,
  WatchLater,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import JobDetailsModal from "../../JobList Components/JobDetailsModal";
import "./Job.css";

const Job = (props) => {
  const navigate = useNavigate();
  const { jobData } = props;
  // console.log("joblISTData:", jobData);
  const {
    job_budget,
    job_title,
    job_category,
    job_currency,
    job_expertlevel,
    job_freelancer,
    job_hour,
    job_id,
    job_info,
    poster_locat,
    job_pay_type,
    job_poster,
    job_poster_pic,
    job_poster_name,
    job_refer,
    job_schedule,
    job_skill,
    job_state,
    job_time,
    job_type,
    job_visibility,
    poster_status,
  } = jobData;
  console.log("jobData:", jobData);
  const skill = JSON.parse(job_skill?.split(", "));

  const [jobId, setJobId] = useState("");
  const [jobRefer, setJobRefer] = useState("");
  const [poid, setPoid] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  console.log("modalOpen:", modalOpen);

  const handleModalOpen = (refer, id, poid) => {
    setJobId(id);
    setJobRefer(refer);
    setPoid(poid);
    setModalOpen(!modalOpen);
  };
  return (
    <Paper
      elevation={3}
      onClick={() => {
        handleModalOpen(job_refer, job_id, job_poster);
        // handleModalOpen();
      }}
      sx={{
        padding: "5px",
        mt: "15px",
        cursor: "pointer",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Grid
        container
        spacing={0}
        rowSpacing={1}
        sx={{ padding: { md: "20px 10px" } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Stack spacing={1} sx={{ width: "90%" }}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14pt", fontWeight: 600 }}
                  >
                    {job_title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    {job_currency} {job_budget}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <Psychology style={{ fontSize: "14pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_expertlevel}
                    </Typography>
                  </Stack>
                  {/* <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "12pt", ml: "4px" }}
                  >
                    {job_currency} {job_budget}
                  </Typography> */}
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <WatchLater style={{ fontSize: "13pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_time}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {/* {job_info + "..."} */}
                  {job_info && job_info?.slice(0, 300) + "..."}
                </Typography>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {skill?.map((element) => (
                    <Button
                      sx={{
                        fontSize: "8pt",
                        color: "white",
                        bgcolor: "darkgreen",
                        modalOpenTransform: "capitalize",
                        paddingX: "10px",
                        paddingY: "0px",
                        borderRadius: "30px",
                        "&:hover": {
                          bgcolor: "#22AB7D",
                          color: "white",
                        },
                      }}
                      onClick={() => {
                        navigate("/joblist");
                      }}
                      size="small"
                      key={element}
                    >
                      {element}
                    </Button>
                  ))}
                </Stack>
                <Stack
                  spacing={0}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  {poster_status == "1" ? (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "darkgreen" }}
                    />
                  ) : (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "gray" }}
                    />
                  )}

                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {job_poster_name}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    ||
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {poster_locat}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={4}>
          <Divider
            sx={{
              mb: "14px",
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
          />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={{ xs: "center", md: "center" }}
            sx={{ height: "100%" }}
          >
            <Stack spacing={1}>
              <Stack
                spacing={1}
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 600 }}
                >
                  {job_currency} {job_budget}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid> */}
      </Grid>
      <JobDetailsModal
        jobId={jobId}
        jobRefer={jobRefer}
        poid={poid}
        modalOpen={modalOpen}
        handleModalOpen={handleModalOpen}
        // style={style}
      />
    </Paper>
  );
};

export default Job;
