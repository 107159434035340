import { Avatar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

const RightContent = (props) => {
  const navigate = useNavigate();
  const { testimonialData } = props;
  const {
    background_c,
    info,
    info_c,
    posterInfo,
    posterName,
    postertitle_c,
    poster_pic,
    refer,
    title,
    subtitle,
    title_c,
  } = testimonialData;
  return (
    <Stack
      spacing={3}
      direction={{ xs: "column", sm: "row" }}
      justifyContent="left"
      alignItems={{ xs: "flex-start", sm: "center" }}
      sx={{ bgcolor: "rgba(0, 0, 0, 0.1)", height: "65vh", padding: "10px" }}
    >
      <Avatar
        alt={posterName}
        src={poster_pic}
        sx={{
          width: { xs: 90, md: 60, lg: 90 },
          height: { xs: 90, md: 60, lg: 90 },
        }}
      />
      <Stack spacing={2} justifyContent="center">
        <Stack>
          <Typography
            variant="p"
            sx={{ color: "white", fontSize: "13pt", fontWeight: 600 }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="p"
            sx={{ color: title_c, fontSize: "22pt", fontWeight: 700 }}
          >
            {title}
          </Typography>
        </Stack>
        <Box>
          <Typography
            variant="p"
            sx={{
              color: info_c,
              fontSize: "13pt",
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            {info}
          </Typography>
        </Box>
        <Stack>
          <Typography
            variant="p"
            sx={{ color: postertitle_c, fontSize: "14pt", fontWeight: 600 }}
          >
            {posterName}
          </Typography>
          <Typography
            variant="p"
            sx={{ color: "white", fontSize: "12pt", fontWeight: 600 }}
          >
            {posterInfo}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RightContent;
