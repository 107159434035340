import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Agency.css";
import { Box } from "@mui/system";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { agencyApi } from "../../API/Api";
import { useNavigate } from "react-router-dom";

const Agency = () => {
  const navigate = useNavigate();
  const [agencyData, setAgencyData] = useState([]);

  React.useEffect(() => {
    fetch(agencyApi)
      .then((res) => res.json())
      .then((result) => {
        setAgencyData(result);
      });
  }, []);
  console.log("agencyData:", agencyData);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 24000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Box sx={{ padding: "20px", pt: "20px" }}>
      <Grid container spacing={2} sx={{}}>
        {agencyData.map((element) => (
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <Box
              key={element.crefer}
              sx={{ position: "relative" }}
              className="agency"
            >
              <Stack
                // className="a1"
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "15vh",
                  width: "100%",
                  border: "2px solid #22AB7D",
                  borderRadius: "20px",
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40%",
                    height: "100%",
                    // bgcolor: "white",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "70%",
                      borderRadius: "50%",
                      // padding: "5%",

                      // maxHeight: { xs: 233, md: 167 },
                      // maxWidth: { xs: 340, md: 240 },
                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt="The house from the offer."
                    src={element.clogo}
                  />
                </Stack>
                <Stack
                  // spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "130%",
                    marginLeft: "-10px",
                    // bgcolor: "white",
                    zIndex: -1,
                    padding: "10px",
                    color: "black",
                  }}
                >
                  <Stack alignItems="flex-left" justifyContent={"center"}>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 300,
                        color: "black",
                      }}
                    >
                      {element.cname.slice(0, 15) + "..."}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "9pt", color: "black" }}
                    >
                      {element.cslogan.slice(0, 22) + "..."}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Box
                className="a1"
                sx={{
                  height: "0%",
                  width: "100%",
                  bgcolor: "rgba(0,0,0,1)",
                  position: "absolute",
                  overflow: "hidden",
                  top: "100%",
                  opacity: 1,
                  borderRadius: "25px",
                }}
              ></Box>

              <Typography
                className="a2"
                variant="p"
                sx={{
                  position: "absolute",
                  fontSize: "10pt",
                  top: "15%",
                  left: "5%",
                  color: "white",
                  opacity: 0,
                }}
              >
                {element.cslogan}
              </Typography>

              <Button
                className="a3"
                variant="conatined"
                sx={{
                  position: "absolute",
                  top: "60%",
                  left: "5%",
                  fontSize: "8pt",
                  opacity: 0,
                  textTransform: "capitalize",
                  color: "white",
                  bgcolor: "#22AB7D",
                  "&:hover": {
                    bgcolor: "#14506A",
                    color: "white",
                  },
                }}
                onClick={() => {
                  navigate("/profile/" + element.crefer);
                }}
              >
                See Profile
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        sx={{ padding: "20px", pt: "20px" }}
      >
        <Button
          variant="outlined"
          color="green"
          sx={{
            fontFamily: "Roboto Condensed",
            fontWeight: 400,
            fontSize: "23pt",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => {
            navigate("/agentlist");
          }}
        >
          Load more...
        </Button>
      </Stack>
    </Box>
  );
};

export default Agency;
