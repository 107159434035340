import {
  CheckCircleRounded,
  Facebook,
  Message,
  MoreVert,
  Star,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Agency.css";

// const decodeLocalData = (name) => {
//   let result = atob(window.localStorage.getItem(name));
//   return JSON.parse(result);
// };
const AgencyCardItem = (props) => {
  const navigate = useNavigate();
  const { agencyData } = props;
  console.log("freelancerdfsdfsfData:", agencyData);
  const {
    category,
    poster,
    sub_category,
    cname,
    crefer,
    cslogan,
    clogo,
    cinfo,
  } = agencyData;
  let active_state = 0;
  let user_state = 0;
  let user_rate = 0;

  return (
    <Card
      sx={{
        cursor: "pointer",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
      onClick={() => {
        navigate("/profile/" + crefer);
      }}
    >
      <Box
        sx={{
          bgcolor: "#F3B154",
          width: "100%",
          height: "10vh",
        }}
      ></Box>
      <CardContent sx={{ mt: "-55px" }}>
        <Box>
          <Stack spacing={2} justifyContent="left" alignItems="center">
            {active_state === 1 ? (
              <StyledBadge badgeContent={""} color="success" variant="dot">
                <Avatar
                  alt="Remy Sharp"
                  src={clogo}
                  sx={{ width: 70, height: 70, border: "4px solid white" }}
                />
              </StyledBadge>
            ) : (
              <StyledBadge badgeContent={""} color="grey" variant="dot">
                <Avatar
                  alt="Remy Sharp"
                  src={clogo}
                  sx={{ width: 70, height: 70, border: "4px solid white" }}
                />
              </StyledBadge>
            )}

            <Stack spacing={7} justifyContent="center" alignItems="center">
              <Box sx={{ height: "42px" }}>
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  {user_state === 1 ? (
                    <Box
                      className="verificationContainer"
                      sx={{ cursor: "pointer", position: "relative" }}
                    >
                      <CheckCircleRounded
                        color="success"
                        sx={{
                          fontSize: "13pt !important",
                          fontWeight: 700,
                          marginTop: "5px",
                        }}
                      />

                      <Stack
                        direction={"row"}
                        justifyContent="center"
                        alignItems="flex-start"
                        className="verification"
                        sx={{
                          position: "absolute",
                          top: "-70%",
                          left: "-110%",
                          opacity: 0,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "9pt",
                            fontWeight: 600,
                            color: "white",
                            bgcolor: "#8CC986",
                            ml: "-5px",
                            px: "10px",
                            py: 0,
                            borderRadius: "8px",
                          }}
                        >
                          Verified
                        </Typography>
                      </Stack>
                    </Box>
                  ) : (
                    <Box
                      className="verificationContainer"
                      sx={{ cursor: "pointer", position: "relative" }}
                    >
                      <CheckCircleRounded
                        color="grey"
                        sx={{
                          fontSize: "11pt",
                          fontWeight: 700,
                          marginTop: "5px",
                        }}
                      />

                      <Stack
                        direction={"row"}
                        justifyContent="center"
                        alignItems="flex-start"
                        className="verification"
                        sx={{
                          position: "absolute",
                          top: "-70%",
                          left: "-150%",
                          opacity: 0,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "9pt",
                            fontWeight: 600,
                            color: "white",
                            bgcolor: "gray",
                            ml: "-5px",
                            px: "10px",
                            py: 0,
                            borderRadius: "8px",
                          }}
                        >
                          Unverified
                        </Typography>
                      </Stack>
                    </Box>
                  )}

                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "12pt",
                      fontWeight: 600,
                      ml: "3px",
                    }}
                  >
                    {cname?.slice(0, 20)}
                    {cname?.length >= 20 && "..."}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="p"
                    sx={{ textAlign: "center", fontSize: "11pt" }}
                  >
                    {cslogan?.slice(0, 40)}
                    {cslogan?.length >= 40 && "..."}
                  </Typography>
                </Stack>
                {/* <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="p"
                    sx={{ textAlign: "center", fontSize: "10pt" }}
                  >
                    {cslogan && cslogan}
                  </Typography>
                </Stack> */}
              </Box>
              <Stack
                spacing={0}
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Typography
                  variant="p"
                  color="white"
                  sx={{
                    ml: "2px",
                    fontSize: { xs: "7pt", md: "10pt" },
                    fontWeight: 600,
                    bgcolor: "#FDBD45",
                    padding: "0 5px",
                  }}
                >
                  {user_rate}
                </Typography>
                <Rating
                  name="read-only"
                  value={user_rate}
                  readOnly
                  sx={{ fontSize: "14pt" }}
                />
                <Typography
                  variant="p"
                  color="black"
                  sx={{
                    ml: "2px",
                    fontSize: { xs: "7pt", md: "10pt" },
                    fontWeight: 600,
                  }}
                >
                  (0 Reviews)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        {/* <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="flex-start"
          className="verification"
          sx={{ position: "absolute", top: "40%", opacity: 0 }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "8pt",
              fontWeight: 600,
              color: "white",
              bgcolor: "#8CC986",
              ml: "-5px",
              px: "10px",
              py: 0,
              borderRadius: "8px",
            }}
          >
            Varified
          </Typography>
        </Stack> */}
      </CardContent>

      {/* <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginBottom: "20px",
          padding: "20px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "50%",
            bgcolor: "#14506A",
            "&:hover": {
              bgcolor: "#22AB7D",
            },
          }}
          size="small"
          onClick={() => {
            navigate("/" + sopnoId);
          }}
        >
          Profile
        </Button>
        <IconButton aria-label="delete">
          <Message />
        </IconButton>
      </Stack> */}
    </Card>
  );
};

export default AgencyCardItem;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 15,
    top: 68,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
