import { Avatar, Box, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import logo from "../../images/card_design.jpg";

const BooknowTopSection = ({ serviceDetails }) => {
  const {
    company,
    post_time,
    poster,
    pic,
    service_category,
    service_duration,
    service_info,
    poster_pic,
    agency_subtitle,
    price,
    agency_title,
    agency_logo,
    currency,
    jcategory,
    details,
    title,
  } = serviceDetails;
  return (
    <Stack sx={{ height: "73vh" }} justifyContent="center">
      <Stack
        justifyContent="space-between"
        alignItems={"flex-left"}
        spacing={2}
      >
        <Box sx={{ height: "100px" }}>
          <Box
            component="img"
            sx={{
              //   padding: "0 12px",
              height: "100%",
              cursor: "pointer",
              borderRadius: "8px",

              // display: { xs: "none", sm: "none", md: "block" },
            }}
            alt="The house from the offer."
            src={pic}
          />
        </Box>
        <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
          {title}
        </Typography>
        <Stack sx={{ width: "100%" }}>
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
            {jcategory}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "11pt" }}>
            {details}
          </Typography>
        </Stack>
        <Box sx={{ width: "15%" }}>
          <Typography
            variant="p"
            sx={{ fontSize: "13pt", fontWeight: 600, textAlign: "center" }}
          >
            {currency} {price}
          </Typography>
        </Box>
        <Paper sx={{ padding: "15px", width: "80%" }}>
          <Stack
            direction="row"
            justifyContent="left"
            alignItems={"center"}
            spacing={1}
          >
            <Avatar
              src={agency_logo ? agency_logo : poster_pic}
              sx={{ width: "40px", height: "40px" }}
            ></Avatar>
            <Stack>
              <Typography
                variant="p"
                sx={{ fontSize: "11pt", fontWeight: 600 }}
              >
                {agency_title}
              </Typography>
              <Typography variant="p" sx={{ fontSize: "11pt" }}>
                {agency_subtitle}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default BooknowTopSection;
