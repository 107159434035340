import { Circle } from "@mui/icons-material";
import { Box, Button, Card, Rating, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import logo from "../../../../images/profile.jpg";
import { useNavigate } from "react-router-dom";

const AboutSeller = (props) => {
  const navigate = useNavigate();

  const { fetchedServiceDetails, setChatOpen } = props;
  const {
    agency_info,
    agency_state,
    agency_logo,
    poster_pic,
    agency_title,
    agency_subtitle,
    agency_service,
    agency_total_rating,
    agency_rating_state,
    poster,
  } = fetchedServiceDetails;

  let agencyService;
  if (agency_service) {
    agencyService = agency_service.split(", ");
  }
  return (
    <Card sx={{ padding: "20px" }}>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent="left"
        alignItems={"flex-start"}
      >
        <Box>
          <Box
            component="img"
            sx={{
              width: 90,
              height: 90,
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "50%",
              // display: { xs: "none", sm: "none", md: "block" },
            }}
            onClick={() => {
              navigate("/profiledetails/" + poster);
            }}
            alt={agency_title}
            src={agency_logo ? agency_logo : poster_pic}
          />
        </Box>
        <Stack spacing={0.5} justifyContent="left" alignItems={"flex-start"}>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent="left"
            alignItems={"center"}
          >
            <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 700 }}>
              {agency_title}
            </Typography>
            {agency_state == 1 && (
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  paddingX: "5px",
                  paddingy: "5px",
                  border: "1px solid darkgreen",
                  borderRadius: "30px",
                }}
              >
                <Circle sx={{ color: "darkgreen", fontSize: "9pt" }} />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 500,
                    color: "darkgreen",
                    border: '1px solid "darkgreen',
                  }}
                >
                  Online
                </Typography>
              </Stack>
            )}
            {agency_state == 0 && (
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  paddingX: "5px",
                  paddingy: "5px",
                  border: "1px solid gray",
                  borderRadius: "30px",
                }}
              >
                <Circle sx={{ color: "gray", fontSize: "9pt" }} />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 500,
                    color: "gray",
                    border: '1px solid "gray',
                  }}
                >
                  Offline
                </Typography>
              </Stack>
            )}
          </Stack>
          <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 600 }}>
            {agency_subtitle}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 500 }}>
            {agency_info}
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="left"
            alignItems={"center"}
            sx={{ flexWarp: "wrap" }}
          >
            {/* {agencyService?.map((element) => (
              <Button
                variant="contained"
                size="small"
                // startIcon={<FavoriteBorder sx={{ color: "#14506A" }} />}
                sx={{
                  textTransform: "capitalize",
                  color: "darkgreen",
                  bgcolor: "#E2EFE2",
                  // border: "1px solid #14506A",
                  // width: "47%",
                  borderRadius: "30px",
                  paddingY: "0px",
                }}
              >
                {element}
              </Button>
            ))} */}
          </Stack>
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent="left"
            alignItems={"center"}
          >
            <Rating
              name="read-only"
              value={parseFloat(agency_rating_state)}
              readOnly
              sx={{ fontSize: "13pt" }}
            />
            <Box
              sx={{
                paddingX: "9px",
                paddingY: "1px",
                bgcolor: "#e2efe2",
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "#09b909", fontWeight: 600 }}
              >
                {agency_rating_state}
              </Typography>
            </Box>

            <Typography variant="p" sx={{ color: "#09b909", fontWeight: 500 }}>
              ({parseFloat(agency_total_rating)})
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            size="small"
            sx={{
              color: "black",
              border: "1px solid black",
              fontSize: "11pt",
              textTransform: "capitalize",
            }}
            onClick={() => {
              setChatOpen(true);
            }}
          >
            {" "}
            Contact Me
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AboutSeller;
