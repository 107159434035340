import {
  AssignmentTurnedIn,
  CalendarToday,
  Category,
  Facebook,
  Language,
  LanguageOutlined,
  LibraryAddCheck,
  LinkedIn,
  Public,
  YouTube,
} from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const BasicInfo = (props) => {
  const { profileDetails } = props;
  const { language, previous_work, reg_date } = profileDetails;
  return (
    <Paper
      sx={{ padding: "30px", marginTop: "5px", border: "1px solid #dfdddd" }}
    >
      <Stack spacing={1} justifyContent={"left"} alignItems={"flex-start"}>
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: "600" }}>
          Basic Info
        </Typography>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="left"
            alignItems={"center"}
            sx={{ width: "56%" }}
          >
            <CalendarToday sx={{ fontSize: "11pt", color: "#039faf" }} />
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              Career Start
            </Typography>
          </Stack>
          <Typography
            variant="p"
            sx={{ width: "2%", fontSize: "11pt", fontWeight: 600 }}
          >
            :
          </Typography>

          <Typography
            variant="p"
            sx={{
              width: "42%",
              fontSize: "11pt",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {reg_date}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="left"
            alignItems={"center"}
            sx={{ width: "56%" }}
          >
            <Public sx={{ fontSize: "12pt", color: "#039faf" }} />
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              language
            </Typography>
          </Stack>
          <Typography
            variant="p"
            sx={{ width: "2%", fontSize: "11pt", fontWeight: 600 }}
          >
            :
          </Typography>

          <Typography
            variant="p"
            sx={{
              width: "42%",
              fontSize: "11pt",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {language}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="left"
            alignItems={"center"}
            sx={{ width: "56%" }}
          >
            <LibraryAddCheck sx={{ fontSize: "12pt", color: "#039faf" }} />
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              Previous work
            </Typography>
          </Stack>
          <Typography
            variant="p"
            sx={{ width: "2%", fontSize: "11pt", fontWeight: 600 }}
          >
            :
          </Typography>

          <Typography
            variant="p"
            sx={{
              width: "42%",
              fontSize: "11pt",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {previous_work}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BasicInfo;
