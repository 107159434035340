import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "../ServiceDetails.css";

import { Stack } from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ReqNewQuotation = (props) => {
  const { refer } = props;
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Card
      style={{
        paddingX: "10px",
        border: "1px solid #dfdddd",
        // zIndex: -1,
        // marginTop: "380px",
      }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            Request New Quotation
          </Typography>

          <Button
            variant="contained"
            sx={{
              bgcolor: "#22AB7D",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#14506A",
              },
            }}
            onClick={() => {
              navigate("/quotation/" + refer);
            }}
          >
            Request for new
          </Button>
          <Typography variant="p" sx={{ fontSize: "9pt", fontWeight: 500 }}>
            By pressing request info, you agree that Ghorami & Other
            Professionals may contact you via phone/text
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                display: !showDetails ? "block" : "none",
              }}
              onClick={() => {
                setShowDetails(true);
              }}
            >
              read more
            </span>
            <span
              style={{
                cursor: "pointer",
                display: !showDetails ? "none" : "block",
              }}
              onClick={() => {
                setShowDetails(true);
              }}
            >
              about your inquiry, which may involve the use of automated means.
              You are not required to consent as a condition of purchasing any
              property, goods or services. Message/data rates may apply. You
              also agree to our Terms of Use.
            </span>
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                display: showDetails ? "block" : "none",
              }}
              onClick={() => {
                setShowDetails(false);
              }}
            >
              show less
            </span>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReqNewQuotation;
