import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ServiceCardItem from "../../../Homepage Components/Trending Services/ServiceCardItem";

const SimilarProducts = (props) => {
  const { fetchedSimilarService } = props;
  return (
    <Box>
      <Typography
        style={{
          fonSize: "22px",
          fontWeight: "600",
          color: "#212121",
        }}
        variant="h5"
        component="Box"
      >
        Similar Products
      </Typography>
      <Box sx={{ marginTop: "15px" }}>
        <Grid container spacing={2} mt={0} mb={4}>
          {fetchedSimilarService?.map((element) => (
            <Grid item xs={12} sm={6} md={4} key={element.service_refer}>
              <ServiceCardItem data={element} key={element.service_refer} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SimilarProducts;
