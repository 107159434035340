import { Box, Container, Grid, Pagination, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { serviceApi } from "../API/Api";
import FreelancerItem from "../Homepage Components/Freelancer/FreelancerItem";
import Job from "../Homepage Components/Latest Job/Job";
import ServiceCardItem from "../Homepage Components/Trending Services/ServiceCardItem";
import loader from "../../images/loader.gif";

const SearchPage = () => {
  const { text, type } = useParams();
  console.log("10001:", type);
  console.log("10001--:", text);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  console.log("searchedData:", searchedData);
  console.log("searchedData:", "searchedData");

  useEffect(() => {
    if (type === "service") {
      fetch(
        `https://ghorami.com/profile/login/api_search/sear_service.php?keyword=${text}&page=${page}`
      )
        .then((res) => res.json())
        .then((result) => {
          setSearchedData(result);
          if (result.length > 0) {
            setLoading(false);
            setNotFound(false);
          } else {
            setLoading(false);
            setNotFound(true);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
    if (type === "freelancer") {
      fetch(
        `https://ghorami.com/profile/login/api_search/sear_expert.php?keyword=${text}&page=${page}`
      )
        .then((res) => res.json())
        .then((result) => {
          setSearchedData(result);
          if (result.length > 0) {
            setLoading(false);
            setNotFound(false);
          } else {
            setLoading(false);
            setNotFound(true);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
    if (type === "job") {
      fetch(
        `https://ghorami.com/profile/login/api_search/sear_job.php?keyword=${text}&page=${page}`
      )
        .then((res) => res.json())
        .then((result) => {
          setSearchedData(result);
          if (result.length > 0) {
            setLoading(false);
            setNotFound(false);
          } else {
            setLoading(false);
            setNotFound(true);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
    if (type === "agency") {
      fetch(
        `https://ghorami.com/profile/login/api_search/sear_agency.php?keyword=${text}&page=${page}`
      )
        .then((res) => res.json())
        .then((result) => {
          setSearchedData(result);
          if (result.length > 0) {
            setLoading(false);
            setNotFound(false);
          } else {
            setLoading(false);
            setNotFound(true);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
  }, [page, text, type]);
  return (
    <Box>
      <Container sx={{ minHeight: "50vh" }}>
        <Box sx={{ marginTop: "30px" }}>
          <Typography variant="p" sx={{ fontSize: "12pt" }}>
            Search result of {text},
          </Typography>
        </Box>
        <Grid container spacing={2} mt={1}>
          {searchedData?.map((data) => (
            <>
              {type == "service" && (
                <Grid item xs={2} sm={4} md={3} key={data.pro_pic}>
                  <ServiceCardItem data={data} />
                </Grid>
              )}
              {(type == "freelancer" || type == "agency") && (
                <Grid item xs={2} sm={4} md={3} key={data.pro_pic}>
                  <FreelancerItem freelancerData={data} />
                </Grid>
              )}
              {type == "job" && (
                <Grid item xs={2} sm={4} md={12} key={data.pro_pic}>
                  <Job jobData={data} />
                </Grid>
              )}
            </>
          ))}
        </Grid>
        {searchedData?.length > 0 && (
          <Pagination
            count={10}
            variant="outlined"
            color="primary"
            sx={{ margin: "auto", marginTop: "40px", marginBottom: "20px" }}
          />
        )}
        {loading && (
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{ height: "70vh" }}
          >
            <Box component={"img"} src={loader} sx={{ width: "150px" }} />
          </Stack>
        )}
        {notFound && (
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{ height: "70vh" }}
          >
            <Typography variant="p">
              No item found related to {text} in {type} category!
            </Typography>
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default SearchPage;
