import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./HouseGallery.css";
// const images = [
//   {
//     original:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product3.jpg",
//     thumbnail:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product3.jpg",
//   },
//   {
//     original:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product5.jpg",
//     thumbnail:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product5.jpg",
//   },
//   {
//     original:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product6.jpg",
//     thumbnail:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product6.jpg",
//   },
//   {
//     original:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product1-1.jpg",
//     thumbnail:
//       "https://radiustheme.com/demo/html/homlisti/img/blog/product1-1.jpg",
//   },
// ];
function HouseGallery(props) {
  const { fetchedServicePic } = props;
  console.log("fetchedServicePictttt:", fetchedServicePic);

  let pictures = fetchedServicePic.map((element) => ({
    original: element.mpic,
    thumbnail: element.mpic,
  }));
  console.log("gg:", pictures);
  // ];
  return (
    <div>
      <ImageGallery items={pictures} />
    </div>
  );
}

export default HouseGallery;
