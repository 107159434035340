import {
  Facebook,
  Language,
  LinkedIn,
  Place,
  YouTube,
} from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const OfficeLocation = (props) => {
  const { profileDetails } = props;
  const { Address, City, Country } = profileDetails;
  return (
    <Paper
      sx={{ padding: "30px", marginTop: "5px", border: "1px solid #dfdddd" }}
    >
      <Stack spacing={1} justifyContent={"left"} alignItems={"flex-start"}>
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: "600" }}>
          Office Location
        </Typography>
        <Stack
          spacing={0.5}
          direction="row"
          justifyContent={"left"}
          alignItems={"center"}
        >
          <Place sx={{ fontSize: "13pt", color: "#039faf" }} />
          <Typography
            variant="p"
            sx={{ fontSize: "11pt", fontWeight: "500", color: "gray" }}
          >
            {Address}, {City}, {Country}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default OfficeLocation;
