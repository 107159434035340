import { useRef, useState } from "react";
import "./GhoramiPlayer.css";
import {
  Fullscreen,
  FullscreenExit,
  History,
  PauseCircle,
  PlayCircle,
  Update,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import PlayBtn from "../../../images/playButton.png";
import { findDOMNode } from "react-dom";
import { toast } from "react-toastify";

const GhoramiPlayer = ({ videoUrl, playerHeight }) => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  console.log("playing:", playing);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [videoLength, setVideoLength] = useState(0);
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const [mystream, setmystream] = useState(null);
  const [videoswitch, setvideo] = useState(true);
  const [audioswitch, setaudio] = useState(true);
  const [videoLengthTotal, setVideoLengthTotal] = useState(100);
  const [value, setValue] = useState(0.5);
  console.log("value434:", value);

  const videoHandler = (control) => {
    setVideoLengthTotal(videoRef.current.duration);
    console.log("videoLength:", videoLengthTotal);
    if (control === "play") {
      setInterval(function () {
        setCurrentTime(videoRef.current?.currentTime);
        setProgress((videoRef.current?.currentTime / videoTime) * 100);
        setVideoLength(videoRef.current?.currentTime);
      }, 1000);

      videoRef.current.play();
      videoRef.current.volume = value;
      setPlaying(true);
      var vid = document.getElementById("video1");
      setVideoTime(vid.duration);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  const fastForward = () => {
    videoRef.current.currentTime += 5;
    setVideoLength(videoRef.current?.currentTime + 5);
  };

  const revert = () => {
    videoRef.current.currentTime -= 5;
    setVideoLength(videoRef.current?.currentTime - 5);
  };

  const handleFullScreen = (event) => {
    console.log("fullscreenMode 33:", event);
    console.log("fullscreenMode:", fullscreenMode);
    const el = videoRef.current;
    setFullscreenMode(true);
    el.requestFullscreen();
  };
  if (playing == true) {
  }

  const stopTime = () => {};

  const handleAudio = () => {
    console.log("audio:", "Audio");

    if (audioswitch) {
      setaudio(false);

      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;

      console.log("audio:", Audio);
    } else {
      setaudio(true);

      videoRef.current.defaultMuted = false;
      videoRef.current.muted = false;

      console.log("audio:", Audio);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("VolumeUp:", newValue);
    console.log("videoRef.current:", videoRef.current);
    if (newValue <= 0.1) {
      videoRef.current.defaultMuted = true;

      videoRef.current.muted = true;
      setaudio(false);
    } else {
      setaudio(true);
      videoRef.current.defaultMuted = false;
      videoRef.current.muted = false;
      videoRef.current.volume = newValue;
    }
  };

  const handleChangeBar = (event, newValue) => {
    setVideoLength(newValue);
    videoRef.current.currentTime = newValue;
  };

  return (
    <div
      className="app 'controlsList', 'nodownload'"
      style={{ height: playerHeight }}
    >
      <video
        id="video1"
        ref={videoRef}
        className="video"
        autoPlay={false}
        width="100%"
        disablePictureInPicture
        controlsList="nodownload"
        src={videoUrl}
        style={{ height: playerHeight }}
      ></video>

      <div className="shadow"></div>
      {!playing && (
        <div className="controlsPause">
          <IconButton size="small" color="success">
            {PlayBtn}
          </IconButton>
        </div>
      )}
      <div className="controlsContainer" style={{ height: playerHeight }}>
        <div className="controls">
          <IconButton size="small" sx={{ color: "gray", zIndex: 50 }}>
            <History style={{ fontSize: "17pt" }} onClick={revert} />
          </IconButton>
          {playing ? (
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                videoHandler("pause");
                stopTime();
              }}
            >
              <PauseCircle
                style={{ fontSize: playerHeight >= "25vh" ? "30pt" : "17pt" }}
              />
            </IconButton>
          ) : (
            <IconButton size="small" color="success">
              <Box
                component={"img"}
                src={PlayBtn}
                sx={{
                  width: playerHeight >= "25vh" ? "28px" : "20px",
                  marginLeft: "3px",
                }}
                onClick={() => {
                  videoHandler("play");
                }}
              />
            </IconButton>
          )}
          <IconButton size="small" sx={{ color: "gray", zIndex: 50 }}>
            <Update style={{ fontSize: "17pt" }} onClick={fastForward} />
          </IconButton>
        </div>
      </div>

      {playerHeight >= "25vh" && (
        <div className={!playing ? "showTimeBar timecontrols" : "timecontrols"}>
          <Slider
            aria-label="videoBar"
            value={videoLength}
            size="small"
            onChange={handleChangeBar}
            // defaultValue={0}
            min={0}
            step={0.1}
            max={videoLengthTotal}
            sx={{ zIndex: 55, color: "white", width: "93%" }}
          />
        </div>
      )}
      {playerHeight >= "25vh" && (
        <div className="timeDigit">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            spacing={1}
            sx={{ zIndex: 55, width: "auto" }}
          >
            <Typography variant="p" className="controlsTime">
              {Math.floor(currentTime / 60) +
                ":" +
                ("0" + Math.floor(currentTime % 60)).slice(-2)}{" "}
            </Typography>
            <IconButton
              onClick={() => handleAudio()}
              sx={{ zIndex: 55, color: "white" }}
              size="small"
            >
              {!audioswitch ? (
                <VolumeOff style={{ fontSize: "14pt" }} />
              ) : (
                <VolumeUp style={{ fontSize: "14pt" }} />
              )}
            </IconButton>

            <IconButton sx={{ zIndex: 55, color: "white" }} size="small">
              {
                <Fullscreen
                  style={{ fontSize: "14pt" }}
                  onClick={() => handleFullScreen()}
                />
              }
            </IconButton>

            <Slider
              aria-label="Volume"
              value={value}
              size="small"
              onChange={handleChange}
              defaultValue={0.5}
              min={0.0}
              step={0.1}
              max={1.0}
              sx={{ zIndex: 55, color: "white", width: "60px" }}
            />
          </Stack>

          <Typography variant="p" className="controlsTime">
            {Math.floor(videoTime / 60) +
              ":" +
              ("0" + Math.floor(videoTime % 60)).slice(-2)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default GhoramiPlayer;
