import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const ClientItem = (props) => {
  const { clientData } = props;
  const { banner_pic, info, refer, title, background_c, info_c, title_c } =
    clientData;
  return (
    <Card
      sx={{
        width: "96%",
        bgcolor: background_c,
        marginX: "10px",
        boxSizing: "border-box",
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ height: "40vh" }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems={"center"}
            sx={{ width: "40%" }}
          >
            <Box
              component="img"
              sx={{
                // padding: "0 12px",
                width: "80%",

                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt={title}
              src={banner_pic}
            />
          </Stack>
          <Stack spacing={2} sx={{ width: "60%" }}>
            <Typography
              variant="p"
              color={title_c}
              sx={{ fontSize: "16pt", fontWeight: 300 }}
            >
              {title}
            </Typography>
            <Typography variant="p" color={info_c}>
              {info}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ClientItem;
