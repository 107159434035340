import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  expertAllCategoryApi,
  expertAllSubCategoryApi,
  ExpertSubCategoryApi,
  freelancerApi,
  serviceCategoryApi,
} from "../Component/API/Api";
import ExpertListBanner from "../Component/ExpertList Component/ExpertListBanner";
import GetStarted from "../Component/Get Started/GetStarted";
import FreelancerItem from "../Component/Homepage Components/Freelancer/FreelancerItem";

const ExpertListExplore = () => {
  const navigate = useNavigate();
  const { refer } = useParams();
  const [page, setPage] = useState(1);

  const [freelancerData, setFreelancerData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [fetchedCategoryListData, setFetchedCategoryListData] = useState([]);
  const [fetchedSubCategoryListData, setfetchedSubCategoryListData] = useState(
    []
  );
  console.log("fetchedCategoryListData:", fetchedCategoryListData);
  console.log("fetchedSubCategoryListData:", fetchedSubCategoryListData);

  console.log("subCategoryListDataaaa:", subCategoryListData);

  useEffect(() => {
    const formData = new FormData();
    formData.append("pref", refer);
    formData.append("page", page);

    if (refer !== "" || refer !== "undefined") {
      fetch(expertAllCategoryApi, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setFreelancerData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    } else {
      fetch(freelancerApi)
        .then((res) => res.json())
        .then((result) => {
          setFreelancerData(result);
        })
        .catch((error) => console.log("freelancerData Error => ", error));
    }
  }, [page, refer]);

  useEffect(() => {
    fetch(serviceCategoryApi)
      .then((res) => res.json())
      .then((result) => {
        setCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    let found = categoryListData?.find(
      (element) => element.category_name === category
    );
    const formData = new FormData();
    formData.append("pref", found?.category_id);
    formData.append("action", "fetch");
    console.log("found:", found?.category_id);

    fetch(ExpertSubCategoryApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setSubCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));

    fetch(expertAllCategoryApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFreelancerData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [category]);

  useEffect(() => {
    let found = subCategoryListData?.find(
      (element) => element.sub_name === subCategory
    );

    const formData = new FormData();
    formData.append("pref", found?.sub_id);
    formData.append("action", "fetch");
    console.log("found:", found?.category_id);

    fetch(expertAllSubCategoryApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFreelancerData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [subCategory]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };
  return (
    <Box sx={{ marginTop: { xs: "60px", md: "0px" } }}>
      <ExpertListBanner />
      <Container sx={{ mt: "0px" }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "left" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            spacing={1}
            sx={{ width: "48%" }}
          >
            <Typography variant="p" sx={{ fontWeight: 600 }}>
              Category:{" "}
            </Typography>
            <FormControl sx={{ width: { xs: "100%", md: "60%" } }}>
              <Select
                value={category}
                onChange={handleCategoryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {categoryListData.map((element) => (
                  <MenuItem value={element.category_name}>
                    {element.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "left" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            spacing={1}
            sx={{ width: "50%" }}
          >
            <Typography variant="p" sx={{ fontWeight: 600 }}>
              Sub Category:{" "}
            </Typography>
            <FormControl sx={{ width: { xs: "100%", md: "60%" } }}>
              <Select
                value={subCategory}
                onChange={handleSubCategoryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {subCategoryListData.map((element) => (
                  <MenuItem value={element.sub_name} key={element.sub_id}>
                    {element.sub_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Grid container spacing={2} mt={4} mb={4}>
          {freelancerData?.map((element) => (
            <Grid item xs={6} sm={6} md={3} key={element.refer_id}>
              <FreelancerItem freelancerData={element} key={element.refer_id} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ExpertListExplore;
