import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { nagadApi } from "../API/Api";
import { useEffect } from "react";

const NagadAccountDetails = () => {
  const [payAmount, setPayAmount] = useState(0);
  const [organization, setOrganization] = useState("ghorami");
  const [orderId, setOrderId] = useState("");
  const [requestData, setRequestData] = useState("");

  useEffect(() => {
    const formData = new FormData();
    formData.append("pamount", payAmount);
    formData.append("organiation", organization);
    formData.append("order_id", orderId);
    fetch(nagadApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        // setPayAmount(result.p_phone);
        // setOrganization(result.p_email);
        // setOrderId(result.p_nameS);

        console.log("resultc:", result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [payAmount, organization, orderId]);

  const handlePayAmountChange = (event) => {
    setPayAmount(event.target.value);
    setRequestData(event.target.value);
  };
  const handleOrganigationChange = (event) => {
    setOrganization(event.target.value);
    setRequestData(event.target.value);
  };
  const handleOrderIdChange = (event) => {
    setOrderId(event.target.value);
  };
  return (
    <Grid container spacing={2} paddingLeft={5} sx={{ width: "30%" }}>
      <Grid item xs={6}>
        <Stack>
          <Typography variant="p" sx={{ fontSize: "12pt" }}>
            Pay Amount
          </Typography>

          <BootstrapInput
            //   defaultValue="react-bootstrap"
            value={payAmount}
            placeholder="Please enter text"
            type="number"
            size="small"
            id="bootstrap-input"
            onChange={handlePayAmountChange}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Typography variant="p" sx={{ fontSize: "12pt" }}>
            Organization
          </Typography>
          <BootstrapInput
            value={organization}
            //   defaultValue="react-bootstrap"
            placeholder="Please enter text"
            type="text"
            size="small"
            id="bootstrap-input"
            onChange={handleOrganigationChange}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Typography variant="p" sx={{ fontSize: "12pt" }}>
            Order Id
          </Typography>
          <BootstrapInput
            value={orderId}
            //   defaultValue="react-bootstrap"
            placeholder="Please enter text"
            type="text"
            size="small"
            id="bootstrap-input"
            onChange={handleOrderIdChange}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NagadAccountDetails;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
