import { CheckCircle, Circle, Share, Verified } from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Paper,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import logo from "../../images/jason-dp450.jpg";

const ProfileCard = (props) => {
  const { profileDetails, fetchedReview } = props;
  const { cname, profile_pic, cslogan } = profileDetails;
  const { total_review } = fetchedReview;
  return (
    <Paper sx={{ padding: 6, bgcolor: "#20B2AA", position: "relative" }}>
      <Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ height: { md: "25vh", xs: "30vh" } }}
        >
          <Stack
            direction={"row"}
            justifyContent={{ xs: "center", md: "right" }}
            alignItems={"center"}
            sx={{ width: { xs: "34%", md: "44%" }, marginTop: "-20px" }}
          >
            <StyledBadge badgeContent={"Top Rated"} color="green">
              <Box
                component="img"
                sx={{
                  //   padding: "0 12px",
                  height: "100px",
                  width: "100px",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius: "50%",

                  // display: { xs: "none", sm: "none", md: "block" },
                }}
                alt="The house from the offer."
                src={profile_pic}
              />
            </StyledBadge>
          </Stack>
          <Stack
            sx={{
              width: { xs: "65%", md: "55%" },
              marginTop: "-20px",
              position: "relative",
            }}
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
              sx={
                {
                  // position: "absolute",
                  // top: { xs: "25%", md: "10%" },
                  // left: "0%",
                }
              }
            >
              {true && (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{
                    paddingX: "5px",
                    paddingY: "0px",
                    border: "2px solid lightgreen",
                    borderRadius: "30px",
                  }}
                >
                  <Circle sx={{ color: "lightgreen", fontSize: "8pt" }} />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 700,
                      color: "lightgreen",
                    }}
                  >
                    Online
                  </Typography>
                </Stack>
              )}
              {false && (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{
                    paddingX: "5px",
                    paddingy: "5px",
                    border: "2px solid gray",
                    borderRadius: "30px",
                  }}
                >
                  <Circle sx={{ color: "gray", fontSize: "8pt" }} />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 700,
                      color: "gray",
                    }}
                  >
                    Offline
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              spacing={0.5}
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18pt", fontWeight: 600, color: "white" }}
              >
                {cname}
              </Typography>
              <Verified sx={{ fontSize: "14pt", color: "darkgreen" }} />
            </Stack>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: 500, color: "white" }}
            >
              {cslogan}
            </Typography>

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems={"center"}
            >
              <Rating
                name="read-only"
                sx={{ fontSize: "14pt" }}
                value={4.5}
                readOnly
              />
              <Typography
                variant="p"
                sx={{ fontSize: "11pt", fontWeight: 500, color: "white" }}
              >
                ({total_review} reviews)
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ProfileCard;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 19,
    top: 75,
    textAlign: "center",
    fontSize: "7pt",
    color: "white",
    width: "40px",
    height: "40px",
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "10px",
    borderRadius: "50%",
  },
}));
