import {
  Apps,
  FormatListBulleted,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Slider from "react-slick";
import "./HomeSlider.css";
import { useNavigate } from "react-router-dom";
import JobShortCard from "./Latest Job/JobShortCard";
import { jobApi } from "../API/Api";

const settings = {
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomePageJobs = () => {
  const navigate = useNavigate();

  const [jobListData, setJobListData] = useState([]);
  console.log("jobListData:", jobListData);

  useEffect(() => {
    fetch(jobApi)
      .then((res) => res.json())
      .then((result) => {
        setJobListData(result?.slice(0, 5));
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);
  console.log("jobListDatahhhhhh:", jobListData);

  return (
    <Box sx={{ backgroundColor: "#F2F3F5", paddingY: "15px" }}>
      <Container>
        <Stack direction="row">
          <Stack
            justifyContent="flex-start"
            sx={{ width: "100%" }}
            className="homeSlick"
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
              spacing={1}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Jobs
              </Typography>

              <Button
                sx={{
                  width: "100px",
                  fontSize: "10pt",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate("/joblist");
                }}
              >
                See more...
              </Button>
            </Stack>
            <Divider sx={{ marginBottom: "15px" }} />
            {jobListData[0]?.job_refer && (
              <Box sx={{}}>
                <Slider {...settings}>
                  {jobListData?.map((data) => (
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      key={data.job_refer}
                    >
                      <Box sx={{ marginX: "10px", marginBottom: "25px" }}>
                        <JobShortCard jobData={data} />
                      </Box>
                    </Stack>
                  ))}
                </Slider>
              </Box>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default HomePageJobs;
