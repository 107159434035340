import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import GhoramiPlayer from "../../Custom Hooks/Video Player/GhoramiPlayer";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: 900 },
  height: "65vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  //   p: 4,
};

const VideoModal = ({ open, handleClose }) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <GhoramiPlayer
            videoUrl={"https://ghorami.com/image/ghorami_Intro.mp4"}
            playerHeight={"100%"}
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              zIndex: 10009,
            }}
            onClick={handleClose}
          >
            <Clear style={{ color: "red" }} />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoModal;
