import {
  ArrowForwardIos,
  CheckCircle,
  Favorite,
  Flag,
  HelpRounded,
  LocalOffer,
  LocationOn,
  OpenInNew,
  Psychology,
  Remove,
  Schedule,
  WatchLater,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jobDetailsApi } from "../API/Api";
import "./JobDetailsModal.css";

const style = {
  position: "fixed",
  top: "10.5%",
  right: "0%",
  maxWidth: "0px",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 1,
  overflow: "hidden",
  overflowY: "scroll",
  zIndex: 5,
  transition: "all .5s",
};
const JobDetailsModal = (props) => {
  const navigate = useNavigate();
  console.log("holaaa:");
  const { jobId, jobRefer, poid, modalOpen, handleModalOpen } = props;
  const [fetchJobDetails, setFetchJobDetails] = useState();

  useEffect(() => {
    const formData = new FormData();
    formData.append("gid", jobId);
    formData.append("refer", jobRefer);
    formData.append("poid", poid);

    fetch(jobDetailsApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchJobDetails(result[0]);

        console.log("resultJobDetails:", result[0]);
      })
      .catch((error) => console.log("JobDetailsApiError=> ", error));
  }, [jobId, jobRefer]);
  //   if (fetchJobDetails) {
  //     const {
  //       budget,
  //       budget_type,
  //       category,
  //       catid,
  //       currency,
  //       details,
  //       expet_level,
  //       job_length,
  //       job_refer,
  //       job_state,
  //       pay_type,
  //       post_time,
  //       poster,
  //       poster_active_job,
  //       poster_id,
  //       poster_location,
  //       poster_pic,
  //       poster_review,
  //       poster_total_job,
  //       poster_total_review,
  //       project_type,
  //       question,
  //       skill,
  //       title,
  //       total_interview,
  //       total_invite,
  //       total_proposal,
  //       working_limit,
  //     } = fetchJobDetails;
  //   }
  //   console.log("post_timeuuuuuuu:", fetchJobDetails.post_time);
  console.log("fetchJobDetails:", fetchJobDetails);

  return (
    <Paper style={style} className={modalOpen ? "showModal" : ""}>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="right"
        alignItems={"center"}
        sx={{ marginTop: "20px", marginRight: "20px" }}
      >
        <OpenInNew
          sx={{
            fontSize: "12pt",
          }}
        />
        <Link
          to={"/JobDetails/" + jobId + "/" + jobRefer + "/" + poid}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "9pt",
              fontWeight: 600,
            }}
          >
            Open in a new tab
          </Typography>
        </Link>
      </Stack>
      <Box
        sx={{
          position: "relative",
          marginTop: "20px",
          paddingBottom: "20px",
          marginX: "20px",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            border: "1px solid #d5d3d3",
            borderRadius: "12px",
          }}
        >
          <Grid item xs={8} sx={{ borderRight: "1px solid #d5d3d3" }}>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                {fetchJobDetails?.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  marginTop: "30px",
                  fontSize: "9pt",
                  fontWeight: 500,
                  color: "#0c87cf",
                }}
              >
                {fetchJobDetails?.category}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                posted at {fetchJobDetails?.post_time.split(" ")[1]} on{" "}
                {fetchJobDetails?.post_time.split(" ")[0]}
              </Typography>
              <Stack
                direction={"row"}
                spacing={0.5}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  marginTop: "10px",
                }}
              >
                <LocationOn
                  sx={{
                    fontSize: "12pt",
                    fontWeight: 500,
                    color: "#0c87cf",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 500,
                  }}
                >
                  {fetchJobDetails?.poster_location}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                {fetchJobDetails?.details}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              spacing={0}
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "16%" }}
              >
                <LocalOffer
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                    transform: "scaleX(-1)",
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.budget}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    {fetchJobDetails?.budget_type}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "34%" }}
              >
                <Schedule
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.job_length}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    Project Length
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "43%" }}
              >
                <Psychology
                  sx={{
                    fontSize: "12pt",
                    fontWeight: 500,
                    transform: "scaleX(-1)",
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.expet_level}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    I am looking for a mix of experience and value
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 600,
                }}
              >
                Project Type :
                <span
                  style={{
                    fontSize: "9pt",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  {fetchJobDetails?.project_type}
                </span>
              </Typography>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              {/* <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                  }}
                >
                  Skills and Expertise
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent="left"
                  alignItems={"center"}
                  flexWrap={"warp"}
                  mt={2}
                >
                  {fetchJobDetails?.skill.split(", ").map((element) => (
                    <Button
                      size="small"
                      variant="container"
                      sx={{
                        padding: "1px",
                        bgcolor: "#d5dfed",
                        fontSize: "8pt",
                        textTransform: "capitalize",
                        borderRadius: "30px",
                      }}
                    >
                      {element}
                    </Button>
                  ))}
                </Stack>
              </Stack> */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 600,
                      }}
                    >
                      Skills and Expertise
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="left"
                      alignItems={"center"}
                      flexWrap={"warp"}
                      // mt={2}
                    >
                      {fetchJobDetails?.skill.split(", ").map((element) => (
                        <Button
                          size="small"
                          variant="container"
                          sx={{
                            paddingY: "1px",
                            paddingX: "15px",
                            bgcolor: "#d5dfed",
                            fontSize: "8pt",
                            textTransform: "capitalize",
                            borderRadius: "30px",
                          }}
                        >
                          {element}
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 600,
                      }}
                    >
                      Working Limit
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 500,
                        color: "gray",
                        lineHeight: "9pt",
                      }}
                    >
                      {fetchJobDetails?.working_limit}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack sx={{ padding: "15px" }}>
              <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                  }}
                >
                  Activity on this job
                </Typography>
                <Stack
                  //   spacing={1}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Proposal :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.total_proposal}
                    </span>
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Interviewing :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.total_interview}
                    </span>
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Invites Sent :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.total_invite}
                    </span>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{}}>
            <Stack
              spacing={2}
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              <Button
                size="small"
                variant="container"
                sx={{
                  padding: "5px",
                  bgcolor: "#22AB7D",
                  color: "white",
                  fontSize: "9pt",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  borderRadius: "30px",
                }}
                href={"https://ghorami.com/profile/login/login.php"}
                target="_blank"
              >
                Submit a Proposal
              </Button>
              <Button
                size="small"
                variant="outlined"
                startIcon={<Favorite color="black" size="small" />}
                sx={{
                  padding: "5px",
                  border: "1px solid #22AB7D",
                  color: "black",
                  fontSize: "9pt",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  borderRadius: "30px",
                }}
                href={"https://ghorami.com/profile/login/login.php"}
                target="_blank"
              >
                Save Job
              </Button>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <Flag
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 500,
                    color: "black",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 500,
                    color: "black",
                  }}
                  onClick={() => {
                    navigate("/flag");
                  }}
                >
                  Flag as inappropriate
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  Send a proposal for: 2 connects
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  Available Connects : 120
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 600,
                }}
              >
                About the client
              </Typography>

              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  Payment Method not verified
                </Typography>
                <HelpRounded
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 400,
                    color: "darkgreen",
                  }}
                />
              </Stack>
              <Stack
                spacing={0}
                direction={"column"}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Box sx={{ width: "35px", height: "35px" }}>
                  <Box
                    component="img"
                    sx={{
                      // padding: "0 12px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                      borderRadius: "50%",

                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt=""
                    src={fetchJobDetails?.poster_pic}
                  />
                </Box>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ lineHeight: "20pt" }}
                >
                  <CheckCircle
                    sx={{
                      color: "darkgreen",
                      fontSize: "10pt",
                      fontWeight: 600,
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                    }}
                  >
                    {fetchJobDetails?.poster}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={0}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Bangladesh
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  {fetchJobDetails?.poster_location}{" "}
                  {fetchJobDetails?.post_time.split(" ")[1]}
                </Typography>
              </Stack>
              <Stack
                spacing={0}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {fetchJobDetails?.poster_total_job} job posted
                </Typography>
                <Stack>
                  <Rating
                    name="read-only"
                    value={parseFloat(fetchJobDetails?.poster_review)}
                    readOnly
                    size="small"
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    ({fetchJobDetails?.poster_total_review} reviews)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px" }}>
              <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                  }}
                >
                  Job link
                </Typography>
                <Stack
                  //   spacing={1}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  sx={{ marginTop: "10px" }}
                >
                  <Button
                    size="small"
                    variant="container"
                    sx={{
                      paddingY: "2px",
                      paddingX: "15px",
                      bgcolor: "#d5dfed",
                      fontSize: "8pt",
                      textTransform: "capitalize",
                      borderRadius: "30px",
                    }}
                  >
                    Share this job
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <IconButton
          aria-label="delete"
          sx={{ position: "absolute", left: "-10px", top: "-50px" }}
          size="small"
        >
          <ArrowForwardIos
            onClick={() => {
              handleModalOpen();
              console.log("click to porse");
            }}
          />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default JobDetailsModal;
