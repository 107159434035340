import {
  ArrowRightAlt,
  Facebook,
  Language,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const PageReach = (props) => {
  const { profileDetails } = props;
  const { follower, visitor } = profileDetails;
  console.log("follodfsdfswer:", follower);
  return (
    <Paper
      sx={{ padding: "30px", marginTop: "40px", border: "1px solid #dfdddd" }}
    >
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          justifyContent={"left"}
          alignItems={"flex-start"}
          sx={{ borderRight: "1px solid gray", width: "50%" }}
        >
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: "600" }}>
            Audience
          </Typography>
          <Stack direction={"row"} justifyContent="left" alignItems={"center"}>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: "500", color: "gray" }}
            >
              {visitor}
            </Typography>
            {visitor < 100 && (
              <ArrowRightAlt
                sx={{ transform: "rotate(90deg)", color: "red" }}
              />
            )}
            {visitor >= 100 && (
              <ArrowRightAlt
                sx={{ transform: "rotate(-90deg)", color: "darkgreen" }}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          justifyContent={"left"}
          alignItems={"flex-start"}
          sx={{ width: "50%", paddingX: "25px", boxSizing: "border-box" }}
        >
          <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: "600" }}>
            Follower
          </Typography>
          <Stack direction={"row"} justifyContent="left" alignItems={"center"}>
            <Typography
              variant="p"
              sx={{ fontSize: "11pt", fontWeight: "500", color: "gray" }}
            >
              {follower}
            </Typography>
            {follower < 100 && (
              <ArrowRightAlt
                sx={{ transform: "rotate(90deg)", color: "red" }}
              />
            )}
            {follower >= 100 && (
              <ArrowRightAlt
                sx={{ transform: "rotate(-90deg)", color: "darkgreen" }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default PageReach;
