import {
  Apps,
  FormatListBulleted,
  KeyboardArrowDown,
  Menu,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useRef } from "react";
import { jobApi } from "../Component/API/Api";
import Job from "../Component/Homepage Components/Latest Job/Job.js";
import CategoryListJob from "./CategoryListJob.js";
import CategoryListJobRES from "./CategoryListJobRES.js";
// import loading from "../Vendors/Images/loading.gif";

const JobList = () => {
  const focusPoint = useRef("");
  const [age, setAge] = useState("");
  const [firstLoadIndegator, setFirstLoadIndegator] = useState(true);
  const [page, setPage] = useState(1);
  const [resLeftToggle, setResLeftToggle] = useState(false);

  const [filterBtn, setFilterBtn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [price, setPrice] = useState(100);
  const [viewMode, setViewMode] = useState("list");
  const [jobListData, setJobListData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, , setSubCategory] = useState("");
  console.log("jobListData:", jobListData);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    fetch(jobApi)
      .then((res) => res.json())
      .then((result) => {
        setJobListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);
  const handleSearch = () => {
    setLoading(true);

    fetch(
      `https://ghorami.com/profile/login/api_search/sear_job.php?keyword=${searchText}&page=${page}&category=${category}&subCategory=${subCategory}`
    )
      .then((res) => res.json())
      .then((result) => {
        setJobListData(result);
        // if (result.length > 0) {
        //   setLoading(false);
        //   setNotFound(false);
        // } else {
        //   setLoading(false);
        //   setNotFound(true);
        // }
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };

  const handlePageChange = (event, value) => {
    setFirstLoadIndegator(false);
    setPage(value);
  };

  console.log("jobListDatahhhhhh:", jobListData);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box>
      <Container sx={{ marginTop: { xs: "10px", md: "20px" } }}>
        <Grid container spacing={2}>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              display: { xs: "none", md: "grid" },
            }}
          >
            <Paper
              elevation={3}
              sx={{
                // width: "30%",
                boxSizing: "border-box",
                minHeight: "90vh",
                padding: "20px",
                overflow: "auto",
              }}
            >
              <Stack>
                <CategoryListJob
                  setServiceListData={setJobListData}
                  page={page}
                  setFirstLoadIndegator={setFirstLoadIndegator}
                  setCategory={setCategory}
                  setSubCategory={setSubCategory}
                />

                <Divider light sx={{ marginBottom: "10px" }} />
              </Stack>
            </Paper>{" "}
          </Grid>
          <Paper
            elevation={3}
            sx={{
              display: { xs: resLeftToggle ? "grid" : "none", md: "none" },
              width: "100%",
              boxSizing: "border-box",
              minHeight: "90vh",
              padding: "20px",
              overflow: "auto",
              position: "absolute",
              top: "60px",
              left: "0px",
              zIndex: 5,
              // marginTop: "50px",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <CategoryListJobRES
                setServiceListData={setJobListData}
                page={page}
                setFirstLoadIndegator={setFirstLoadIndegator}
                setResLeftToggle={setResLeftToggle}
                setCategory={setCategory}
                setSubCategory={setSubCategory}
              />

              <Divider light sx={{ marginBottom: "10px" }} />
            </Stack>
          </Paper>
          <Grid item xs={12} md={9}>
            <Stack
              justifyContent="flex-start"
              sx={{ padding: "2%", paddingTop: "0px" }}
            >
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ width: "100%", marginTop: "15px", marginBottom: "10px" }}
                spacing={1}
              >
                <IconButton
                  size="small"
                  sx={{ display: { xs: "block", md: "none" } }}
                  onClick={() => setResLeftToggle(!resLeftToggle)}
                >
                  <Menu />
                </IconButton>
                <Stack
                  direction="row"
                  justifyContent={"left"}
                  alignItems="center"
                  sx={{
                    width: "100%",
                    marginTop: "15px",
                    marginBottom: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                  spacing={1}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "15pt", fontWeight: 600 }}
                  >
                    All Job
                  </Typography>
                </Stack>
                <OutlinedInput
                  color="success"
                  sx={{ width: "50%" }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSearch}
                        size="small"
                        sx={{ marginTop: "5px", marginLeft: "10px" }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {jobListData[0]?.job_refer ? (
                <Box sx={{ flexGrow: 1, marginTop: "-14px" }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {viewMode === "list" &&
                      jobListData.map((data) => (
                        <Grid item xs={12} key={data.job_refer}>
                          <Job jobData={data} />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginLeft: "200px",
                  }}
                >
                  <img
                    src={loading}
                    alt="loading"
                    style={{
                      width: "150px",
                      height: "150px",
                    }}
                  />
                </Box>
              )}

              <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />

              <Pagination
                count={10}
                variant="outlined"
                color="primary"
                page={page}
                sx={{ margin: "auto" }}
                onChange={handlePageChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default JobList;
