import { Apps, FormatListBulleted } from "@mui/icons-material";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { jobApi } from "../../API/Api";
import Job from "../../Homepage Components/Latest Job/Job";
import ServiceCardItem from "../../Homepage Components/Trending Services/ServiceCardItem";
import ServiceCardItemFlat from "../../Homepage Components/Trending Services/ServiceCardItemFlat";

const Jobs = (props) => {
  const { jobListData } = props;
  const [viewMode, setViewMode] = useState("grid");

  console.log("jobListDatahhhhhh:", jobListData);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{ padding: "10px", marginBottom: "5px" }}
      >
        <Typography variant="p" sx={{ fontSize: "14pt", fontWeight: 700 }}>
          Jobs
        </Typography>
      </Stack>
      <Divider sx={{ marginBottom: "10px" }} />
      <Grid container spacing={{ xs: 2, md: 1 }}>
        {jobListData.map((data) => (
          <Grid item xs={12} sm={12} md={12} key={data.pro_pic}>
            <Job jobData={data} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Jobs;
