import { ClassNames } from "@emotion/react";
import { AttachFile, Remove, Send } from "@mui/icons-material";
import {
  Badge,
  Button,
  IconButton,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BotReplyNewApi,
  BotReplyOldApi,
  ChatbotNewUserApi,
  fetchUserApi,
} from "../../API/Api";
import "../ChatBot/FloatingContactMe.css";
import ChatBox from "./ChatBox";
import ghorami from "../../../images/ghorami.ico";

const FloatingContactMe = ({
  profileDetails,
  pic,
  name,
  urlId,
  chatOpen,
  setChatOpen,
}) => {
  console.log("profileDecxzczxctails:", profileDetails);
  // const [chatOpen, setChatOpen] = useState(false);
  const [next, setNext] = useState(false);
  const [send, setSend] = useState(false);
  const [emptyText, setEmptyText] = useState(false);
  console.log("next:", next);
  const [chatText, setChatText] = useState("");
  const [deviceIp, setDeviceIp] = useState("");
  const [newUser, setNewUser] = useState("");
  const [fetchedPhone, setFetchedPhone] = useState("");
  const [fetchedEmail, setFetchedEmail] = useState("");
  const [fetchedName, setFetchedName] = useState("");
  const [requestData, setRequestData] = useState("");
  const [botOldConversation, setBotOldConversation] = useState("");
  const [botNewResponse, setBotNewResponse] = useState("");
  // botOldConversation
  let newUserInfofromLocal = localStorage.getItem("newUserInfo");
  let newUserInfo;
  if (newUserInfofromLocal) {
    newUserInfo = JSON.parse(newUserInfofromLocal);
    console.log("kk", newUserInfo?.m_croom);
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", newUserInfo?.m_croom);

    fetch(BotReplyOldApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("resuadasdadsdlt:", result);
        setBotOldConversation(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [newUserInfo?.m_croom]);

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("user_id", newUserInfo?.m_croom);
  //   formData.append("mtext", chatText);

  //   fetch(BotReplyNewApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       console.log("resuzxxzxlt:", result);
  //       setBotNewResponse(result[0]);
  //     })
  //     .catch((error) => console.log("FetchUserApiError=> ", error));
  // }, [newUserInfo?.m_croom, chatText]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch(fetchUserApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.p_phone) {
          setFetchedPhone(result.p_phone);
        }
        if (result.p_email) {
          setFetchedEmail(result.p_email);
        }
        if (result.p_nameS) {
          setFetchedName(result.p_nameS);
        }

        console.log("resultc:", result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [requestData]);
  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((result) => {
        console.log("resdsdsdult:", result);
        setDeviceIp(result.ip);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  const handlePhoneChange = (event) => {
    setFetchedPhone(event.target.value);
    setRequestData(event.target.value);
  };
  const handleEmailChange = (event) => {
    setFetchedEmail(event.target.value);
    setRequestData(event.target.value);
  };
  const handleNameChange = (event) => {
    setFetchedName(event.target.value);
  };
  const handleChatTextChange = (event) => {
    setChatText(event.target.value);
  };

  const handleSendMassage = () => {
    if (chatText) {
      console.log("chatText e dhukse");

      const formData = new FormData();
      formData.append("user_id", newUserInfo?.m_croom);
      formData.append("mtext", chatText);

      fetch(BotReplyNewApi, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("resuzxxzxlt:", result);
          setBotNewResponse(result[0]);
        })
        .catch((error) => console.log("FetchUserApiError=> ", error));
      setEmptyText(false);
    } else {
      setEmptyText(true);
    }
    setSend(true);
  };

  const handleNext = (event) => {
    if (fetchedPhone && fetchedEmail && fetchedName) {
      setNext(!next);

      const formData = new FormData();
      formData.append("user_name", fetchedName);
      formData.append("user_number", fetchedPhone);
      formData.append("user_email", fetchedEmail);
      formData.append("states", 1);
      formData.append("portal", "ghorami.com");
      formData.append("user_ip", deviceIp);
      formData.append("croom", Math.floor(Math.random() * 1000000000000 + 1));
      formData.append("sopno_id", urlId);
      formData.append("action", "fetch");

      fetch(ChatbotNewUserApi, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setNewUser(result);
          localStorage.setItem("newUserInfo", JSON.stringify(result[0]));
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
  };
  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("user_name", fetchedName);
  //   formData.append("user_number ", fetchedPhone);
  //   formData.append("user_email", fetchedEmail);
  //   formData.append("states", 1);
  //   formData.append("portal", "ghorami.com");
  //   formData.append("user_ip", deviceIp);
  //   formData.append("croom", "123");
  //   formData.append("sopno_id", profileDetails.uSopnoID);
  //   formData.append("action", "fetch");

  //   fetch(ChatbotNewUserApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setNewUser(result);
  //       localStorage.setItem("newUser", JSON.stringify(result));
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, [fetchedPhone, fetchedName, fetchedEmail, deviceIp]);
  return (
    <Box>
      <Paper
        elevation={10}
        onClick={() => {
          setChatOpen(!chatOpen);
        }}
        className={chatOpen ? "smallButton" : ""}
        sx={{
          width: "230px",
          height: "60px",
          borderRadius: "50px",
          position: "fixed",
          right: "30px",
          bottom: "30px",
          zIndex: 20,
          cursor: "pointer",
          transition: "width 1s",
          "&:hover": {
            bgcolor: "#f5f7f7",
          },
        }}
      >
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent="left"
          alignItems={"center"}
          sx={{ height: "60px" }}
        >
          <StyledBadge badgeContent={""} color="grey" variant="dot">
            <Box
              component="img"
              sx={{
                //   padding: "0 12px",
                height: "50px",
                width: "50px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
                marginLeft: "6px",
              }}
              src={pic}
            />
          </StyledBadge>
          <Stack
            sx={{
              opacity: 1,
              height: "50px",
              overflow: "hidden",
            }}
            className={chatOpen ? "hideWords" : ""}
          >
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              {name}
            </Typography>
            <Typography
              variant="p"
              sx={{ fontSize: "9pt", fontWeight: 600, color: "#7a7878" }}
            >
              Contact Me
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      {!newUserInfo?.m_croom ? (
        <Box>
          {chatOpen && (
            <Paper
              elevation={10}
              sx={{
                height: "50vh",
                width: { md: "20vw", xs: "50vw" },
                position: "fixed",
                right: "15px",
                bottom: "91px",
                borderRadius: "12px",
                zIndex: 10,
              }}
            >
              <Stack sx={{ height: "100%", borderRadius: "12px" }}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent={"left"}
                  alignItems="center"
                  sx={{ height: "22%", bgcolor: "#194E53" }}
                >
                  <Box
                    component="img"
                    sx={{
                      //   padding: "0 12px",
                      height: "40px",
                      width: "40px",
                      //   objectFit: "cover",
                      cursor: "pointer",
                      marginLeft: "10px",
                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt="The house from the offer."
                    src={ghorami}
                  />
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 600, color: "white" }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "8pt", fontWeight: 400, color: "white" }}
                    >
                      Powered by Ghorami Technology
                    </Typography>
                  </Stack>
                </Stack>
                {next && fetchedPhone && fetchedEmail && fetchedName ? (
                  <Box sx={{ height: "78%", padding: "10px" }}>
                    <ChatBox
                      profileDetails={profileDetails}
                      pic={pic}
                      name={name}
                      urlId={urlId}
                    />
                  </Box>
                ) : (
                  <Stack sx={{ height: "78%", padding: "10px" }} spacing={2}>
                    <Stack sx={{}}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "11pt", fontWeight: 500 }}
                      >
                        Mobile No
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter your mobile no"
                        value={fetchedPhone}
                        type="number"
                        onChange={handlePhoneChange}
                        size="small"
                        sx={{ borderRadius: "4px" }}
                      />
                    </Stack>
                    <Stack sx={{}}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "11pt", fontWeight: 500 }}
                      >
                        Name
                      </Typography>
                      <OutlinedInput
                        value={fetchedName}
                        type="text"
                        onChange={handleNameChange}
                        placeholder="Enter your name"
                        size="small"
                        sx={{ borderRadius: "4px" }}
                      />
                    </Stack>
                    <Stack sx={{}}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "11pt", fontWeight: 500 }}
                      >
                        Email
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter your email"
                        size="small"
                        value={fetchedEmail}
                        type="email"
                        onChange={handleEmailChange}
                        sx={{ borderRadius: "4px" }}
                      />
                    </Stack>
                  </Stack>
                )}
                <Box
                  sx={{
                    height: "20%",
                    bgcolor: "",
                    display:
                      next && fetchedPhone && fetchedEmail && fetchedName
                        ? "none"
                        : "block",
                  }}
                >
                  <Stack
                    sx={{
                      height: "100%",
                      bgcolor: "",
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Stack>
                </Box>

                {next && fetchedPhone && fetchedEmail && fetchedName && (
                  <Stack
                    sx={{ height: "20%", bgcolor: "#797c7c" }}
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box sx={{ paddingLeft: "5px" }}>
                      {/* <AttachFile /> */}
                    </Box>

                    <TextField
                      variant="standard"
                      required
                      id="phoneNumber"
                      name="phoneNumber"
                      value={chatText}
                      autoFocus
                      sx={{
                        paddingLeft: "20px",
                        bgcolor: "white",
                        borderRadius: "30px",
                      }}
                      onChange={handleChatTextChange}
                      placeholder="..."
                      InputProps={{
                        disableUnderline: true, // <== added this
                      }}
                    />
                    <IconButton aria-label="delete">
                      <Send />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </Paper>
          )}
        </Box>
      ) : (
        <Box>
          {chatOpen && (
            <Paper
              elevation={10}
              sx={{
                height: "65vh",
                width: { md: "25vw", xs: "50vw" },
                position: "fixed",
                right: "15px",
                bottom: "91px",
                borderRadius: "12px",
                zIndex: 10,
              }}
            >
              <Stack sx={{ height: "100%", borderRadius: "12px" }}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "15%", bgcolor: "#194E53", paddingX: "10px" }}
                >
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems="center"
                    sx={{ height: "15%", bgcolor: "#194E53" }}
                  >
                    <Box
                      component="img"
                      sx={{
                        //   padding: "0 12px",
                        height: "40px",
                        width: "40px",
                        //   objectFit: "cover",
                        cursor: "pointer",
                        marginLeft: "10px",
                        // display: { xs: "none", sm: "none", md: "block" },
                      }}
                      alt=""
                      src={ghorami}
                    />
                    <Stack>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "11pt",
                          fontWeight: 600,
                          color: "white",
                        }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "8pt",
                          fontWeight: 400,
                          color: "white",
                        }}
                      >
                        Powered by Ghorami Technology
                      </Typography>
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      setChatOpen(!chatOpen);
                    }}
                  >
                    <Remove sx={{ color: "white" }} />
                  </IconButton>
                </Stack>
                <Box
                  sx={{ height: "78%", padding: "10px", overflowY: "scroll" }}
                >
                  <ChatBox
                    pic={pic}
                    name={name}
                    urlId={urlId}
                    profileDetails={profileDetails}
                    botOldConversation={botOldConversation}
                    botNewResponse={botNewResponse}
                  />
                </Box>

                <Stack
                  sx={{ height: "15%", bgcolor: "#797c7c", paddingX: "10px" }}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box sx={{ paddingLeft: "5px" }}>{/* <AttachFile /> */}</Box>

                  <TextField
                    variant="standard"
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    value={chatText}
                    autoFocus
                    sx={{
                      boxSizing: "border-box",
                      bgcolor: "white",
                      borderRadius: "30px",
                      paddingX: "15px",
                    }}
                    onChange={handleChatTextChange}
                    placeholder={emptyText ? "Textfield is empty" : "..."}
                    InputProps={{
                      disableUnderline: true, // <== added this
                    }}
                  />
                  <Box>
                    <IconButton onClick={handleSendMassage} sx={{}}>
                      <Send />
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FloatingContactMe;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 44,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
