import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStartedApi } from "../API/Api";

const ExpertListBanner = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(getStartedApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result[0]);
      })
      .catch((error) => {
        console.log("fetch Failed ", error);
      });
  }, []);
  return (
    <Box>
      <Paper elevation={3} sx={{ padding: "20px", bgcolor: "#1a475c" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, md: 0 }}
        >
          <Stack
            sx={{ width: { xs: "100%", md: "70%" } }}
            spacing={1}
            justifyContent={"center"}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16pt", fontWeight: 600, color: "white" }}
            >
              {data.title}
            </Typography>
            <Typography variant="p" sx={{ fontSize: "13pt", color: "white" }}>
              {data.info}
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: { xs: "100%", md: "30%" },
              display: { xs: "none", md: "flex" },
            }}
            direction="row"
            justifyContent={{ xs: "center", md: "right" }}
            alignItems="center"
          >
            <Box
              component="img"
              sx={{
                width: "60%",
                cursor: "pointer",
              }}
              alt={data.title}
              src={data.banner_pic}
            />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ExpertListBanner;
